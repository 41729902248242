interface NavItem {
    name: string;
    link: string;
}
const Library = [
    { name: 'Contests', link: 'contest' },
]

const Marketing = [

    {
        name: 'Email Marketing',
        link: 'email-marketing'
    },
    {
        name: 'SMS Marketing',
        link: 'sms-marketing'
    },
    {
        name: 'Contacts',
        link: 'import-contacts'
    },
    {
        name: 'Templates',
        link: 'templates'
    },
    {
        name: 'Memberships',
        link: 'memberships',
    },
    {
        name: 'Sponsors',
        link: 'sponsors',
    },
    {
        name: 'Season',
        link: 'season',
    },
]

const Accounting = [
    { name: 'Accounting', link: 'accounting' },
]

export const navData: Record<string, NavItem[]> = {
    ['Chapters / Events']: [
        {
            name: 'Chapters',
            link: 'chapters',
        },
        {
            name: 'Chapters Setup',
            link: 'chapters-setup',
        },
        {
            name: 'Create Tournament',
            link: 'tournament/create-a-tournament',
        },
        {
            name: 'All Tournaments/ Events',
            link: 'tournaments',
        },
    ],

    Administration: [
        {
            name: 'Dashboard',
            link: 'dashboard',
        },
        {
            name: 'Players',
            link: 'player-management',
        },
        {
            name: 'User Management',
            link: 'user-management',
        },
        {
            name: 'Permissions & Accesses',
            link: 'permission-access',
        },
        {
            name: 'Flights and Divisions',
            link: 'flights-divisions',
        },
        {
            name: 'Prizes and Awards',
            link: 'prizes-awards',
        },

    ],

    "Marketing": [
        ...Marketing
    ],

    "Library": [
        ...Library
    ],
    "Accounting": [
        ...Accounting
    ],
    Courses: [
        {
            name: 'Courses',
            link: 'courses'
        }
    ]
};
export const navMenu: any = {
    1: "Chapters / Events",
    2: "Marketing",
    3: "Courses",
    4: "Accounting",
    5: "Reports",
    6: "Administration",
    7: "Pro-Shop",
    8: "Support",
    9: "Library"
};