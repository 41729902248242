import React, { useState } from "react";
import Routes from "@config/routes";
import { Button, ConfigProvider } from "antd";
import '../../assets/style/globalStyle.css'
ConfigProvider.config({
  theme: {
    primaryColor: "#3a57e8",
    errorColor: "#e74c3c",
  },
});

const App = () => {
  return (
    <ConfigProvider>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          flex: 1,
        }}
      >
        <Routes />
      </div>
    </ConfigProvider>
  );
};

export default App;
