import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface TournamentsListInterface {
    tournamentsData: any[]; // player data storage
    isLoading: boolean; // loader
    isActiveLoading: boolean;
    page: number;
    hasMore?: boolean;
    total?:number;
    isTournamentListView:boolean;
}
const initialState: TournamentsListInterface = {
    tournamentsData: [],
    isLoading: true,
    isActiveLoading: false,
    page: 1,
    hasMore: false,
    total:0,
    isTournamentListView:true,
};
export const TournamentsListSlice = createSlice({
    name: "tournamentsList",
    initialState,
    reducers: {
        setIsTournamentListView: (state, action) => {
            state.isTournamentListView = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.TOURNAMENTS_LIST}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                const { data, page, total_count }:any = action.payload
                const hasMore = (page * 10) < total_count
                let newData = page === 1 ? data : [...state?.tournamentsData, ...data]
                return {
                    ...state,
                    isLoading: false,
                    hasMore: hasMore,
                    page: page,
                    tournamentsData: state.isTournamentListView ? data : newData,
                    total:total_count
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.TOURNAMENTS_LIST}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                    page: action.payload.page,
                };;
            }
        );
    },
})

export const { setIsTournamentListView } = TournamentsListSlice.actions;

export default TournamentsListSlice.reducer;  