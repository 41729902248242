export const getCustomHolesArray = (array: any[], NUMBER: string, format: string): any[] => {
    let tempArray = [];
    array = array.map((item:any) => item.name)
    let flag: boolean = true;
    let indexOfNewDoc, scrollBottom=false;
    if (format.includes('A')) {
        const newPart: string[] = format.split('').map((char) => NUMBER + char)
        for (let index = 0; index < array?.length; index++) {
            if (array[index].includes(NUMBER) && flag) {
                indexOfNewDoc = index
                for (let j = 0; j < newPart.length; j++) {
                    tempArray.push(newPart[j])
                    if (newPart[j] === array[index]) {
                        if (j + 1 < newPart.length) {
                            index++
                            if(!array[index]?.includes(NUMBER)) {
                                --index;
                            }
                        }
                            
                    }
                }
                flag = false
            } else {
                tempArray.push(array[index])
            }
        }
        if(flag) {
            scrollBottom = true;
            tempArray = [...tempArray, ...newPart].sort()
            let orderFlag = false
            const temp1 = [],temp2 = [];
            for(let i of tempArray) {
                if(i.length === 2 && !orderFlag) {
                    orderFlag = true
                }
                if(orderFlag) {
                    temp1.push(i)
                } else {
                    temp2.push(i)
                }
            }
            tempArray = [...temp1, ...temp2]
        }
    } else {
        tempArray = [...array]
        if (NUMBER === '1') {
            let index = array.indexOf('10');
            if (index > 0) {
                indexOfNewDoc = index
                tempArray.splice(index, 0, NUMBER);
            }
            else {
                indexOfNewDoc = array.length
                tempArray.push(NUMBER)
                scrollBottom = true;
            }
                
        } else {
            indexOfNewDoc = array.length
            tempArray.push(NUMBER)
            scrollBottom = true;
        }

    }
    return [tempArray,indexOfNewDoc,scrollBottom];
}
export const BASICHOLEARRAY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']
export const showWhenNeededFun = (className:string,value:string) => {
const dataArray:any = document.getElementsByClassName(className)
for(let i of dataArray) {
    i.style.display = value
}
}
export const getHolesArray = ({ format, count }: { format: string, count: number }): string[] => {
    const array = [];
    format = format.toUpperCase()
    if (format === 'A') {
        for (let i = 1; i <= count; i++) {
            array.push('' + i)
        }
    } else if (format === 'AB') {
        let tempVal = 1
        for (let i = 1; i <= count; i++) {
            array.push(tempVal + 'A')
            i++
            if (i > count)
                break
            array.push(tempVal++ + 'B')
        }
    } else if (format === 'ABC') {
        let tempVal = 1
        for (let i = 1; i <= count; i++) {
            array.push(tempVal + 'A')
            if (i++ > count)
                break
            array.push(tempVal + 'B')
            if (i++ > count)
                break
            array.push(tempVal++ + 'C')
        }
    } else if (format === '1') {
        let tempVal = 1
        for (let i = 1; i <= count; i++) {
            array.push(tempVal + '')
        }
    } else if (format === '10') {
        let tempVal = 10
        for (let i = 1; i <= count; i++) {
            array.push(tempVal + '')
        }
    } else if (format === '1&10') {
        let tempVal = 1
        const tempCount = count / 2 + 1
        for (let i = 1; i <= count; i++) {
            if (i < tempCount) {
                array.push('' + tempVal)
            }
            else
                array.push(tempVal + '0')
        }
    }
    return array
}


const valForUniqKey = 10000
let memoizedUniqKey: any = {};
export const getUniqKey = (val1: number, val2: number): number => {
    const key = `${val1}-${val2}`;
    if (memoizedUniqKey[key] !== undefined) {
        return memoizedUniqKey[key];
    }
    const val = (val1 + 1) * valForUniqKey + val2;
    memoizedUniqKey[key] = val;
    return val;
}

export const getUniqIndex = (val1: number): string => {
    const val = (parseInt((val1 / valForUniqKey).toString()) - 1) + '-' + (val1 % valForUniqKey);
    return val;
}