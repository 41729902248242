import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from "../constant/toast/Toast";
import { history } from "@config/routes";

function* fetchflightsDivisionsSagaData({ payload = {} }: any): Generator<any> {
    try {
        payload.access_token = getToken()
        let response: any = yield call(gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.FLIGHTS_DIVISIONS}`,
            { params: payload });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });
        }
    }
    catch (error) {
        // yield put (setPermissionData(error));
    }

}

function* DivisionsSetActive({ payload = {} }: any): Generator<any> {
    try {
        payload.access_token = getToken()
        let response: any = yield call(gcbAPI().patch,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.FLIGHTS_DIVISIONS}/${ApiEndpoints.DIVISIONS_SET_ACTIVE}`,
            { ...payload });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.DIVISIONS_SET_ACTIVE}_${SagaActionType.SUCCESS}`,
                payload: {},
            });
            yield call(Success, { description: response?.data?.message })
        }
    }
    catch (error: any) {
        yield call(Error, { description: error?.message })
    }

}

function* viewflightsDivisions({ payload, id }: any): Generator<any> {
    try {
        payload.access_token = getToken();
        let response: any = yield call(
            gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.FLIGHTS_DIVISIONS}/${payload.id}`,
            { params: payload }
        );
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.VIEW_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });
        }
    } catch (error: any) {
        yield call(Error, { description: error?.data?.message });
    }
}

function* updateflightsDivisions({ payload, id }: any): Generator<any> {
    console.log("tester saga")
    try {
        payload.access_token = getToken();
        const response: any = yield call(
            gcbAPI().put,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.FLIGHTS_DIVISIONS}/${payload.id}`,
            { ...payload }
        );
        if (response?.status === 200) {
            yield call(Success, { description: response?.data?.message });
            yield put({
                type: `${SagaActions.UPDATE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
                payload: { data: response?.data?.data },
            });
            //To reload after updtaing
            yield put({
                type: `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
                payload: {},
            });
            yield call(history.replace, `/admin/flights-divisions`);
        }
    } catch (error: any) {
        yield put({
            type: `${SagaActions.UPDATE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            payload: { data: [] },
        });
        yield call(Error, { description: error?.data?.message });
    }
}

function* DeleteflightsDivisions({ payload }: any): Generator<any> {
    try {
        payload.access_token = getToken();
        const id = payload.id
        const response: any = yield call(
            gcbAPI().delete,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.FLIGHTS_DIVISIONS}/${id}`,
            { params: payload }
        );
        if (response?.status === 200) {
            yield call(Success, { description: response?.data?.message });
            yield put({
                type: `${SagaActions.DELETE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
                payload: {},
            });
            //To reload list after deleting 
            yield put({
                type: `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
                payload: {}
            });
        }
    } catch (error: any) {
        yield put({
            type: `${SagaActions.DELETE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            payload: { data: [] },
        });
        yield call(Error, { description: error?.data?.message });
    }
}

function* flightsDivisionsSagaRequest() {
    yield takeLatest(
        `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
        fetchflightsDivisionsSagaData
    );
}
function* DivisionsSetActiveSagaRequest() {
    yield takeLatest(
        `${SagaActions.DIVISIONS_SET_ACTIVE}_${SagaActionType.REQUEST}`,
        DivisionsSetActive
    );
}
function* viewflightsDivisionsRequest() {
    yield takeLatest(
        `${SagaActions.VIEW_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
        viewflightsDivisions
    );
}

function* updateflightsDivisionsRequest() {
    yield takeLatest(
        `${SagaActions.UPDATE_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
        updateflightsDivisions
    );
}

function* deleteflightsDivisionsRequest() {
    yield takeLatest(
        `${SagaActions.DELETE_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
        DeleteflightsDivisions
    );
}

export default function* rootflightsDivisionsManagement() {
    yield spawn(flightsDivisionsSagaRequest);
    yield spawn(DivisionsSetActiveSagaRequest);
    yield spawn(viewflightsDivisionsRequest);
    yield spawn(updateflightsDivisionsRequest);
    yield spawn(deleteflightsDivisionsRequest);
}