import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { CHAPTER_ID, getLocalVal, getCompanyID, getToken } from "../auth/localData";
import { Error, Success } from "@constant/toast/Toast";

function* fetchNotificationData({ payload = {} }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    payload.company_id = getCompanyID()
    payload.chapter_id = getLocalVal(CHAPTER_ID)
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.NOTIFICATIONS}/${ApiEndpoints.LIST_NOTIFICATIONS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
        payload: {
          page: Number(response.data.page)
          , data: response.data.data, total: response.data.total_count, notificationCount: response.data.total_count
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }

}
function* fetchNotificationTable({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.NOTIFICATIONS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.GET_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
        payload: {
          page: Number(response.data.page), data: response.data.data, total: response.data.total_count
        },
      });

    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.GET_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
      payload: {
        page: Number(1), data: [], total: 0
      },
    });
    yield call(Error, { description: error?.data?.message });
  }

}
function* updateNotification({ payload = {} }: any): Generator<any> {
  const { id } = payload;
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.NOTIFICATIONS}/${id}/update_tournament_status`,
      { ...payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data, notificationCount: response.data.total_count },
      });
      yield put({
        type: `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
        payload: {},
      });
      yield call(Success, { description: response?.data?.message });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
      payload: { data: {}},
    });
    yield call(Error, { description: error?.data?.message });
  }

}
function* NotificationRequest() {
  yield takeLatest(
    `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
    fetchNotificationData
  );
}
function* NotificationTableRequest() {
  yield takeLatest(
    `${SagaActions.GET_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
    fetchNotificationTable
  );
}
function* NotificationUpdateRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
    updateNotification
  );
}
export default function* rootNotificationManagement() {
  yield spawn(NotificationRequest);
  yield spawn(NotificationUpdateRequest);
  yield spawn(NotificationTableRequest);

}