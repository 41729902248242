import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error } from "@constant/toast/Toast";

function* fetchScoringsRoundList({ payload = {} }: any): Generator<any> {
    try {
        let response: any = yield call(gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/${ApiEndpoints.SHOW_SCORING_CONTROL}`,
            { params: { access_token: getToken() } });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.SCORINGS_ROUND_LIST}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });
        }
    }
    catch (error: any) {
        yield call(Error, { description: error?.data?.message });
    }
}
function* postScoringsRoundList({ payload }: any): Generator<any> {
    try {
        let response: any = yield call(gcbAPI().put,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.ID}/${ApiEndpoints.SCORINGS_ROUND_UPDATE}`,
            { ...payload, access_token: getToken() });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });
        }
    }
    catch (error: any) {
        yield call(Error, { description: error?.data?.message });
        yield put({
            type: `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.FAIL}`,
        });
    }
}
function* fetchScoringsRoundListRequest() {
    yield takeLatest(
        `${SagaActions.SCORINGS_ROUND_LIST}_${SagaActionType.REQUEST}`,
        fetchScoringsRoundList
    );
}
function* postScoringsRoundListRequest() {
    yield takeLatest(
        `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.REQUEST}`,
        postScoringsRoundList
    );
}

export default function* rootcreateScorings() {
    yield spawn(fetchScoringsRoundListRequest);
    yield spawn(postScoringsRoundListRequest);
}