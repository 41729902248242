import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from "@constant/toast/Toast";
import { history } from "@config/routes";

function* fetchMembershipData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.MEMBERSHIP}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.MEMBERSHIP}_${SagaActionType.SUCCESS}`,
        payload: {
          page: response.data.page
          , data: response.data.data, total: response.data.total_count
        },
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));
  }
}

function* PostMembershipData({ payload }: any): Generator<any> {
  let ApiEndpoint: any = `${ApiEndpoints.ADMIN}/${ApiEndpoints.MEMBERSHIP}`;
  let ID = payload?.id
  if (payload?.id) {
    ApiEndpoint = `${ApiEndpoint}/${payload?.id}`
    delete payload.id
  }
  try {
    payload.access_token = getToken()
    const response: any =
      ID ? yield call(gcbAPI().put, ApiEndpoint, { ...payload }, { headers: { "Content-Type": "multipart/form-data", }, })
        : yield call(gcbAPI().post, ApiEndpoint, { ...payload }, { headers: { "Content-Type": "multipart/form-data", }, });
    if (response?.status === 200 || response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
    history.replace('/admin/memberships');
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* DeleteMembershipData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const id = payload.id
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.MEMBERSHIP}/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      //To reload list after deleting the Membership
      yield put({
        type: `${SagaActions.MEMBERSHIP}_${SagaActionType.REQUEST}`,
        payload: {}
      });
      // history.replace('/admin/Membership');
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.DELETE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* MembershipRequest() {
  yield takeLatest(
    `${SagaActions.MEMBERSHIP}_${SagaActionType.REQUEST}`,
    fetchMembershipData
  );
}
function* PostMembershipRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_MEMBERSHIP}_${SagaActionType.REQUEST}`,
    PostMembershipData
  );
}
function* DeleteMembershipRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_MEMBERSHIP}_${SagaActionType.REQUEST}`,
    DeleteMembershipData
  );
}

export default function* rootMembershipManagement() {
  yield spawn(MembershipRequest);
  yield spawn(PostMembershipRequest);
  yield spawn(DeleteMembershipRequest);
}