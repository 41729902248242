import { Card, Col } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import './addCardStyle.css';
interface AddIconCardProps {
    link: string;
    name: string;
    fc?: () => void;
}
export const AddIconCard = ({ link, name, fc }: AddIconCardProps) => (
    <Col xxl={5} xl={6} lg={10} md={12} sm={24} xs={24} className='mrb-4'>
        <Card className="d-box-shadow card-heigth flex-center b-r-5">
            <Link to={link} className='flex-center' onClick={() => { if (fc) fc() }}>
                <PlusCircleOutlined className='card-add-icon-antd' />
            </Link>
            <p className="card-add-icon-text mt-2">{name ? name : 'Create'}</p>
        </Card>
    </Col>
);

export const CardUI = (props:any) =>
    <Col xxl={5} xl={6} lg={10} md={12} sm={24} xs={24} className='mrb-4'>
        <Card className="d-box-shadow card-heigth flex b-r-5" bodyStyle={{ width: '100%' }}>
            {props.children}
        </Card>
    </Col>