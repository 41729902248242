import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from "@constant/toast/Toast";

function* fetchSchedulesList({ payload = {} }: any): Generator<any> {
    try {
        let response: any = yield call(gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.ID}/${ApiEndpoints.SCHEDULES}`,
            { params: {access_token : getToken()} });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.SET_SCHEDULES}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });
        }
    }
    catch (error:any) {
        yield call(Error, { description: error?.data?.message });
    }
}
function* postSchedulesList({ payload}: any): Generator<any> {
    try {
        let response: any = yield call(gcbAPI().put,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.SCHEDULES}/bulk_update_and_create`,
            { ...payload, access_token : getToken() });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data,
                },
            });

            yield call(Success, { description: response.data.message });
        }
    }
    catch (error:any) {
        yield call(Error, { description: error?.data?.message });
        yield put({
            type: `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.FAIL}`,
        });
    }
}
function* fetchSchedulesListRequest() {
    yield takeLatest(
        `${SagaActions.SET_SCHEDULES}_${SagaActionType.REQUEST}`,
        fetchSchedulesList
    );
}
function* postSchedulesListRequest() {
    yield takeLatest(
        `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.REQUEST}`,
        postSchedulesList
    );
}

export default function* rootcreateSchedules() {
    yield spawn(fetchSchedulesListRequest);
    yield spawn(postSchedulesListRequest);
}