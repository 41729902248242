import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface playersInterface {
  playersData: any[]; // player data storage
  isLoading: boolean; // loader
  total: number; // number of results
  playersTag: any[]; // tags data storage
  deletePlayerTagReq: boolean;
  createPlayerTagReq: boolean;
  playerTagReq: boolean;
  addPlayerTagReq: boolean;
  playerTagUpdate: boolean;
}
// create index with pageNumber + filterType + searchStrings =====> this will help to reduce api call
const initialState: playersInterface = {
  playersData: [],
  isLoading: true,
  total: 0,
  playersTag: [],
  deletePlayerTagReq: false,
  createPlayerTagReq: false,
  playerTagReq: false,
  addPlayerTagReq: false,
  playerTagUpdate: false,
};
export const playersInfoSlice = createSlice({
  name: "player",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ADD_PLAYER_USERS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          total: action.payload.total,
          playersData: action.payload.data,

        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ADD_PLAYER_USERS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          playerTagReq: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          playerTagReq: false,
          playersTag: action.payload.data,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS_CREATE}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        // const tagArray = state.playersTag
        // debugger
        // if(action.payload.data)
        // tagArray.push(action.payload.data)
        return {
          ...state,
          createPlayerTagReq: false,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS_CREATE}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          createPlayerTagReq: true,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS_DELETE}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          deletePlayerTagReq: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_TAGS_DELETE}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          deletePlayerTagReq: false,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_ADD_TAG}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          addPlayerTagReq: false,
          playerTagUpdate: !state.playerTagUpdate,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_ADD_TAG}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          addPlayerTagReq: true,
        };;
      }
    );
  },
})

export default playersInfoSlice.reducer;  