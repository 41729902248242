import { Typography } from 'antd';

const { Title } = Typography;

interface HtagProps {
    level?: 1 | 2 | 3 | 4 | 5;
    children: React.ReactNode;
    className?: string,
    title?: any,
}

function Htag({ level = 1, className = "", children, title = '' }: HtagProps) {
    return <Title level={level} className={className} title={title}>{children}</Title>;
}

export default Htag;