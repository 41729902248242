import {
  takeLatest,
  call,
  put,
  spawn,
  takeEvery,
  select,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { notification } from "antd";
import { Error, Success, Waraning } from "../constant/toast/Toast";

function* fetchPlayerData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_USERS}`,
      { params: payload });
    if (response?.status === 200) {
      // yield call(Error,{description:'Tag/s Deleted Successfully.'});
      // yield call(Success,{description:'Tag/s Deleted Successfully.'});
      // yield call(Waraning,{description:'Tag/s Deleted Successfully.'});

      yield put({
        type: `${SagaActions.ADD_PLAYER_USERS}_${SagaActionType.SUCCESS}`,
        payload: {
          page: response.data.page
          , data: response.data.data, total: response.data.total_count
        },
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}
function* fetchPlayerTags({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_TAGS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PLAYER_TAGS}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data },
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));
    yield put({
      type: `${SagaActions.PLAYER_TAGS}_${SagaActionType.SUCCESS}`,
      payload: { error: true },
    });
  }

}
function* createPlayerTag({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    const response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_TAGS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    if (response?.status === 201) {
      yield call(Success, { description: 'Tag Added Successfully.' });
      yield put({
        type: `${SagaActions.PLAYER_TAGS}_${SagaActionType.REQUEST}`,
        payload: {}
      })
      yield put({
        type: `${SagaActions.PLAYER_TAGS_CREATE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.PLAYER_TAGS_CREATE}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* deletePlayerTag({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const id = payload.id
    delete payload.id
    let response: any = yield call(gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_TAGS}/${id}`,
      { params: payload });
    if (response?.status === 200) {
      yield call(Success, { description: "Tag's Deleted Successfully." });
      yield put({
        type: `${SagaActions.PLAYER_TAGS}_${SagaActionType.REQUEST}`,
        payload: {}
      })
      yield put({
        type: `${SagaActions.PLAYER_TAGS_DELETE}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}
function* addPlayerTag({ payload }: any): Generator<any> {
  try {

    const response: any = yield call(gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_USERS}/${payload.id}/${ApiEndpoints.PLAYER_ADD_TAG}`,
      { tag_ids: JSON.stringify([...payload.data]), access_token: getToken() }
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'Tag Added Successfully.' });
      yield put({
        type: `${SagaActions.PLAYER_ADD_TAG}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.PLAYER_ADD_TAG}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* playerManagementRequest() {
  yield takeLatest(
    `${SagaActions.ADD_PLAYER_USERS}_${SagaActionType.REQUEST}`,
    fetchPlayerData
  );
}
function* playerTagRequest() {
  yield takeEvery(
    `${SagaActions.PLAYER_TAGS}_${SagaActionType.REQUEST}`,
    fetchPlayerTags
  );
}
function* createPlayerTagRequest() {
  yield takeEvery(
    `${SagaActions.PLAYER_TAGS_CREATE}_${SagaActionType.REQUEST}`,
    createPlayerTag
  );
}
function* deletePlayerTagRequest() {
  yield takeLatest(
    `${SagaActions.PLAYER_TAGS_DELETE}_${SagaActionType.REQUEST}`,
    deletePlayerTag
  );
}
function* addPlayerTagRequest() {
  yield takeEvery(
    `${SagaActions.PLAYER_ADD_TAG}_${SagaActionType.REQUEST}`,
    addPlayerTag
  );
}

export default function* rootPlayerManagement() {
  yield spawn(playerManagementRequest);
  yield spawn(playerTagRequest);
  yield spawn(createPlayerTagRequest);
  yield spawn(deletePlayerTagRequest);
  yield spawn(addPlayerTagRequest);
}