import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken, getCompanyID } from "../auth/localData";
import { history } from "@config/routes";
import { Error, Success, Waraning } from "../constant/toast/Toast";

function* fetchSeasonData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SEASONS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.SEASONS}_${SagaActionType.SUCCESS}`,
        payload: {
          page: response.data.page,
          data: response.data.data,
          total: response.data.total_count
        },
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}

function* deleteSeasonData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SEASONS}/${payload}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.DELETE_SEASONS}_${SagaActionType.SUCCESS}`,
      });
      yield call(Success, { description: 'Season deleted successfully.' });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}

function* createSeasonData({ payload }: any): Generator<any> {
  try {
    if (payload.edit) {
      let response: any = yield call(gcbAPI().put,
        `${ApiEndpoints.ADMIN}/${ApiEndpoints.SEASONS}/${payload.id}`,
        {
          access_token: getToken(),
          name: payload.season_name,
          start_date: payload.season_dates[0],
          end_date: payload.season_dates[1],
          company_id: getCompanyID(),
          is_active: payload.season_isActive
        });
      if (response?.status === 200) {
        yield call(Success, { description: 'Season updated successfully.' });
        yield call(history.replace, `/admin/season`);
        yield put({
          type: `${SagaActions.ACTIVE_SEASONS}_${SagaActionType.REQUEST}`,
          payload: {}
        });
      }
    } else {
      let response: any = yield call(gcbAPI().post,
        `${ApiEndpoints.ADMIN}/${ApiEndpoints.SEASONS}`,
        {
          access_token: getToken(),
          name: payload.season_name,
          start_date: payload.season_dates[0],
          end_date: payload.season_dates[1],
          company_id: getCompanyID(),
          is_active: payload.season_isActive
        });
      if (response?.status === 201) {
        yield call(Success, { description: 'Season created successfully.' });
        yield call(history.replace, `/admin/season`);
      }

    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}


function* fetchSeasonList({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SEASONS}/${ApiEndpoints.ACTIVE_SEASONS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.ACTIVE_SEASONS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data
        },
      });
    }
  }
  catch (error) { }
}

function* SeasonsRequest() {
  yield takeLatest(
    `${SagaActions.SEASONS}_${SagaActionType.REQUEST}`,
    fetchSeasonData
  );
}
function* SeasonsDeleteRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_SEASONS}_${SagaActionType.REQUEST}`,
    deleteSeasonData
  );
}
function* SeasonsCreateRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_SEASONS}_${SagaActionType.REQUEST}`,
    createSeasonData
  );
}
function* ActiveSeasonsListRequest() {
  yield takeLatest(
    `${SagaActions.ACTIVE_SEASONS}_${SagaActionType.REQUEST}`,
    fetchSeasonList
  );
}

export default function* rootSeasonManagement() {
  yield spawn(SeasonsRequest);
  yield spawn(SeasonsDeleteRequest);
  yield spawn(SeasonsCreateRequest);
  yield spawn(ActiveSeasonsListRequest);
}