import { MainLayout } from '@components/layouts'
import PageHeader from '@components/page/PageHeader'
import { Button, Card, Checkbox, Col, Modal, Radio, Row, Spin } from 'antd'
import { AddIconCard, CardUI } from './AddCard'
import { Link, useNavigate, useParams } from 'react-router-dom';
import ContestLibrary from '@screens/admin/contestsLibrary/ContestLibrary';
import { useEffect, useMemo, useState } from 'react';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import Htag from '../../../../../layout/H1';
import { AxiosResponse } from 'axios';
import { gcbAPI } from '@utils/APIInterceptor';
import { Error } from '@constant/toast/Toast';
import { getToken } from '@auth/localData';
import FormikTextField from '@components/inputFields/FormikTextField';
import { isNumber } from '@constant/number';
import { ErrorMessage } from 'formik';
import { URL_BINDER } from '@constant/url';

export default function AddScoringContest() {
    const {TOURNAMENT, NUM} = useParams();
    const [tempNAME, ID] = TOURNAMENT?.split(URL_BINDER) || [];
    const [visible, setVisible] = useState(false);
    const [contest, setContest] = useState<any>(undefined);
    const [rounds, setRounds] = useState<any>([]);
    const [contestType, setContestType] = useState<any>([]);
    const [adminFee, setAdminFee] = useState<any>(undefined);
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const ENDPOINT = `/admin/tournaments/${ID}/list_tournament_rounds`;
    const POSTENDPOINT = '/admin/tournament_contests';
    const contest_flights = useMemo(() => (contest?.contest_flights || []).map((div: any, ind: number) => `${div.leaderboard}${Boolean(ind !== (contest?.contest_flights.length - 1)) ? ', ' : ''}`), [contest])

    useEffect(() => {
        fetchData();
    }, [])
    const handleOk = async () => {
        let haveError = false;
        const array = rounds.map((item: any) => { if (item.checked) return item.id });
        if (array.length === 0) {
            setErrors({ rounds: '*Please select atleast one round' });
            haveError = true;
        }
        if (adminFee <= -1 || !adminFee) {
            setErrors((prev: any) => { return { ...prev, adminFee: '*Required' } });
            haveError = true;
        }
        if (haveError) return;
        const payload = {
            tournament_id: ID,
            contest_id: contest?.id,
            admin_fee: adminFee,
            prize_pool: rounds[0]?.prize_pool,
            double_dip_fee: adminFee + rounds[0]?.prize_pool,
            tournament_rounds: array,
            contest_type: contestType,
        }
        try {
            const response: AxiosResponse<any> = await gcbAPI().post(POSTENDPOINT, payload, { params: { 'access_token': getToken() } });
            const data: any = response.data;
            if (data?.success) {
                // navigate(`/admin/events/scorings/${ID}`);
                navigate(`/admin/tournaments/${TOURNAMENT}/scorings`);
            }
        } catch (error: any) {
            Error({ description: error?.data?.message })
        }
    }
    const fetchData = async () => {
        try {
            const response: AxiosResponse<ResponseType> = await gcbAPI().get(ENDPOINT, { params: { 'access_token': getToken() } });
            const data: any = response.data;
            if (data?.success) {
                const array = (data?.data || []).map((item: any) => ({ ...item, checked: true }));
                setRounds(array);
            }

        } catch (error: any) {
            Error({ description: error?.data?.message })
        }
    }
    const handleChange = (e: any) => {
        setErrors({});
        const { name, checked } = e.target;
        const array = rounds.map((item: any) => ({ ...item, checked: true }));
        setRounds(array);
    }
    const handleadminFeeChange = (e: any) => {
        setErrors({});
        const { value } = e.target;
        if (isNumber(value))
            setAdminFee(Number(value));
    }
    const handleContestChecked = (e: any) => {
        const { checked, name } = e.target;
        const arr = [...contestType]
        if (checked) {
            arr.push(name);
            setContestType(arr);
        } else {
            const index = arr.indexOf(name);
            if (index > -1) {
                arr.splice(index, 1);
                setContestType(arr);
            }
        }
    }
    return (
        <MainLayout>
            <>
                <PageHeader name={`Setting Up Contest ${NUM}`} style={{ marginBottom: '3rem' }} />
                <Col span={24} className='ml-2 input48'>
                    <Row className='mb-8 scoring-input-wrapper'>
                        {contest ?
                            <CardUI>
                                <Row className='h-100'>
                                    <EditOutlined onClick={() => { setVisible(true) }} className='p-abs pointer' style={{ right: '1rem', top: '1rem' }} />
                                    <Col className='mt-auto'>
                                        <Htag level={5} className='single-line'>{contest?.name}</Htag>
                                        {contest?.is_multiple_leaderboard ?
                                            <>
                                                <p className='single-line'>Multiple Leaderboards</p>
                                                <p className='single-line' title={contest_flights}>{contest_flights}</p>
                                            </>
                                            :
                                            <>
                                                <p className='single-line'>{contest?.contest_type}</p>
                                                <p className='single-line'>{contest?.scoring_type}</p>
                                            </>}
                                    </Col>
                                </Row>
                            </CardUI>
                            : <AddIconCard link='' fc={() => { setVisible(true) }} name="Add Contest's" />}

                    </Row>
                    <Row className='mb-6 black-radio scoring-input-wrapper'>
                        <Col span={24} className='mb-3 text-field-label'>
                            Select Round{rounds.length > 1 ? "'s" : ''}
                        </Col>
                        {((rounds) || []).map((round: any) => <Checkbox name={round.id} checked={round?.checked} onChange={handleChange} className='mr-4'>Round {round.round_number}</Checkbox>)}
                        <br />
                        <Col span={24} className='ant-form-item-explain-error'><span>{errors?.rounds}</span></Col>
                    </Row>
                    <Row className='mb-8 black-radio scoring-input-wrapper'>
                        <Col span={24} className='mb-3 text-field-label'>
                            Set default contest?
                        </Col>
                        <Checkbox defaultChecked={false} onChange={handleContestChecked} name='open' className='mr-4'>Open (Default)</Checkbox>
                        <Checkbox defaultChecked={false} onChange={handleContestChecked} name='senior_plus_double_dip' className='mr-4'>Senior + Double Dip</Checkbox>
                        <Checkbox defaultChecked={false} onChange={handleContestChecked} name='women_only'>Women Only(TBD)</Checkbox>
                    </Row>
                    <Row className='mb-10'>
                        <Col span={24} className='mb-3'>
                            Additional Amount To Be Charged At Check In For Double Dip
                        </Col>
                        <Col span={24} className='mb-3'>
                            <Row>
                                <Col className='w-150'>
                                    <FormikTextField
                                        label='Admin Fee'
                                        style={{ marginRight: '0.5rem' }}
                                        placeholder="0"
                                        className="input-name-padding"
                                        currency
                                        value={adminFee}
                                        onChange={handleadminFeeChange}
                                        error={errors?.adminFee}
                                    />
                                </Col>
                                <Col className='font-24 flex-center mb-2 mr-4 ml-4'> + </Col>
                                <Col className='w-150'>
                                    <FormikTextField
                                        label='Prize Pool/player'
                                        style={{ marginRight: '0.5rem' }}
                                        placeholder="0"
                                        className="input-name-padding w-120"
                                        currency
                                        value={rounds[0]?.prize_pool || 0}
                                        onChange={handleadminFeeChange}
                                        disabled
                                    />
                                </Col>
                                <Col className='font-24 flex-center mb-2 mr-4 ml-4'> = </Col>
                                <Col className='w-150'>
                                    <FormikTextField
                                        label='Double Dip fee'
                                        style={{ marginRight: '0.5rem' }}
                                        placeholder="0"
                                        className="input-name-padding"
                                        currency
                                        value={(adminFee || 0) + (Number(rounds[0]?.prize_pool) || 0)}
                                        onChange={handleadminFeeChange}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Button className='raw-button btnSpin' onClick={handleOk} style={{ background: '#047EB1', color: '#fff' }} >
                            {false ? <Spin size='small' /> : 'Submit'}
                        </Button>
                        <Button onClick={() => { navigate(-1) }} className="com-cancel raw-button my-auto text-b opacity-5">
                            Cancel
                        </Button>
                    </Row>
                </Col>
                <Modal
                    visible={visible}
                    footer={null}
                    closable={false}
                    centered={true}
                    onCancel={() => setVisible(false)}
                    width={'80%'}
                >
                    <Col span={24} className='m-2'>
                        <CloseCircleOutlined
                            style={{ transform: 'scale(2)', color: '#A6A8AB', marginLeft:'-.75rem' }}
                            className="pointer mb-8" onClick={() => { setVisible(false) }}
                        />
                        <ContestLibrary
                            isModal={true}
                            setContest={(param: any) => {
                                setVisible(false);
                                setTimeout(() => { setContest(param) }, 200)
                            }} />
                    </Col>
                </Modal>
            </>
        </MainLayout>
    )
}
