import { Button, Card, Space } from "antd";
import React from "react";
import { ReactComponent as CharmTick } from "@assets/icons/charm_circle-tick.svg";
import { useNavigate } from 'react-router-dom';
import './TournamentCardStyle.css'
import { ReactComponent as TickButton } from "@assets/icons/Vector.svg";

interface TournamentCardProps {
  title: string;
  subTitle: string;
  status?: string;
  pending?: boolean;
  assign?: boolean;
  editLink?: string;
  onEditClick?: any
  edit?: boolean;
}

const TournamentCard = ({
  title,
  subTitle,
  status,
  pending = false,
  assign = true,
  edit = true,
  editLink,
  onEditClick
}: TournamentCardProps) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    // Navigate to the next page and pass the title as a parameter only if it exists
    const state = title ? { title } : undefined;
    if (editLink) {
      // Navigate to the next page and pass the title as a parameter only if it exists
      const state = title ? { title } : undefined;
      navigate(editLink, { state: state || undefined });
    }
    if (onEditClick)
      onEditClick()
  };
  return (
    <>
      <Card className="card-container" >
        <div className="card-container-inside">
          <div onClick={handleEditClick} className="pointer">
            <div className="ant-card-head-title">
              <p>{title}</p>
              {!pending && <CharmTick />}
            </div>
            <div>
              <p className="card-text">{subTitle}</p>
            </div>
          </div>
          <div className="card-footer">
            <Space>
              {assign && (
                <Button className="assign-btn">
                  <u>Assign</u>
                </Button>
              )}

              {edit && <Button className="card-edit-btn" type="primary" ghost href={editLink} onClick={handleEditClick} >
                Edit
              </Button>}
              {!edit && <TickButton onClick={handleEditClick} style={{ cursor: 'pointer' }} />}
              {pending && (
                <Button className="card-pending-btn" type="primary" ghost>
                  Pending Review
                </Button>
              )}
            </Space>
          </div>
        </div>
      </Card>
    </>
  );
};

export default TournamentCard;
