import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";


const initialState = {
  registeredPlayers: [],
  isregisteredPlayersLoading: true,
  regPlayersStats: [],
  isRegPlayersStatsLoading: true,
  isCheckInCSVLoading: true,
  checkInCSV: [],
  total: 0,
  regPlayerInfo:[],
  isRegPlayerInfoLoading:true,
  checkInPlayerInfo:[],
  isCheckInPlayerLoading:true,
};

export const CheckInSlice = createSlice({
  name: "checkIn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_REG_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isRegPlayersStatsLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_REG_PLAYERS_STATS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          regPlayersStats: action.payload.data,
          isRegPlayersStatsLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isregisteredPlayersLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          registeredPlayers: action.payload.data,
          isregisteredPlayersLoading: false,
          total: action.payload.total,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CHECKIN_CSV}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isCheckInCSVLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CHECKIN_CSV}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          checkInCSV: action.payload.data,
          isCheckInCSVLoading: false
        };
      }
    );


    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REGISTERED_PLAYER_INFO}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isRegPlayerInfoLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REGISTERED_PLAYER_INFO}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          regPlayerInfo: action.payload.data,
          isRegPlayerInfoLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_CHECKIN}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_CHECKIN}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_CHECKIN}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_CHECKIN}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_CHECKIN}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isCheckInPlayerLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_CHECKIN}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          checkInPlayerInfo: action.payload.data,
          isCheckInPlayerLoading: false
        };
      }
    );

  },
})

export default CheckInSlice.reducer;  