import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from '../constant/toast/Toast';

function* fetchLeaderboard({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/leaderboard`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* fetchPlayerScores({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/player_scores`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_PLAYER_SCORES}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* updateScores({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/bulk_update_scores`,
      { ...payload },
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.UPDATE_SCORES}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      yield put({
        type: `${SagaActions.FETCH_PLAYER_SCORES}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.id,
          user_id: payload.playerId,
          tournament_round_id: payload.tournament_round_id,
        }
      });
      yield put({
        type: `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.id,
          standard_division_range_id: payload.divisionId,
        }
      });

    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* finalizeScores({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/finalize_scores`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.FINALIZE_SCORES}_${SagaActionType.SUCCESS}`,
      });
      // Call the Leaderboard API after success
      if(!payload.isEventControl){
      yield put({
        type: `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.id,
          standard_division_range_id: payload.divisionId,
        }
      });
    }
      if(payload.isEventControl){
      yield put({
        type: `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.REQUEST}`,
        payload: {
          tournamentId:payload.id,
        }
      })
    }
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.FINALIZE_SCORES}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}


function* LeaderBoardRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.REQUEST}`,
    fetchLeaderboard
  );
}

function* PlayerScoresRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_PLAYER_SCORES}_${SagaActionType.REQUEST}`,
    fetchPlayerScores
  );
}

function* UpdateScoresRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_SCORES}_${SagaActionType.REQUEST}`,
    updateScores
  );
}

function* FinalizeScoresRequest() {
  yield takeLatest(
    `${SagaActions.FINALIZE_SCORES}_${SagaActionType.REQUEST}`,
    finalizeScores
  );
}



export default function* rootLeaderboards() {
  yield spawn(LeaderBoardRequest);
  yield spawn(PlayerScoresRequest);
  yield spawn(UpdateScoresRequest);
  yield spawn(FinalizeScoresRequest);
}