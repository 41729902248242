import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface CoursesInterface {
    courses: any;
    isCoursesLoading: boolean;
    teeDetails:any;
    isTeesLoading: boolean;
    CoursesData:any[];
    isLoading:boolean;
    CourseInfo:any[];
}

const initialState: CoursesInterface = {
    courses: {},
    isCoursesLoading:true,
    teeDetails:{},
    isTeesLoading: true,
    CoursesData:[],
    isLoading:true,
    CourseInfo:[]
};

export const CoursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addMatcher(
        (action) =>
            action.type ===
            `${SagaActions.SEARCH_COURSES}_${SagaActionType.REQUEST}`,
        (state, action) => {
            return {
                ...state,
                isCoursesLoading:true
            };;
        }
       );
      builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SEARCH_COURSES}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    courses: action.payload.data,
                    isCoursesLoading:false
                  };
            }
        );

      builder.addMatcher(
          (action) =>
              action.type ===
              `${SagaActions.FETCH_TEE_DETAILS}_${SagaActionType.REQUEST}`,
          (state, action) => {
              return {
                  ...state,
                  isTeesLoading:true
              };;
          }
         );
      builder.addMatcher(
              (action) =>
                  action.type ===
                  `${SagaActions.FETCH_TEE_DETAILS}_${SagaActionType.SUCCESS}`,
              (state, action) => {
                  return {
                      ...state,
                      teeDetails: action.payload.data,
                      isTeesLoading:false
                    };
          }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.CREATE_COURSE}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                };
            }
           );
        builder.addMatcher(
                (action) =>
                    action.type ===
                    `${SagaActions.CREATE_COURSE}_${SagaActionType.SUCCESS}`,
                (state, action) => {
                    return {
                        ...state,
                      };
            }
          );
    
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.FETCH_COURSES}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                ...state,
                isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.FETCH_COURSES}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                ...state,
                isLoading: false,
                CoursesData: action.payload.data,
              };
            }
          );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.FETCH_COURSE_INFO}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                ...state,
                isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.FETCH_COURSE_INFO}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                ...state,
                isLoading: false,
                CourseInfo: action.payload.data,
              };
            }
          );
      
  },
})

export default CoursesSlice.reducer;  