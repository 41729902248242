import { forwardRef } from "react";
import { Form, Input } from "antd";

const { Item } = Form;

const FormikTextField: any = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      style,
      type,
      currency,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      {type === "textarea" ? (
        <Input.TextArea
          className="form-textarea-field-custom"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          value={value}
          rows={6}
          {...props}
        />
      ) : type === "Password" ? (
        <Input.Password
          className="form-input-field-custom"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          onBlur={onBlur}
          ref={ref}
          value={value}
          rows={6}
          {...props}
        />
      ) : (
        <Input
          className="form-input-field-custom"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          value={value}
          style={currency ? { paddingLeft: "1.25rem" } : {}}
          {...props}
        />
      )}
      {currency && (
        <span className="p-abs color-PH" style={{ left: 10, top: "35px" }}>
          $
        </span>
      )}
    </Item>
  )
);

export default FormikTextField;
