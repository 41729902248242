import { factory } from "typescript";
import { USER_ROLE_INTERFACE, getLocalVal } from "./localData";

type RoleProps =
    | "company_owner"
    | "company_admin"
    | "chapter_admin"
    | "chapter_owner"
    | "tournament_director"
    | "tournament_staff"
    | "super_admin"

export const isC_Owner = () =>
    USER_ROLE() === "company_owner";
export const isC_Admin = () =>
    USER_ROLE() === "company_admin";
export const isCh_Owner = () =>
    USER_ROLE() === "chapter_owner";
export const isCh_Admin = () =>
    USER_ROLE() === "chapter_admin";
export const isTournament_Director = () =>
    USER_ROLE() === "tournament_director";
export const isTournament_Staff = () =>
    USER_ROLE() === "tournament_staff";
export const isSuper_Admin = () =>
    USER_ROLE() === "super_admin";

export const USER_ROLE = () => getLocalVal(USER_ROLE_INTERFACE);
export const approveNotification = () => {
    if (isC_Owner()) {
        return true;
    }
    return false;
};

export const whoCanSeeNotification = () => {
    // const USER_ROLE = getLocalVal(USER_ROLE)
    if (isC_Owner()) {
        return true;
    } else if (isC_Admin()) {
        return true;
    } else if (isCh_Owner()) {
        return true;
    } else if (isCh_Admin()) {
        return true;
    } else if (isSuper_Admin()) {
        return true;
    }
    return false;
};
export const canSelectChapter = () => {
    if (isC_Owner()) {
        return true;
    } else if (isC_Admin()) {
        return true;
    } else if (isSuper_Admin()) {
        return true;
    }
    return false;
}