import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success, Waraning } from "../constant/toast/Toast";


function* setImportContacts({ payload }: any): Generator<any> {
    try {
        payload.access_token = getToken()
        let response: any = yield call(gcbAPI().post,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.MARKETINGS}/${ApiEndpoints.IMPORT_CONTACTS}`,
            { ...payload },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        if (response?.status === 201 || response?.status === 200) {
            yield put({
                type: `${SagaActions.IMPORT_CONTACTS}_${SagaActionType.SUCCESS}`,
                payload: response.data.data,
            });
        }
    }
    catch (error: any) {
        yield put({
            type: `${SagaActions.IMPORT_CONTACTS}_${SagaActionType.FAIL}`,
        });
        yield call(Error, { description: error?.data?.message });
    }
}

function* setImportContactsRequest(): Generator<any> {
    yield takeLatest(`${SagaActions.IMPORT_CONTACTS}_${SagaActionType.REQUEST}`, setImportContacts);
}

export default function* MarketingsRoot(): Generator<any> {
    yield spawn(setImportContactsRequest);
}