import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Skeleton, Tag, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { SagaActionType, SagaActions } from "@sagas/index";
import { useAppSelector } from '@utils/reduxHooks';

interface PlayerInfo {
  name: string;
  hcp_value: number;
  gender_and_age: string;
  tags: any;
}


type PlayerInfoState = {
  PlayerInfo: PlayerInfo;
  isPlayerInfoLoading: boolean;
}

interface PlayerInfoToolTipProps {
  id: number;
}



const PlayerInfoToolTip: React.FC<PlayerInfoToolTipProps> = ({ id }) => {
  const dispatch = useDispatch();
  const playerInfoState: PlayerInfoState = useAppSelector((state) => state.allPlayers);

  const playerInfo = (player_id: number) => {
    const payload = {
      id: player_id
    }
    dispatch({ type: `${SagaActions.PLAYER_INFO_FETCH}_${SagaActionType.REQUEST}`, payload });
  }

  const toolTipContent = (
    <div className="playerInfoTooltip">
      <div className="name">
        <b>{playerInfoState?.PlayerInfo?.name}</b>
        <ArrowRightOutlined />
      </div>
      <p>HCP: {playerInfoState?.PlayerInfo?.hcp_value}</p>
      <p>{playerInfoState?.PlayerInfo?.gender_and_age}</p>
      <p>Notes: Slow Player is not eligible for Stroke Play Open. But can participate for all others</p>
      <p>{playerInfoState.PlayerInfo?.tags?.map((tag:string)=>
        <Tag>{tag}</Tag>
      )}</p>
    </div>
  )

  return (
    <Tooltip
      title={playerInfoState?.isPlayerInfoLoading ?
        <div style={{ width: 220, padding: 10 }}>
          <Skeleton active />
        </div> :
        toolTipContent}
      color="#fff"
      trigger='click'
      placement='bottom'
    >
      <InfoCircleOutlined onClick={() => playerInfo(id)}  className="p-abs" style={{right:'.5rem', top:28}}/>
    </Tooltip>
  )
}

export default PlayerInfoToolTip;
