export const URL_BINDER = '+'

export const urlSpaceBinder = (url: string) => ((url || '').replace(/%20/g, ' ') || '')?.replace(/%/g, ' ')

export const getPathName = (url: string | undefined) => {
    if (!url) return ''
    const urlArray = url.split(URL_BINDER)
    const deepCopy = JSON.parse(JSON.stringify(urlArray))
    if (deepCopy.length === 0) return ''
    else if (deepCopy.length === 1) return urlSpaceBinder(deepCopy[0])
    else {
        const last = deepCopy.pop()
        return urlSpaceBinder(deepCopy.join(''))
    }
}

export const getIDfromURL = (url: string|undefined) => {
    if (!url) return ''
    const urlArray = url.split(URL_BINDER)
    if (urlArray.length === 2) {
        return parseInt(urlArray[1])
    }
    return ''
}