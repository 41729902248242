import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface PrizesAwardsInterface {
    PrizesAwardsData: any[];
    isLoading: boolean;
    isActiveLoading: boolean;
    PrizesAwardsCardData: any;
}
const initialState: PrizesAwardsInterface = {
    PrizesAwardsData: [],
    isLoading: true,
    isActiveLoading: false,
    PrizesAwardsCardData: {},
};
export const PrizesAwardsSlice = createSlice({
    name: "prizesAwards",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRIZES_AWARDS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    PrizesAwardsData: action.payload.data,

                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    PrizesAwardsCardData: action.payload.data,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                    isActiveLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                const data = state.PrizesAwardsData.filter((item) => item.id !== action?.payload?.id);
                return {
                    ...state,
                    isLoading: false,
                    isActiveLoading: false,
                    PrizesAwardsData: data,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.CREATE_PAYOUT_MATRIX}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.CREATE_PAYOUT_MATRIX}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                };;
            }
        );
    },
})

export default PrizesAwardsSlice.reducer;  