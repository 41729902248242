import React from 'react'
import { ReactComponent as InfoIcon } from "@assets/icons/Notification.svg";
import { Button, Col, Modal, Spin } from 'antd';
import Htag from '@layout/H1';

interface ConfirmationModalProps {
    modalOpen:boolean;
    setModalOpen:React.Dispatch<React.SetStateAction<boolean>>;
    heading?:string;
    description?:string;
    onConfirm:()=>void;
    onCancel:()=>void;
    isDanger?:boolean;
    btnText?:string;
    isLoading?:boolean;
}
export function ConfirmationModal({modalOpen,setModalOpen, onCancel, onConfirm,isLoading, isDanger, heading, description, btnText}:ConfirmationModalProps) {
  return (
    <Modal
    open={modalOpen}
    onCancel={()=>setModalOpen(false)}
    className=''
    closable={false}
    footer={null}
    width={620}
    style={{maxWidth:'90%'}}
    centered
  >
  <Col style={{textAlign:'center'}}>
      <InfoIcon />
      <div style={{ margin: 20 }}>
        <Htag level={2}><b>{heading}</b></Htag>
        <p style={{fontSize:18}}><b>{description}</b></p>
      </div>
      <Button
        type="primary"
        className='mr-2 h-50 text-bold btnSpin d-border'
        style={isDanger ? {background:'#BF0000'}:{background:'#0ABBF2'}  }
        onClick={onConfirm}
      >
        {isLoading? <Spin /> :  btnText ? btnText : 'Confirm'}
      </Button>
      <Button
        size={"middle"}
        onClick={onCancel}
        className='ml-2 h-50'
      >
        Cancel
      </Button>
    </Col> 
  </Modal>
  )
}