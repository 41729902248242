import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface SideGamesInterface {
  SideGamesData: any[];
  isLoading: boolean;
  TournamentRounds:any[];
  SideGamesList:any[];
}
const initialState: SideGamesInterface = {
  SideGamesData: [],
  isLoading: true,
  TournamentRounds:[],
  SideGamesList:[]
};
export const SideGameslice = createSlice({
  name: "sideGames",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_ROUNDS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_ROUNDS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          TournamentRounds: action.payload.data,
        };;
      }
    );


    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_SIDE_GAME}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          SideGamesList: action.payload.data,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_SIDE_GAME}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );

  },
})

export default SideGameslice.reducer;  