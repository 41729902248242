import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken, getCompanyID } from "../auth/localData";
import { history } from "@config/routes";
import { Error, Success, Waraning } from "../constant/toast/Toast";
import { ADD_PLAYER_IN_LIST, REMOVE_PLAYER_FROM_LIST } from "@slices/allPlayersSlice";



function* deletePairingData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING_PLAYERS}/${payload}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data.data }
      });
      yield put({
        type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${ADD_PLAYER_IN_LIST}`,
        payload: response?.data.data
      });
    }
  }
  catch (error) {
    yield put({
      type: `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.FAIL}`,
    });
    yield call(Error, { description: 'Something went wrong (BE:404). Please refresh the page or try again.' });
  }

}

function* AddPlayerPairingData({ payload }: any): Generator<any> {
  const format_payload = payload.data.map((player: any) => {
    let tempObj = {
      id: player?.id,
      player_id: player.player_id,
      player_name: player.player_name,
      hole: player.hole,
      player_number: player.player_number,
      hole_name: player.hole_name,
      is_internal: payload.is_internal,
      tee_time: player.tee_time,
      division_name: player.division_name,
      inner_color: player.inner_color,
      outer_color: player.outer_color,
    }
    if (!payload.is_internal) {
      delete tempObj.is_internal
    }
    if (!player?.id) {
      delete tempObj.id
    }
    return tempObj
  })
  delete payload.is_internal
  try {
    let response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING_PLAYERS}`,
      {
        access_token: getToken(),
        pairing_id: payload.pairing_id,
        tournament_id: payload.tournament_id,
        is_internal: payload.is_internal,
        pairing_players: format_payload,
        tournament_round_course_id: payload.id,
      });
    if (response?.status === 201) {
      yield put({
        type: `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data.data }
      });
      yield put({
        type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${REMOVE_PLAYER_FROM_LIST}`,
        payload: response?.data.data
      });
    }
  }
  catch (error) {
    yield put({
      type: `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.FAIL}`,
    });
    yield call(Error, { description: 'Something went wrong (BE:404). Please refresh the page or try again.' });

  }
}
function* FetchPlayerPairingData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING}/${ApiEndpoints.PAIRING_DETAILS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PAIRING_PLAYERS_FETCH}_${SagaActionType.SUCCESS}`,
        payload: response.data.data,
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}
function* FetchCourseListData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ROUNDS}/${ApiEndpoints.LIST_TOURNAMENT_ROUNDS_COURSES}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PAIRING_COURSE_LIST}_${SagaActionType.SUCCESS}`,
        payload: response.data.data,
      });
    }
  }
  catch (error) {
    // yield put (setPermissionData(error));

  }

}
function* RemovePlayerPairingData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING}/${ApiEndpoints.DELETE_PAIRINGS_COLUMN}`,
      {
        params: {
          access_token: getToken(),
          ...payload
        }
      });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data.data }
      });
      yield put({
        type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${ADD_PLAYER_IN_LIST}`,
        payload: { data: response?.data.data }
      });
    }
  }
  catch (error: any) {
    if (error?.status !== 422)
      yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
  }
}
function* AddCustomHole({ payload }: any): Generator<any> {
    let { hole_details, ...params} = payload
    let temphole_details = hole_details.map((hole: any) => {return{hole_name: hole.name, tee_time: hole.teeTime}}) 
  try {
    let response: any = yield call(gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING}/${ApiEndpoints.ADD_CUSTOM_HOLE}`,
      {
        access_token: getToken(),
        ...params,
        hole_details : temphole_details
      });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.ADD_CUSTOM_HOLE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data, holes: hole_details.map((hole: any) => hole.name) }
      });
    }
  }
  catch (error: any) {
    if (error?.status !== 422)
      yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
  }
}
function* AddPairingHole({ payload }: any): Generator<any> {
  let { hole_details, ...params} = payload
  hole_details = hole_details.map((hole: any) => {return{hole_name: hole.name, tee_time: hole.teeTime}}) 
try {
  let response: any = yield call(gcbAPI().put,
    `${ApiEndpoints.ADMIN}/${ApiEndpoints.PAIRING}/${ApiEndpoints.UPDATE_PAIRING_HOLES}`,
    {
      access_token: getToken(),
      ...params,
      hole_details
    });
  if (response?.status === 200) {

  }
}
catch (error: any) {
  if (error?.status !== 422)
    yield call(Error, { description: error?.data?.message });
}
}
function* PairingRemovePlayerRequest() {
  yield takeLatest(
    `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.REQUEST}`,
    deletePairingData
  );
}
function* PairingAddPlayerRequest() {
  yield takeLatest(
    `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.REQUEST}`,
    AddPlayerPairingData
  );
}
function* PairingFetchPlayerRequest() {
  yield takeLatest(
    `${SagaActions.PAIRING_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
    FetchPlayerPairingData
  );
}
function* FetchCourseListDataRequest() {
  yield takeLatest(
    `${SagaActions.PAIRING_COURSE_LIST}_${SagaActionType.REQUEST}`,
    FetchCourseListData
  );
}
function* DeletePairingColumnRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.REQUEST}`,
    RemovePlayerPairingData
  );
}
function* AddCustomHoleRequest() {
  yield takeLatest(
    `${SagaActions.ADD_CUSTOM_HOLE}_${SagaActionType.REQUEST}`,
    AddCustomHole
  );
}
function* AddPairingHoleRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_PAIRING_HOLES}_${SagaActionType.REQUEST}`,
    AddPairingHole
  );
}
export default function* rootPairingManagement() {
  yield spawn(PairingRemovePlayerRequest);
  yield spawn(PairingAddPlayerRequest);
  yield spawn(PairingFetchPlayerRequest);
  yield spawn(FetchCourseListDataRequest);
  yield spawn(DeletePairingColumnRequest);
  yield spawn(AddCustomHoleRequest);
  yield spawn(AddPairingHoleRequest);
}