import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface membershipsInterface {
  data: any[]; // player data storage
  isLoading: boolean; // loader
  total: number; // total count for pagination
  isUpdating: boolean; // loader for update
}
const initialState: membershipsInterface = {
  data: [],
  isLoading: true,
  total: 0,
  isUpdating: false,
};
export const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MEMBERSHIP}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          total: action.payload.total,
          data: action.payload.data,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MEMBERSHIP}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_MEMBERSHIP}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isUpdating: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isUpdating: false,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_MEMBERSHIP}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isUpdating: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_MEMBERSHIP}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isUpdating: false,
        };
      }
    );
  },
})

export default membershipSlice.reducer;  