import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";


const initialState = {
    cartSignData: [],
    isLoading: true,
    tournamentRoundCoursesData:[],
    scoreCardsData: [],
    nationalSponsors:[],
    cartSignInfo:[],
    isCarsignInfoLoading:true,
    isScorecardInfoLoading:true,
    scorecardInfo:[],
    cartSignCreated:undefined,
    scorecardCreated:undefined,
    isNatSponsLoading:true,
    isCartSignLoading:true,
    isScorecardLoading:true,
};
export const PrintingsSlice = createSlice({
    name: "printings",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRINT_CART_SIGN}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isCartSignLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRINT_CART_SIGN}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isCartSignLoading: false,
                    cartSignData: action.payload.data,
                };
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.TOURNAMENT_ROUND_COURSES}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.TOURNAMENT_ROUND_COURSES}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    tournamentRoundCoursesData: action.payload.data.data,
                    cartSignCreated:action.payload.data.cart_sign_created,
                    scorecardCreated:action.payload.data.scorecard_created,
                };
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRINT_SCORECARD}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isScorecardLoading: true,
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.PRINT_SCORECARD}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isScorecardLoading: false,
                    scoreCardsData: action.payload.data,
                };
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.FETCH_NATIONAL_SPONSORS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isNatSponsLoading: true,
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.FETCH_NATIONAL_SPONSORS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isNatSponsLoading: false,
                    nationalSponsors: action.payload.data,
                };
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.CREATE_CART_SIGN}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.CREATE_CART_SIGN}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: false,
              };;
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_CART_SIGN}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_CART_SIGN}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: false,
              };
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.SHOW_CART_SIGN}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                ...state,
                isCarsignInfoLoading: true,
              };
            }
          );
          builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.SHOW_CART_SIGN}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                ...state,
                isCarsignInfoLoading: false,
                cartSignInfo: action.payload.data,
              };
            }
          );

          builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.CREATE_SCORECARD}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.CREATE_SCORECARD}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: false,
              };;
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_SCORECARD}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: true,
              };
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_SCORECARD}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  isLoading: false,
              };
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.SHOW_SCORECARD}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                ...state,
                isScorecardInfoLoading: true,
              };
            }
          );
          builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.SHOW_SCORECARD}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                ...state,
                isScorecardInfoLoading: false,
                scorecardInfo: action.payload.data,
              };
            }
          );
    },
})

export default PrintingsSlice.reducer;  