import { Button, Col, Modal, Spin, Tree } from "antd";
import { BalenceDescription, ExpenseTitle, RevenueTitle } from "./Component";
import { CarryOutOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import './AccountingStyle.css'
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { URL_BINDER } from "@constant/url";
import { DataNode } from "antd/lib/tree";
import './AccountingStyle.css'
import { useAppSelector } from "@utils/reduxHooks";
import { SagaActionType, SagaActions } from "@sagas/index";
import { useDispatch } from "react-redux";
import { isC_Admin, isC_Owner } from "@auth/permission";
import axios from "axios";
import { getChapterID } from "@auth/localData";
interface Balance {
    setActiveMenu?: (val: string) => void
    dateRange?: any
    filter?: any
}
export default function Balance({ setActiveMenu, filter = {}, dateRange }: Balance) {
    const [edit, setEdit] = useState(false)
    const { TOURNAMENT } = useParams();
    const [tempNAME, tournamnetID] = TOURNAMENT?.split(URL_BINDER) || [];
    const ID = useMemo(() => {
        return filter.tournament_id || tournamnetID;
    }, [tournamnetID, filter.tournament_id])
    const isTournament = useMemo(() => {
        return (ID || filter.tournament_id) ? true : false;
    }, [ID, filter.tournament_id])
    const dispatch = useDispatch();
    const { balanceSheetId, BalanceSheet, isBalanceSheetLoading, isMainAccountingsLoading, istournamentAccountingsLoading, BalanceSheetData, BalanceSheetDataLoading } = useAppSelector((state: any) => state.accounting)
    const editFun = () => {
        setEdit(!edit)
    }
    const onSelect = (selectedKeys: any, info: any) => {
        const KEY = info.node.key;
        if (KEY.includes('-') && info?.node?.children.length === 1 && info?.expanded) {
            const [, id] = KEY.split('-');
            const flag = isNaN(parseInt(id))
            if (flag) return;
            if (KEY.includes('rc')) {
                dispatch({
                    type: `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.REQUEST}`,
                    payload: {
                        id: id,
                    },
                })
            } else if (KEY.includes('ec')) {
                dispatch({
                    type: `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.REQUEST}`,
                    payload: {
                        id: id,
                    },
                })
            } else if (KEY.includes('rt')) {
                dispatch({
                    type: `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.REQUEST}`,
                    payload: {
                        id: id,
                        isChapter: true,
                        isExpense: false,
                    },
                })
            } else if (KEY.includes('et')) {
                dispatch({
                    type: `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.REQUEST}`,
                    payload: {
                        id: id,
                        isChapter: true,
                        isExpense: true,
                    },
                })
            }
        }
    };
    let TreeData: any = useMemo(() => {
        return [
            {
                title: <RevenueTitle title="All Revenue" />,
                key: 'r',
                icon: <CarryOutOutlined />,
                children: [
                    ...BalanceSheetData?.revenue?.map((item: any, index: number) => {
                        if (!item?.name) {
                            return {
                                title: <Col span={24} className='d-border'><BalenceDescription title="Tournament Revenue" price={item?.total_amount} index={0} /></Col>,
                                key: `rcAmount`,
                                icon: <CarryOutOutlined />,
                                children: [
                                ],
                            }
                        }
                        return ({
                            title: <BalenceDescription key={item?.id} title={item?.name} price={item.amount} index={1} edit={edit} />,
                            key: `rc-${item?.id}`,
                            icon: <CarryOutOutlined />,
                            children: [
                                ...(item?.data || [{}]).map((tournament: any, index: number) => {
                                    if (!tournament?.name) {
                                        return {
                                            title: <Col span={24} className='d-border'><BalenceDescription title="Tournament Revenue" price={tournament?.total_amount} index={0} /></Col>,
                                            key: `rtAmount${index}`,
                                            icon: <CarryOutOutlined />,
                                            children: [
                                            ],
                                        }
                                    }
                                    return ({
                                        title: <BalenceDescription key={tournament?.id} title={tournament?.name} price={tournament.amount} index={1} edit={edit} />,
                                        key: `rt-${tournament?.id}`,
                                        icon: <CarryOutOutlined />,
                                        children: [
                                            ...(tournament?.data || [{}])?.map((entry: any, index: number) => {

                                                const name = entry?.transaction_reason ? entry?.transaction_reason : entry?.type === 'event_registrations' ? 'Event Registrations' : undefined;
                                                if (!name) {
                                                    return {
                                                        title: <Col span={24} className='d-border'><BalenceDescription title="Total" price={entry?.total_amount} index={0} /></Col>,
                                                        key: `rteAmount${tournament?.id}${index}`,
                                                        icon: <CarryOutOutlined />,
                                                        children: [
                                                        ],
                                                    }
                                                }
                                                return ({
                                                    title: <BalenceDescription key={entry?.id} title={name} price={entry.amount} index={index} edit={edit} onDelete={() => onDelete(entry?.id)} isId={entry?.id ? true : false} />,
                                                    key: `rte${tournament?.id}${index}`,
                                                    icon: <CarryOutOutlined />,
                                                    children: [
                                                    ],
                                                })
                                            }
                                            )
                                        ],
                                    })
                                },)
                            ],
                        })
                    }),
                ]
            },
            {
                title: <ExpenseTitle title="All Expenses" />,
                key: 'e',
                className: 'mt-10',
                icon: <CarryOutOutlined />,
                children: [
                    ...BalanceSheetData?.expense?.map((item: any, index: number) => {
                        if (!item?.name) {
                            return {
                                title: <Col span={24} className='d-border'><BalenceDescription title="Tournament Expense" price={item?.total_amount} index={0} /></Col>,
                                key: `ecAmount`,
                                icon: <CarryOutOutlined />,
                                children: [
                                ],
                            }
                        }
                        return ({
                            title: <BalenceDescription key={item?.id} title={item?.name} price={item.amount} index={1} edit={edit} />,
                            key: `ec-${item?.id}`,
                            icon: <CarryOutOutlined />,
                            children: [
                                ...(item?.data || [{}]).map((tournament: any, index: number) => {
                                    if (!tournament?.name) {
                                        return {
                                            title: <Col span={24} className='d-border'><BalenceDescription title="Tournament Revenue" price={tournament?.total_amount} index={0} /></Col>,
                                            key: `etAmount${item?.id}`,
                                            icon: <CarryOutOutlined />,
                                            children: [
                                            ],
                                        }
                                    }
                                    return ({
                                        title: <BalenceDescription key={tournament?.id} title={tournament?.name} price={tournament.amount} index={1} edit={edit} />,
                                        key: `et-${tournament?.id}`,
                                        icon: <CarryOutOutlined />,
                                        children: [
                                            ...(tournament?.data || [{}])?.map((entry: any, index: number) => {
                                                if (!entry?.amount) {
                                                    return {
                                                        title: <Col span={24} className='d-border'><BalenceDescription title="Total" price={entry?.total_amount} index={0} /></Col>,
                                                        key: `eteAmount${tournament?.id}${index}`,
                                                        icon: <CarryOutOutlined />,
                                                        children: [
                                                        ],
                                                    }
                                                }
                                                return ({
                                                    title: <BalenceDescription key={entry?.id} title={entry?.transaction_reason || entry?.type} price={entry.amount} index={index} edit={edit} onDelete={() => onDelete(entry?.id)} isId={entry?.id ? true : false} />,
                                                    key: `ete${tournament?.id}${index}`,
                                                    icon: <CarryOutOutlined />,
                                                    children: [
                                                    ],
                                                })
                                            }
                                            )
                                        ],
                                    })
                                },)
                            ],
                        })
                    }),
                ],
            },
        ]
    }, [BalanceSheetData, BalanceSheetDataLoading])

    useEffect(() => {
        if (!isMainAccountingsLoading && !istournamentAccountingsLoading) {
            if (isTournament) {
                // if (ID !== balanceSheetId) {
                const payload: any = {
                    id: filter?.tournament_id || ID,
                }
                if (dateRange[0]) {
                    payload['start_date'] = dateRange[0]
                    payload['end_date'] = dateRange[1]
                }
                dispatch({
                    type: `${SagaActions.BALANCE_SHEET}_${SagaActionType.REQUEST}`,
                    payload: {
                        id: ID,
                        ...payload,
                    },
                })
                // }
            }
            else {
                if ((isC_Admin() || isC_Owner()) && !filter?.chapter_id) {
                    // if (BalanceSheetData.revenue.length === 0 && BalanceSheetData.expense.length === 0) {
                    dispatch({
                        type: `${SagaActions.LIST_CHAPTER_REVENUES}_${SagaActionType.REQUEST}`,
                        payload: {},
                    })
                    dispatch({
                        type: `${SagaActions.LIST_CHAPTER_EXPENSES}_${SagaActionType.REQUEST}`,
                        payload: {},
                    })
                    // }
                } else {
                    // if (BalanceSheetData.revenue.length === 0 && BalanceSheetData.expense.length === 0) {
                    const ID = filter?.chapter_id ? filter?.chapter_id : getChapterID();
                    dispatch({
                        type: `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.REQUEST}`,
                        payload: {
                            id: ID,
                            isChapter: true,
                        },
                    })
                    dispatch({
                        type: `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.REQUEST}`,
                        payload: {
                            id: ID,
                            isChapter: true,
                        },
                    })
                    // }
                }
            }
        }
    }, [isMainAccountingsLoading, istournamentAccountingsLoading, filter?.chapter_id, filter?.tournament_id]);

    const onDelete = (id: number) => {
        Modal.confirm({
            title: "Are you sure?",
            onOk: () => {
                dispatch({
                    type: `${SagaActions.DELETE_ENTRY}_${SagaActionType.REQUEST}`,
                    payload: {
                        id,
                        tournament_id: ID,
                        isBudgetAPIReload: isTournament ? true : false
                    },
                })
            },
        });
    };
    return (
        isTournament ? (isBalanceSheetLoading ? <div style={{ textAlign: 'center' }} className='mt-10'><Spin /></div> :
            <Col span={24} className="text-bold">
                <Col span={24} className='flex-end'>
                    {edit ?
                        <UnlockOutlined className="balence-edit-clock b-r-5 pointer" onClick={editFun} />
                        :
                        <LockOutlined className="balence-edit-clock b-r-5 pointer" onClick={editFun} />
                    }
                </Col>
                {BalanceSheet?.revenue?.revenues?.length !== 0 &&
                    <Col span={24} className="mb-10">
                        <Col span={24} className="mb-2">Revenue</Col>
                        <RevenueTitle title="Revenue" />
                        {
                            BalanceSheet?.revenue?.revenues?.map((revenue: any, index: number) =>
                                <BalenceDescription key={revenue?.id || index} title={revenue?.transaction_reason || 'Event Registrations'} price={revenue?.amount.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} index={index} edit={edit} onDelete={() => onDelete(revenue?.id)} isId={revenue?.id ? true : false} />
                            )
                        }
                        <RevenueTitle title="Total Revenue" price={BalanceSheet?.revenue?.total} />
                    </Col>
                }
                {BalanceSheet?.expense?.expenses?.length !== 0 &&
                    <Col span={24} className="mb-10">
                        <Col span={24} className="mb-2">Expense</Col>
                        <ExpenseTitle title="Expense" />
                        {
                            BalanceSheet?.expense?.expenses?.map((expense: any, index: number) =>
                                <BalenceDescription key={expense?.id} title={expense?.transaction_reason} price={expense?.amount.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} index={index} edit={edit} onDelete={() => onDelete(expense?.id)} isId={expense?.id ? true : false} />
                            )
                        }
                        <ExpenseTitle title="Total Expense" price={BalanceSheet?.expense?.total} />
                    </Col>
                }
                {setActiveMenu &&
                    <Button className='raw-button' style={{ backgroundColor: '#0ACFF2' }} onClick={() => { setActiveMenu('Create Entry') }}>+ Add Entry</Button>
                }
            </Col>
        )
            :
            <Col span={24} className="text-bold balence-tree-wrapper">
                {<Tree
                    showLine={true}
                    treeData={TreeData}
                    onExpand={onSelect}
                    checkStrictly={true}
                    expandAction={'click'}
                />}
            </Col>
    )
}