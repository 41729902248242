import { createSlice } from '@reduxjs/toolkit';
import { SagaActionType, SagaActions } from '@sagas/index';
import { sortArrayFun } from '../constant/helperFunction';

export const ADD_PLAYER_IN_LIST = 'ADD_PLAYER_IN_LIST'
export const REMOVE_PLAYER_FROM_LIST = 'REMOVE_PLAYER_FROM_LIST'
export interface UsersInterface {
  PlayersData: any[]; // Players data storage
  isLoading: boolean; // loader
  PlayerInfo: any;   // Player Info storage
  isPlayerInfoLoading: boolean; // loader for Player Info
}
// create index with pageNumber + filterType + searchStrings =====> this will help to reduce api call
const initialState: UsersInterface = {
  PlayersData: [],
  isLoading: true,
  PlayerInfo: [],
  isPlayerInfoLoading: true,
};
export const AllPlayersSlice = createSlice({
  name: 'allPlayers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          PlayersData: action.payload.data,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${ADD_PLAYER_IN_LIST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${ADD_PLAYER_IN_LIST}`,
      (state, action) => {
        let data = [...state.PlayersData]
        if (action.payload?.data) {
          data = [...data, ...action.payload.data]
        } else {
          data.push(action.payload)
        }
        data = sortArrayFun('name', data)
        return {
          ...state,
          PlayersData: [...data],
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${REMOVE_PLAYER_FROM_LIST}`,
      (state, action) => {
        let array: any[] = [...state.PlayersData]
        for (let i of action.payload) {
          const INDEX = array.findIndex((player) => player.player_id === i.player_id)
          if (INDEX !== -1) {
            array.splice(INDEX, 1);
          }
        }
        return {
          ...state,
          PlayersData: array,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_INFO_FETCH}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayerInfoLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYER_INFO_FETCH}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isPlayerInfoLoading: false,
          PlayerInfo: action.payload.data,
        };
      }
    );
  },
});

export default AllPlayersSlice.reducer;
