import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken, getCompanyID } from "../auth/localData";
import { Error, Success, Waraning } from "../constant/toast/Toast";
import { getChapterID } from "../auth/localData";
import { isC_Admin, isC_Owner } from "@auth/permission";

function* permissionData({ payload }: any): Generator<any> {
  const ID = isC_Admin() || isC_Owner() ? { company_id: getCompanyID() } : { chapter_id: getChapterID() };
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PERMISSION_MATRIX}`,
      { params: { ...ID, access_token: getToken() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.PERMISSION_MATRIX}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);
  }
}

function* permissionUpdateData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PERMISSION_MATRIX_UPDATE}`,
      {
        company_id: getCompanyID(),
        access_token: localStorage.getItem("accessToken"),
        permissions: payload.data.data
      });
    if (response?.status === 200) {
      yield call(Success, { description: 'Permissions Updated successfully.' });
      const { data } = response || {};
      yield put({
        type: `${SagaActions.PERMISSION_MATRIX_update}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);
  }
}

function* permissionResetData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PERMISSION_MATRIX_RESET}`,
      {
        id: getChapterID(),
        access_token: localStorage.getItem("accessToken"),
      });
    if (response?.status === 200) {
      yield call(Success, { description: 'Default Permissions set successfully.' });
      const { data } = response || {};
      yield put({
        type: `${SagaActions.PERMISSION_MATRIX_reset}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);
  }
}


function* PermissionRequest() {
  yield takeLatest(
    `${SagaActions.PERMISSION_MATRIX}_${SagaActionType.REQUEST}`,
    permissionData
  );
  yield takeLatest(
    `${SagaActions.PERMISSION_MATRIX_update}_${SagaActionType.REQUEST}`,
    permissionUpdateData
  );
  yield takeLatest(
    `${SagaActions.PERMISSION_MATRIX_reset}_${SagaActionType.REQUEST}`,
    permissionResetData
  );
}
export default function* rootPermissionManagement() {
  yield spawn(PermissionRequest);
}