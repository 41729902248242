import { MainLayout } from "@components/layouts";
import PageHeader from "@components/page/PageHeader";
import TournamentCard from "@components/tournamentCard";
import { ApiEndpoints } from "@sagas/index";
import { gcbAPI } from "@utils/APIInterceptor";
import { Col, Row, Spin } from "antd";
import { getToken } from "../../../auth/localData";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from 'axios';
import { Error } from "../../../constant/toast/Toast";
import { URL_BINDER } from "@constant/url";

interface PairingListItem {
    id: number;
    round_number: number;
}
interface ResponseType {
    success: boolean;
    data?: PairingListItem[]; // Data is an array of PairingListItem
    message: string;
}
export default function PairingsList() {
    const location = useLocation();
    const navigate = useNavigate();
    const { TOURNAMENT } = useParams();
    const [tempNAME, ID] = TOURNAMENT?.split(URL_BINDER) || [];
    const query = new URLSearchParams(location.search);
    const endPoint = `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${ID}/${ApiEndpoints.LIST_TOURNAMENT_ROUNDS}`
    const [pairingList, setPairingList] = useState<PairingListItem[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response: AxiosResponse<ResponseType> = await gcbAPI().get(endPoint, { params: { 'access_token': getToken() } });
            const { data } = response;
            setIsLoading(false)
            if (data?.success) {
                setPairingList([...data.data || []]);
            }
        } catch (error: any) {
            setIsLoading(false)
            Error({ description: error?.data?.message })
        }
    }
    useEffect(() => {
        if (!ID) {
            navigate('/admin/tournaments');
        } else {
            if (!isLoading)
                fetchData()
        }
    }, [])
    return (
        <MainLayout>
            <>
                <PageHeader name="Pairings" isLoading={isLoading} style={{ marginBottom: '1.5rem' }} />
                <Row>
                    {(pairingList || []).map(({ round_number, id }) =>
                        <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24} className="p-2">
                            <TournamentCard
                                title={`Round ${round_number}`}
                                subTitle={`Setup Pairings For Round ${round_number}`}
                                assign={false}
                                editLink={`/admin/tournaments/${TOURNAMENT}/pairings/Round ${round_number + URL_BINDER + id}`}
                            />
                        </Col>
                    )}
                </Row>
            </>
        </MainLayout>
    );
}