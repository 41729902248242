import { createSlice } from "@reduxjs/toolkit";

export interface UserDetailsType {
    Userdata ?: any;
    Permissiondata ?: any;
    company_log ?:any;
    
  }
  const initialState: UserDetailsType = { };


export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        getUserData(state, action) {
            return action.payload;
        },
        setUserData: (state, action) => {
            state.Userdata = action.payload;
        },
        getPermissionDataa(state, action) {
            return action.payload;

        },
        setPermissionData(state, action) {
            state.Permissiondata = action.payload;

        },
        updatePermissionData(state, action) {

        },
        veifyEmail(state, action) {
            return action.payload;
        },
        forgetPasswordSlice(state, action) {
            alert(1);
        },
        logoutUser(state, action) {
        },
        accountSetup(state,action){   
        },
        setCompanyImage(state,action){
            state.company_log = action.payload;
        },
        createContest(state,action){   
        },
        
    },

})

export const {getUserData,setUserData,getPermissionDataa,setPermissionData,updatePermissionData,veifyEmail,accountSetup,setCompanyImage,logoutUser,createContest,forgetPasswordSlice} = userInfoSlice.actions;
export default userInfoSlice.reducer;