import { AnyAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  put,
  call,
  spawn,
  PutEffect,
  CallEffect,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import { getErrorMessage, gcbAPI } from "@utils/APIInterceptor";
import { SagaActionType, SagaActions, ApiEndpoints } from "./index";
import { history } from "@config/routes";
import { setUserData } from "../auth/localData";
import { Error, Success, Waraning } from "../constant/toast/Toast";


function* loginUser({
  payload: { email, password },
}: any): Generator<
  CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {
    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.AUTHENTICATE_USER}`,
      {
        email,
        password,
      }
    );
    const { data } = response || {};
    // const { data } = apiData
    // const { access_token } = data
    yield put({
      type: `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.SUCCESS}`,
      payload: data,
    });

    // yield call(localStorage.setItem, 'accessToken', 'test')
    setUserData(data?.data)
    localStorage.setItem('accessToken', data?.data?.access_token)
    yield call(Success, { description: 'Signed In successfully.' });
    yield call(history.replace, `/admin/dashboard`);
    localStorage.setItem('notification_count', data?.data?.notification_count)
  } catch (error: any) {
    getErrorMessage(error, SagaActions.AUTHENTICATE_USER);
    yield put({
      type: `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
    yield call(Error, { description: error.data.message });
    // history.replace('/admin/SignUp');
  }
}

function* forgotPassword({
  payload: { email },
}: any): Generator<
  CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {
    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.FORGET_PASSWORD}`,
      {
        email,
      }
    );
    const { data } = response || {};
    yield put({
      type: `${SagaActions.FORGET_PASSWORD}_${SagaActionType.SUCCESS}`,
      payload: data,
    });
    yield call(history.replace, `/success?email=${email}`);
  } catch (error: any) {
    getErrorMessage(error, SagaActions.AUTHENTICATE_USER);
    yield put({
      type: `${SagaActions.FORGET_PASSWORD}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
    history.replace('/admin/SignUp');
  }
}

function* resetPassword({
  payload: { password },
}: any): Generator<
  CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {
    const params = new URLSearchParams(window.location.search);
    const myParam = params.get('reset_token');

    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.RESET_PASSWORD}`,
      {
        password,
        password_confirmation: password,
        reset_token: myParam,
      }
    );
    const { data } = response || {};
    yield put({
      type: `${SagaActions.RESET_PASSWORD}_${SagaActionType.SUCCESS}`,
      payload: data,
    });
    yield call(history.replace, `/admin/login`);
  } catch (error: any) {
    getErrorMessage(error, SagaActions.RESET_PASSWORD);
    yield put({
      type: `${SagaActions.FORGET_PASSWORD}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
    history.replace('/admin/SignUp');
  }
}

function* verifyEmail({
  payload: { email },
}: any): Generator<
  CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {

    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.REGISTER_USER}`,
      {
        email
      }
    );
    const { data } = response || {};
    yield put({
      type: `${SagaActions.VERIFY_EMAIL}_${SagaActionType.SUCCESS}`,
      payload: data,
    });
    yield call(history.replace, `/admin/account-setup`);
  } catch (error: any) {
    getErrorMessage(error, SagaActions.VERIFY_EMAIL);
    yield put({
      type: `${SagaActions.VERIFY_EMAIL}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
    history.replace('/admin/login');
  }
}
function* setPassword({payload}: any): Generator<CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {
    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.ACCEPT_INVITATION}`,
      {...payload}
    );
    const { data } = response || {};
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.ACCEPT_INVITATION}_${SagaActionType.SUCCESS}`,
        payload: data,
      });
      yield call(history.replace, `/admin/login`);
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.ACCEPT_INVITATION}_${SagaActionType.SUCCESS}`,
      payload: {},
    });
    // yield call(history.replace, `/admin/login`);
  }
}
function* logoutUser({
  payload: { accessToken },
}: any): Generator<
  CallEffect<any> | PutEffect<AnyAction>,
  void,
  AxiosResponse<any>
> {
  try {
    const response = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.SIGNOUT_USER}`,
      {
        access_token: accessToken
      }
    );
    const { data } = response || {};
    yield put({
      type: `${SagaActions.SIGNOUT_USER}_${SagaActionType.SUCCESS}`,
      payload: data,
    });
    localStorage.clear()
    yield call(history.replace, `/admin/login`);
  } catch (error: any) {
    getErrorMessage(error, SagaActions.SIGNOUT_USER);
    if (error.status === 401) {
      yield call(history.replace, `/admin/login`);
    }
    yield put({
      type: `${SagaActions.SIGNOUT_USER}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
  }
}

function* createUser({ payload }: any): Generator<any> {
  try {

    yield put({
      type: `${SagaActions.CREATE_USER}_${SagaActionType.SUCCESS}`,
      payload: payload,
    });
    yield call(Success, { description: 'Data Saved Successfully.' });
  }
  catch (error: any) {
    yield call(Error, { description: 'Failed to save data .' });

  }

}

function* storeBasicData({ payload }: any): Generator<any> {
  try {
    yield put({
      type: `${SagaActions.STORE_BASIC}_${SagaActionType.SUCCESS}`,
      payload: payload,
    });
    yield call(Success, { description: 'Data Saved Successfully.' });

  }
  catch (error: any) {
    yield call(Error, { description: 'Failed to save data .' });

  }
}

function* createNewUser({ payload }: any): Generator<any> {
  try {
    console.log(payload, "payload");
    const newUserData = (state: any) => state.auth
    const newUser: any = yield select(newUserData);
    const response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${ApiEndpoints.CREATE_USER}`,
      {
        email: newUser.basicDetails.values.email,
        password: payload.values.password,
        primary_phone1: newUser.basicDetails.values.phone1,
        role: newUser.basicDetails.values.role,
        company_name: newUser.basicDetails.values.company,
        company_url: newUser.compnayDetails.values.companyUrl,
        first_name: newUser.basicDetails.values.firstName,
        last_name: newUser.basicDetails.values.lastName,
        usage: JSON.stringify(["Events", "Chapters"]),
        company_logo: newUser.compnayDetails.image,
        address: newUser.compnayDetails.values.address,
        state: newUser.compnayDetails.values.state,
        city: newUser.compnayDetails.values.city,
        zipcode: newUser.compnayDetails.values.zip,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    if (response?.status === 201) {
      yield call(Success, { description: 'User Added Successfully.' });
      yield put({
        type: `${SagaActions.CREATE_NEW_USER}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      yield call(history.replace, `/admin/login`);
    }

  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_NEW_USER}_${SagaActionType.FAIL}`,
      payload: error?.data || error,
    });
    yield call(Error, { description: error?.data?.message });

  }

}

function* authenticateUserRequest() {
  yield takeEvery(
    `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.REQUEST}`,
    loginUser
  );
}

function* signoutUserRequest() {
  yield takeEvery(
    `${SagaActions.SIGNOUT_USER}_${SagaActionType.REQUEST}`,
    logoutUser
  );
}

function* CreateUserRequest() {
  yield takeEvery(
    `${SagaActions.CREATE_USER}_${SagaActionType.REQUEST}`,
    createUser
  );
}

function* StoreBasicRequest() {
  yield takeEvery(
    `${SagaActions.STORE_BASIC}_${SagaActionType.REQUEST}`,
    storeBasicData
  );
}

function* createNewUserRequest() {
  yield takeEvery(
    `${SagaActions.CREATE_NEW_USER}_${SagaActionType.REQUEST}`,
    createNewUser
  );
}

function* forgetPasswordRequest() {
  yield takeEvery(
    `${SagaActions.FORGET_PASSWORD}_${SagaActionType.REQUEST}`,
    forgotPassword
  );
}

function* resetPasswordRequest() {
  yield takeEvery(
    `${SagaActions.RESET_PASSWORD}_${SagaActionType.REQUEST}`,
    resetPassword
  );
}

function* verifyEmailRequest() {
  yield takeEvery(
    `${SagaActions.VERIFY_EMAIL}_${SagaActionType.REQUEST}`,
    verifyEmail
  );
}

function* setPasswordRequest() {
  yield takeEvery(
    `${SagaActions.ACCEPT_INVITATION}_${SagaActionType.REQUEST}`,
    setPassword
  );
}

export default function* rootAuthSaga() {
  yield spawn(authenticateUserRequest);
  yield spawn(signoutUserRequest);
  yield spawn(forgetPasswordRequest);
  yield spawn(resetPasswordRequest);
  yield spawn(verifyEmailRequest);
  yield spawn(CreateUserRequest);
  yield spawn(StoreBasicRequest);
  yield spawn(createNewUserRequest);
  yield spawn(setPasswordRequest);
}
