import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface IgolfInterface {
    countries: any[]; // player data storage
    states:any[]
}
const initialState: IgolfInterface = {
    countries: [],
    states:[]
};
export const IgolfSlice = createSlice({
    name: "igolf",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addMatcher(
        (action) =>
            action.type ===
            `${SagaActions.IGOLF_COUNTRIES}_${SagaActionType.REQUEST}`,
        (state, action) => {
            return {
                ...state,
            };;
        }
       );
      builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.IGOLF_COUNTRIES}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    countries: action.payload.data,
                };
            }
        );

      builder.addMatcher(
          (action) =>
              action.type ===
              `${SagaActions.IGOLF_STATES}_${SagaActionType.REQUEST}`,
          (state, action) => {
              return {
                  ...state,
              };;
          }
         );
      builder.addMatcher(
              (action) =>
                  action.type ===
                  `${SagaActions.IGOLF_STATES}_${SagaActionType.SUCCESS}`,
              (state, action) => {
                  return {
                      ...state,
                      states: action.payload.data,
                  };
              }
          );
  },
})

export default IgolfSlice.reducer;  