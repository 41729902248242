import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';

function* fetchAllPlayersData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/players`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* managePlayersColumnsAPI({ payload }: any): Generator<any> {
  try {
    const { ID, tournament_round_id, ...props } = payload
    const token = { access_token: getToken(), ...props }
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${ID}/pairing_filter_columns`,
      { ...token },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.SUCCESS}`,
      });
      // Call the "All Players Fetch" API after successful "Manage Players Columns" API
      yield put({
        type: `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
        payload: { id: ID, tournament_round_id },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchPlayerInfo({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYER_INFO
      }`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PLAYER_INFO_FETCH}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* AllPlayersRequest() {
  yield takeLatest(
    `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
    fetchAllPlayersData
  );
}

function* ManagePlayersColumnsRequest() {
  yield takeLatest(
    `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.REQUEST}`,
    managePlayersColumnsAPI
  );
}

function* PlayerInfoRequest() {
  yield takeLatest(
    `${SagaActions.PLAYER_INFO_FETCH}_${SagaActionType.REQUEST}`,
    fetchPlayerInfo
  );
}

export default function* rootAllPlayers() {
  yield spawn(AllPlayersRequest);
  yield spawn(ManagePlayersColumnsRequest);
  yield spawn(PlayerInfoRequest);
}
