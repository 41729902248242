import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface StatesListInterface {
    isLoading: boolean;
    statesList: any[];
    divisionList: {id:number;name:string;start_range:string;end_range:string}[];
}
const initialState: StatesListInterface = {
    statesList: [],
    divisionList: [],
    isLoading: true,
};
export const StatesListSlice = createSlice({
    name: "commonList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.STATESLIST}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    statesList: action.payload.data,

                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.STATESLIST}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.LIST_STANDARD_DIVISION}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    divisionList: action.payload.data,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.LIST_STANDARD_DIVISION}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
    },
})

export default StatesListSlice.reducer;  