import { Col, Grid, Row, Spin, Typography } from 'antd';
import tableStyle from '../../assets/style/table/table.module.css'
import { FC } from 'react';
import { ReactComponent as Vector } from "@assets/icons/Vector.svg";
const { Title } = Typography;

//temp Code Will create new File
interface PageHeaderProps {
    name: string;
    hideVector?: boolean;
    textStyle?: any;
    className?: string;
    style?: any;
    isLoading?:boolean,
}

const PageHeader: FC<PageHeaderProps> = ({ name, textStyle, className = '',isLoading= false, hideVector = false, style }) => {
    return (
        <>
            <Row style={style}>
                <Col xl={20} sm={24}>
                    <Row align="middle">
                        <Title level={2} className={`${className} text-cap ${tableStyle.tableHeader}`}>
                            {name}
                        </Title>
                        {!hideVector && <Vector style={{ marginLeft: 15, marginTop: -8 }} />}
                    </Row>
                </Col>
            </Row>
            {isLoading &&
            <Col span={24} className="flex-center" style={{ height: 'calc(100vh - 240px)' }}>
                <Spin />
            </Col>}
        </>
    );
};

export default PageHeader;