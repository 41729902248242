import { getChapterID } from '@auth/localData';
import { isC_Admin, isC_Owner } from '@auth/permission';
import FormikTextField from '@components/inputFields/FormikTextField';
import ImageUpload from '@components/inputFields/ImageUpload';
import NPSelectM from '@components/inputFields/NPSelectM';
import { isArray } from '@constant/formik/validator';
import { URL_BINDER } from '@constant/url';
import { SagaActionType, SagaActions } from '@sagas/index';
import { Button, Col, Form, Radio, Row, Spin } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
const { Item } = Form;

export default function CreateEntry() {

    const { TOURNAMENT } = useParams();
    const [tempNAME, ID] = TOURNAMENT?.split(URL_BINDER) || [];
    const isTournament = ID ? true : false;
    const { chapter_list, chapter_tournament_list } = useSelector((state: any) => state.chapter);
    const { isEntryAdded } = useSelector((state: any) => state.accounting)
    const dispatch = useDispatch();
    const canSelectChapter = (isC_Owner() || isC_Admin()) && !isTournament;
    const formikMain = useFormik({
        initialValues: {
            transaction_record_type: 'income',
            amount: undefined,
            chapter_id: canSelectChapter ? undefined : getChapterID(),
            tournament_id: isTournament ? ID : undefined,
            transaction_reason: '',
            expense_invoice: undefined,
            isBudgetAPIReload:isTournament ? true : false,
        },
        onSubmit: async () => {
            if (!isEntryAdded)
                dispatch({
                    type: `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.REQUEST}`,
                    payload: { ...values }
                })
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            amount: yup.number().required('Please enter Amount').typeError('Please enter Number').min(0),
            chapter_id: !isTournament ? yup.string().required('Please select Chapter') : yup.string(),
            tournament_id: yup.string().required('Please select Event'),
            transaction_reason: yup.string().required('Please enter Details'),
            expense_invoice: yup.string().when('transaction_record_type', {
                is: 'expense',
                then: yup.string().required('Please upload expense Invoice'),
            }),
        }),
    });

    const { getFieldProps, handleSubmit, values, setFieldValue, errors, touched, resetForm } = formikMain;

    useEffect(() => {
        if (values.chapter_id) {
            setFieldValue('tournament_id', '');
            dispatch({ type: `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.REQUEST}`, payload: { id: values.chapter_id } })
        }
    }, [values.chapter_id])


    const clearForm = (e: any) => {
        e.preventDefault();
        resetForm();
    }
    useEffect(() => {
        if (!isEntryAdded) {
            resetForm();
        }
    }, [isEntryAdded])
    return (
        <Col span={24} xs={24} md={18} lg={12} xl={8} xxl={6}>
            <form className='com-form-container eventcreate input50' onSubmit={handleSubmit}>
                <Col span={24} className='mb-10'>
                    <Row className='mb-2 black-radio'>
                        <label className="mb-2">
                            <p className="text-field-label radio">
                                Type
                            </p>
                        </label>
                        <Radio.Group
                            onChange={(e) => { resetForm(); setFieldValue('transaction_record_type', e.target.value) }}
                            value={values?.transaction_record_type}
                            className="mb-4 w-100"
                        >
                            <Row>
                                <Col sm={12}><Radio value={'income'}>Income</Radio></Col>
                                <Col sm={12}><Radio value={'expense'}>Expense</Radio></Col>
                            </Row>
                        </Radio.Group>
                    </Row>
                    <FormikTextField
                        label='Enter Amount'
                        placeholder="Enter Amount"
                        className={'d-border b-r-5 input-name-padding'}
                        fieldValue="amount"
                        currency
                        error={
                            Boolean(errors['amount'] && touched['amount']) &&
                            errors['amount']
                        }
                        {...getFieldProps("amount")}
                    />
                    {canSelectChapter &&
                        <NPSelectM
                            label="Select Chapter"
                            placeholder="Select Chapter"
                            fname="chapter_id"
                            options={chapter_list}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            getFieldProps={getFieldProps}
                        />
                    }
                    {!isTournament &&
                        <NPSelectM
                            label='Select Event'
                            placeholder='Select Event'
                            fname='tournament_id'
                            options={chapter_tournament_list}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            getFieldProps={getFieldProps}
                        />}
                    <FormikTextField
                        label='Enter transaction_reason'
                        placeholder="Cash Collection from Event"
                        className={'d-border b-r-5'}
                        fieldValue="transaction_reason"
                        error={
                            Boolean(errors['transaction_reason'] && touched['transaction_reason']) &&
                            errors['transaction_reason']
                        }
                        {...getFieldProps("transaction_reason")}
                    />
                    {
                        values.transaction_record_type === 'expense' &&
                        <Col xxl={16} xl={20} xs={24} className=''>
                            <Item
                                {...(Boolean(errors['expense_invoice'] && touched['expense_invoice']) && { validateStatus: 'error', help: errors['expense_invoice'] })}
                                className='upload-image-wrapper-hide-text'
                            >
                                <label {...getFieldProps('expense_invoice')}><p className="text-field-label">upload Expense Invoice</p></label>
                                <ImageUpload
                                    logoBase={values.expense_invoice}
                                    setLogoBase={(e: any) => { setFieldValue('expense_invoice', e) }}
                                    hideAllText={true}
                                />
                            </Item>
                        </Col>
                    }
                </Col>
                <Col span={24} className='mt-10'>
                    <Button className='raw-button btnSpin' htmlType="submit" style={{ background: '#047EB1', color: '#fff' }} >
                        {isEntryAdded ? <Spin size='small' /> : 'Submit'}
                    </Button>
                    <Link to='/' onClick={clearForm} className="com-cancel" style={{ marginLeft: '2rem' }}>
                        Cancel
                    </Link>
                </Col>
            </form>
        </Col>
    )
}
