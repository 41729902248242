import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";


const initialState = {
  leaderboardData: [],
  divisions: [],
  isLeaderboardLoading: true,
  playerScores: [],
  playerScoresLoading: true,
  firstDivision: '',
  tournamentRoundId: ''
};

export const LeaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLeaderboardLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_LEADERBOARD}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          leaderboardData: action.payload.data.data,
          divisions: action.payload.data.division_data.sort((a: any, b: any) => a.name.localeCompare(b.name)),
          firstDivision: action.payload.data.division_data.sort((a: any, b: any) => a.name.localeCompare(b.name))[0]?.name,
          isLeaderboardLoading: false,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_PLAYER_SCORES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          playerScoresLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_PLAYER_SCORES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          playerScores: action.payload.data,
          playerScoresLoading: false,
          tournamentRoundId: action.payload.data?.tournament_round_id
        };
      }
    );
  },
})

export default LeaderboardSlice.reducer;  