import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface scoringInterface {
    roundData: any[]; // player data storage
    isLoading: boolean; // loader
    isUpdating: boolean; // loader
    isActiveLoading: boolean;
}
const initialState: scoringInterface = {
    roundData: [],
    isLoading: false,
    isActiveLoading: false,
    isUpdating: false,
};
export const scoringsRoundSlice = createSlice({
    name: "scoringRound",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SCORINGS_ROUND_LIST}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    roundData: action.payload.data,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SCORINGS_ROUND_LIST}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isUpdating: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isUpdating: false,
                    roundData: action.payload.data,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.SCORINGS_ROUND_UPDATE}_${SagaActionType.FAIL}`,
            (state, action) => {
                return {
                    ...state,
                    isUpdating: false,
                };;
            }
        );
    },
})

export default scoringsRoundSlice.reducer;  