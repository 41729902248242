import {
  takeLatest,
  call,
  put,
  spawn,
  takeEvery,
  select,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getCompanyID, getToken } from "../auth/localData";
import { notification } from "antd";
import { Error, Success, Waraning } from "../constant/toast/Toast";
import { history } from "@config/routes";


// function* createEventAPI({ payload }: any): Generator<any> {
//   try {
//     payload.access_token = getToken()
//     payload.company_id = getCompanyID()
//     const response: any = yield call(gcbAPI().post,
//       `${ApiEndpoints.ADMIN}/${ApiEndpoints.EVENT}`,
//       { ...payload },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     if (response?.status === 201) {      
//       yield call(Success,{description:response?.data?.message});
//       yield put({
//         type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
//         payload: {data: response?.data?.data},
//       });
//     }
//     history.replace('/admin/tournament/create-a-tournament/budget');
//   }
//   catch (error:any) {
//     yield put({
//       type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
//       payload: {data:[]}
//     });
//     yield call(Error,{description:error?.data?.message});

//   }

// }

// function* createBudgetAPI({ payload }: any): Generator<any> {
//   alert('here')
//   try {
//     payload.access_token = getToken()
//     payload.company_id = getCompanyID()
//     const response: any = yield call(gcbAPI().post,
//       `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_BUDGETS}`,
//       { ...payload },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     if (response?.status === 201) {      
//       yield call(Success,{description:response?.data?.message});
//       yield put({
//         type: `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.SUCCESS}`,
//         payload: {data: response?.data?.data},
//       });
//     }
//     history.replace('/admin/tournament/create-a-tournament/budget');
//   }
//   catch (error:any) {
//     yield put({
//       type: `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.SUCCESS}`,
//       payload: {data:undefined}
//     });
//     yield call(Error,{description:error?.data?.message});

//   }

// }

// function* createTournamentRequest() {
//   yield takeLatest(
//     `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.REQUEST}`,
//     createEventAPI
//   );
// }
// function* createBudgetRequest() {
//   yield takeLatest(
//     `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.REQUEST}`,
//     createBudgetAPI
//   );
// }
function* getEventAPI({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.EVENT}/${payload?.id}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* getTournamentRequest() {
  yield takeLatest(
    `${SagaActions.GET_TOURNAMENT_BY_ID}_${SagaActionType.REQUEST}`,
    getEventAPI
  );
}

export default function* rootcreateTournament() {
  // yield spawn(createTournamentRequest);
  // yield spawn(createBudgetRequest);
  yield spawn(getTournamentRequest);
}