import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { select } from "redux-saga/effects";

interface RoundInfoType {
    name: string
    starting_format: string

}

interface PairingHeaderProps {
    roundInfo: RoundInfoType[];
    selectedRound: any;
    setRoundInfo: (index: number) => void;
    selectedIndex: number;
    assigned: number;
}

const PairingHeader: React.FC<PairingHeaderProps> = ({ assigned, roundInfo, setRoundInfo, selectedRound, selectedIndex }) => {
    const TIME =  selectedRound?.double_tee_time ? selectedRound?.double_tee_time :selectedRound?.shotgun_time ? selectedRound?.shotgun_time : selectedRound?.tee_time
    return (
        <Row className='mb-10'>
            <Col span={24}>
                <Row className="pairing-course-round-name overflowY-hidden">
                    {(roundInfo || []).map(({ name }, index) => <span key={name} onClick={() => { setRoundInfo(index) }} className={index === selectedIndex ? 'selected text-skyBlue pointer' : 'non-selected text-skyBlue pointer'}>{name}</span>)}
                </Row>
            </Col>
            <Col span={24} className="pairing-course-round-line" />
            <Col span={24}>
                <Row gutter={{ xs: 24, sm: 16 }} className="pairing-course-round-details-wrapper">
                    <Col className="gutter-row">
                        <Card className="pairing-course-round-details-1 w-100 bg-skyBlue b-r-5 text-w mt-8">
                            <p className='mb-8'>{selectedRound?.starting_format}</p>
                            <Row>
                                <b className="font-42 line-height1">{moment(TIME).format('hh:mm')} </b>
                                <div className="mt-auto ml-2 h-100">
                                    <div><b>Hole:</b>1</div>
                                    <div><b className=''>{moment(TIME).format('A') + ' ' + 'UTC'}</b>(I: 10 mins)</div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xl={5} lg={6}>
                        <Card className="shadow-card pairing-course-round-details w-100 b-r-5  mt-8 d-box-shadow">
                            <p className='mb-8'>Total (R1-C1) &nbsp;<InfoCircleOutlined className='opacity-5' style={{ fontSize: 10, marginBottom: 'auto' }} /></p>
                            <Row>
                                <b className="font-42 mb--1 text-skyBlue line-height1">{selectedRound?.course_player_count} </b>
                                <b className="mt-auto h-100"> /{selectedRound?.total_count}</b>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xl={5} lg={6}>
                        <Card className="shadow-card pairing-course-round-details w-100 b-r-5  mt-8  d-box-shadow">
                            <p className='mb-8'>Assigned &nbsp;<InfoCircleOutlined className='opacity-5' style={{ fontSize: 10, marginBottom: 'auto' }} /></p>
                            <Row>
                                <b className="font-42 mb--1 text-skyBlue line-height1">{assigned} </b>
                                <b className="mt-auto h-100"> /{selectedRound?.course_player_count}</b>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xl={5} lg={6}>
                        <Card className="shadow-card pairing-course-round-details w-100 b-r-5  mt-8  d-box-shadow">
                            <p className='mb-8'>Unassigned &nbsp;<InfoCircleOutlined className='opacity-5' style={{ fontSize: 10, marginBottom: 'auto' }} /></p>
                            <Row>
                                <b className="font-42 mb--1 text-skyBlue line-height1">{!isNaN(selectedRound?.course_player_count) && (selectedRound?.course_player_count - assigned)} </b>
                                <b className="mt-auto h-100"> /{selectedRound?.course_player_count}</b>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row mt-8" xxl={3} xl={2} lg={6}>
                        <b>Slots Available: {!isNaN(selectedRound?.solts_available) && (selectedRound?.solts_available - assigned)}/ {selectedRound?.total_count}</b>
                        <p>Unpublished</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default PairingHeader;