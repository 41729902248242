import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface EventControlsInterface {
  EventControls: any[],
  isEventControlsLoading: boolean,
  ScoringControl: any[],
  isScoringControlLoading: boolean,
  isDistributeRewardsLoading:boolean
}
const initialState: EventControlsInterface = {
  EventControls: [],
  isEventControlsLoading: true,
  ScoringControl: [],
  isScoringControlLoading: true,
  isDistributeRewardsLoading:true
};

export const EventControlsSlice = createSlice({
  name: "eventControls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isEventControlsLoading: true,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isEventControlsLoading: false,
          EventControls: action.payload.data,
        };
      }
    );
   
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_SCORING_CONTROL}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isScoringControlLoading: true,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_SCORING_CONTROL}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isScoringControlLoading: false,
          ScoringControl: action.payload.data,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REWARD_DISTRIBUTION}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isDistributeRewardsLoading: true,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REWARD_DISTRIBUTION}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isDistributeRewardsLoading: false,
        };
      }
    );
    
  },
})

export default EventControlsSlice.reducer;  