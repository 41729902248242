import { spawn } from "redux-saga/effects";
import AuthSaga from "./authSaga";
import Players from "./playerSaga";
import Membership from "./membershipSaga";
import Chapter from "./chapterSaga";
import Season from "./seasonSaga";
import UserManagement from "./userManagementSaga";
import statesList from "./StatesListSaga"
import Sponsor from "./SponsorSaga";
import AllPlayers from "./allPlayersSaga";
import flightsDivisions from "./flightsDivisionsSaga"
import PairingManagement from "./pairingPlayerSaga";
import PermissionMangment from "./permissionSaga";
import IgolfData from "./IgolfSaga"
import Courses from "./coursesSaga";
import Printings from "./PrintingsSaga";
import CreateContestLibrary from "./CreateContestLibrary";
import SideGames from "./sideGamesSaga";
import PrizesAwards from "./prizesAwardsSaga";
import Notification from "./notification";
import RegisteredPlayers from "./registeredPlayersSaga";
import TournamentsList from "./TornamentsListSaga";
import CreateSchedules from "./schedulesSaga";
import createTournament from "./CreateTournamentSaga";
import ScoringsRound from "./scoringsRoundSaga";
import CheckIn from "./CheckInSaga";
import Leaderboards from "./LeaderboardsSaga";
import rootAccountings from "./allAccoutingSaga";
import EventControls from "./EventControlsSaga";
import MarketingsRoot from "./marketingsSaga";

export default function* rootSaga() {
  yield spawn(AuthSaga);
  yield spawn(Players);
  yield spawn(Membership);
  yield spawn(Chapter);
  yield spawn(Season);
  yield spawn(UserManagement);
  yield spawn(statesList);
  yield spawn(CreateContestLibrary);
  yield spawn(Sponsor);
  yield spawn(AllPlayers);
  yield spawn(IgolfData);
  yield spawn(Courses);
  yield spawn(flightsDivisions);
  yield spawn(PairingManagement);
  yield spawn(PermissionMangment);
  yield spawn(Printings);
  yield spawn(SideGames);
  yield spawn(PrizesAwards);
  yield spawn(Notification);
  yield spawn(RegisteredPlayers);
  yield spawn(TournamentsList);
  yield spawn(CreateSchedules);
  yield spawn(createTournament)
  yield spawn(ScoringsRound);
  yield spawn(CheckIn);
  yield spawn(Leaderboards);
  yield spawn(rootAccountings);
  yield spawn(EventControls);
  yield spawn(MarketingsRoot);
}


export enum SagaActions {
  CREATE_PAYOUT_MATRIX = 'CREATE_PAYOUT_MATRIX',
  INITIATE_CHARGING_SESSION = "INITIATE_CHARGING_SESSION",
  GET_CHARGING_STATUS = "GET_CHARGING_STATUS",
  GET_CHARGER_DETAILS = "GET_CHARGER_DETAILS",
  START_CHARGING_STATUS_POLLING = "START_CHARGING_STATUS_POLLING",
  STOP_CHARGING_STATUS_POLLING = "STOP_CHARGING_STATUS_POLLING",
  //WRITE SAGA ACTIONS HERE BEFORE DOING ANYTHING MAKE SURE YOU ARE REUSING IT
  AUTHENTICATE_USER = "AUTHENTICATE_USER",
  CREATE_USER = "CREATE_USER",
  STORE_BASIC = "STORE_BASIC",
  CREATE_NEW_USER = "CREATE_NEW_USER",
  FORGET_PASSWORD = "FORGET_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  SIGNOUT_USER = "SIGNOUT_USER",
  ADD_PLAYER_USERS = "ADD_PLAYER_USERS",
  PLAYER_TAGS = "PLAYER_TAGS",
  PLAYER_TAGS_CREATE = "PLAYER_TAGS_CREATE",
  PLAYER_TAGS_DELETE = "PLAYER_TAGS_DELETE",
  MEMBERSHIP = "MEMBERSHIP",
  CREATE_MEMBERSHIP = "CREATE_MEMBERSHIP",
  SESSIONS = "SESSIONS",
  CHAPTER_OWNER = "CHAPTER_OWNER",
  CHAPTER_LIST = "CHAPTER_List",
  COURSES_LIST = "COURSES_List",
  STAFF_LIST = "STAFF_List",
  DIRECTOR_LIST = "DIRECTOR_List",
  CHAPTER_CREATE = "CHAPTER_Create",
  SEASONS = "SEASONS",
  USERS_FETCH = "USERS_FETCH",
  USERS_CREATE = "USERS_CREATE",
  USERS_DELETE = "USERS_DELETE",
  USERS_UPDATE = "USERS_UPDATE",
  STATESLIST = "STATES_LIST",
  CREATE_TOURNAMENNT = "CREATE_TOURNAMENTS",
  List_CHAPTER_TOURNAMENNT = 'List_CHAPTER_TOURNAMENNT',
  PERMISSION_MATRIX = "PERMISSION",
  PERMISSION_MATRIX_update = "PERMISSION_UPDATE",
  PERMISSION_MATRIX_reset = "PERMISSION_RESET",
  CREATE_CONTEST_LIBRARY = 'CREATE_CONTEST_LIBRARY',
  GET_CONTEST_LIBRARY = 'GET_CONTEST_LIBRARY',
  DELETE_CONTEST_LIBRARY = 'DELETE_CONTEST_LIBRARY',
  GET_CONTEST_LIBRARY_BY_ID = 'GET_CONTEST_LIBRARY_BY_ID',
  CREATE_SPONSORS = 'CREATE_SPONSORS',
  DELETE_SEASONS = 'DELETE_SEASONS',
  CREATE_SEASONS = 'CREATE_SEASONS',
  ALL_PLAYERS_FETCH = "ALL_PLAYERS_FETCH",
  MANAGE_PLAYERS_COLUMNS = "MANAGE_PLAYERS_COLUMNS",
  PAIRING_PLAYERS_DRAGED = 'PAIRING_PLAYERS_DRAGED',
  PAIRING_PLAYERS_REMOVED = 'PAIRING_PLAYERS_REMOVED',
  DELETE_PAIRINGS_COLUMN = 'DELETE_PAIRINGS_COLUMN',
  PAIRING_PLAYERS_FETCH = 'PAIRING_PLAYERS_FETCH',
  PAIRING_COURSE_LIST = 'PAIRING_COURSE_LIST',
  CREATE_FLIGHT = "CREATE_FLIGHT",
  PLAYER_INFO_FETCH = "PLAYER_INFO_FETCH",
  FLIGHTS_DIVISIONS = "FLIGHTS_DIVISIONS",
  USER_INFO_FETCH = "USER_INFO_FETCH",
  DIVISIONS_SET_ACTIVE = "DIVISIONS_SET_ACTIVE",
  IGOLF_COUNTRIES = "IGOLF_COUNTRIES",
  IGOLF_STATES = "IGOLF_STATES",
  SEARCH_COURSES = "SEARCH_COURSES",
  FETCH_TEE_DETAILS = "FETCH_TEE_DETAILS",
  CREATE_COURSE = "CREATE_COURSE",
  FETCH_COURSES = "FETCH_COURSES",
  LIST_STANDARD_DIVISION = 'LIST_STANDARD_DIVISION',
  FETCH_COURSE_INFO = "FETCH_COURSE_INFO",
  PLAYER_ADD_TAG = "PLAYER_ADD_TAG",
  PRINT_CART_SIGN = "PRINT_CART_SIGN",
  TOURNAMENT_ROUND_COURSES = "TOURNAMENT_ROUND_COURSES",
  TOURNAMENT_BUDGETS = "TOURNAMENT_BUDGETS",
  PRINT_SCORECARD = "PRINT_SCORECARD",
  ADD_CUSTOM_HOLE = "ADD_CUSTOM_HOLE",
  UPDATE_PAIRING_HOLES = 'update_pairing_holes',
  FETCH_NATIONAL_SPONSORS = "FETCH_NATIONAL_SPONSORS",
  CREATE_CART_SIGN = "CREATE_CART_SIGN",
  UPDATE_CART_SIGN = "UPDATE_CART_SIGN",
  SHOW_CART_SIGN = "SHOW_CART_SIGN",
  LIST_TOURNAMENT_ROUNDS = "LIST_TOURNAMENT_ROUNDS",
  CREATE_SIDE_GAME = "CREATE_SIDE_GAME",
  LIST_SIDE_GAMES = "LIST_SIDE_GAMES",
  DELETE_SIDE_GAME = "DELETE_SIDE_GAME",
  PRIZES_AWARDS = 'PRIZES_AWARDS',
  PAYOUT_MATRIX_ID = 'PAYOUT_MATRIX_ID',
  CREATE_SCORECARD = "CREATE_SCORECARD",
  UPDATE_SCORECARD = "UPDATE_SCORECARD",
  SHOW_SCORECARD = "SHOW_SCORECARD",
  LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS",
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS",
  FETCH_PLAYERS_STATS = "FETCH_PLAYERS_STATS",
  FETCH_REGISTERED_PLAYERS = "FETCH_REGISTERED_PLAYERS",
  PLAYERS_CSV = "PLAYERS_CSV",
  PLAYERS_COMMENTS = "PLAYERS_COMMENTS",
  TOURNAMENTS_LIST = "TOURNAMENTS_LIST",
  VIEW_COMMENT = "VIEW_COMMENT",
  CREATE_COMMENT = "CREATE_COMMENT",
  EDIT_COMMENT = "EDIT_COMMENT",
  DELETE_COMMENT = "DELETE_COMMENT",
  WAITLIST_TO_REGISTERED = "WAITLIST_TO_REGISTERED",
  SEND_EMAIL_SMS = "SEND_EMAIL_SMS",
  UPDATE_PLAYER_STATUS = "UPDATE_PLAYER_STATUS",
  REMOVE_PLAYER = "REMOVE_PLAYER",
  SET_SCHEDULES = "SET_SCHEDULES",
  UPDATE_SCHEDULES = "UPDATE_SCHEDULES",
  GET_TOURNAMENT_BY_ID = "GET_TOURNAMENT_BY_ID",
  SCORINGS_ROUND_LIST = "SCORINGS_ROUND_LIST",
  SCORINGS_ROUND_UPDATE = "SCORINGS_ROUND_UPDATE",
  REGISTERED_PLAYERS_LIST = "REGISTERED_PLAYERS_LIST",
  CREATE_CHECKIN = "CREATE_CHECKIN",
  UPDATE_CHECKIN = "UPDATE_CHECKIN",
  FETCH_REG_PLAYERS_STATS = "FETCH_REG_PLAYERS_STATS",
  CHECKIN_CSV = "CHECKIN_CSV",
  REGISTERED_PLAYER_INFO = "REGISTERED_PLAYER_INFO",
  VIEW_CHECKIN = "VIEW_CHECKIN",
  VIEW_CHAPTER = "VIEW_CHAPTER",
  UPDATE_CHAPTER = "UPDATE_CHAPTER",
  FETCH_SPONSORS = "FETCH_SPONSORS",
  UPDATE_SPONSORS = "UPDATE_SPONSORS",
  DELETE_SPONSOR = "DELETE_SPONSOR",
  FETCH_LEADERBOARD = "FETCH_LEADERBOARD",
  FETCH_PLAYER_SCORES = "FETCH_PLAYER_SCORES",
  DELETE_CHAPTER = "DELETE_CHAPTER",
  ACCEPT_INVITATION = "ACCEPT_INVITATION",
  FETCH_CHAPTERS = "FETCH_CHAPTERS",
  DELETE_MEMBERSHIP = "DELETE_MEMBERSHIP",
  ACTIVE_SEASONS = "ACTIVE_SEASONS",
  FETCH_CHAPTER_LIST = "FETCH_CHAPTER_LIST",
  UPDATE_SCORES = "UPDATE_SCORES",
  VIEW_FLIGHTS_DIVISIONS = "VIEW_FLIGHTS_DIVISIONS",
  FINALIZE_SCORES = "FINALIZE_SCORES",
  ACCOUNTING_TRANSACTIONS = 'ACCOUNTING_TRANSACTIONS',
  TOURNAMENT_ACCOUNTING_TRANSACTIONS = 'TOURNAMENT_ACCOUNTING_TRANSACTIONS',
  VIEW_BUDGET = "VIEW_BUDGET",
  GET_TOURNAMENT_ACCOUNTINGS = "GET_TOURNAMENT_ACCOUNTINGS",
  GET_COMPANY_CHAPTER_ACCOUNTINGS = "GET_COMPANY_CHAPTER_ACCOUNTINGS",
  BALANCE_SHEET = "BALANCE_SHEET",
  TOURNAMENT_PROFIT_AND_LOSS= "TOURNAMENT_PROFIT_AND_LOSS",
  COMPANY_CHAPTER_PROFIT_AND_LOSS = "COMPANY_CHAPTER_PROFIT_AND_LOSS",
  DELETE_ENTRY = "DELETE_ENTRY",
  LIST_CHAPTER_REVENUES = 'LIST_CHAPTER_REVENUES',
  LIST_CHAPTER_EXPENSES = 'LIST_CHAPTER_EXPENSES',
  LIST_TOURNAMENT_REVENUES = 'LIST_TOURNAMENT_REVENUES',
  LIST_TOURNAMENT_EXPENSES = 'LIST_TOURNAMENT_EXPENSES',
  DETAILS_TOURNAMENT_EXPENSES_REVENUES = 'DETAILS_TOURNAMENT_EXPENSES_REVENUES',
  UPDATE_FLIGHTS_DIVISIONS = 'UPDATE_FLIGHTS_DIVISIONS',
  DELETE_FLIGHTS_DIVISIONS = 'DELETE_FLIGHTS_DIVISIONS',
  FETCH_EVENT_CONTROLS = 'FETCH_EVENT_CONTROLS',
  FETCH_SCORING_CONTROL = 'FETCH_SCORING_CONTROL',
  REWARD_DISTRIBUTION = 'REWARD_DISTRIBUTION',
  END_TOURNAMENT='END_TOURNAMENT',
  DELETE_PRIZES_AWARDS = 'DELETE_PRIZES_AWARDS',
  UPDATE_PRIZES_AWARDS = 'UPDATE_PRIZES_AWARDS',
  IMPORT_CONTACTS = 'IMPORT_CONTACTS',
  CREATE_MAIL_TEMPLATES = 'CREATE_MAIL_TEMPLATES',
}

export enum SagaActionType {
  REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export enum ApiEndpoints {
  SESSIONS = "sessions",
  CHARGERS = "chargers",
  ADMIN = "admin",
  AUTHENTICATE_USER = "sign_in",
  REGISTER_USER = "verify_user",
  CREATE_USER = "sign_up",
  SIGNOUT_USER = "sign_out",
  FORGET_PASSWORD = "forgot_password",
  RESET_PASSWORD = "reset_password",
  PLAYER_USERS = "player_users",
  PLAYER_TAGS = "player_tags",
  MEMBERSHIP = "memberships",
  CHAPTER_OWNER = "chapter_owners_list",
  SEASONS = "seasons",
  USERS = "users",
  COMPANIES = "companies",
  LIST_STANDARD_DIVISION = 'list_standard_division_ranges',
  CHAPTER_LIST = "list_chapters",
  COURSES_LIST = "courses",
  STAFF_LIST = "/users/list_staff_users",
  DIRECTOR_LIST = "/users/list_director_users",
  STATESLIST = "states_list",
  EVENT = "tournaments",
  PERMISSION_MATRIX = "permissions/list_permissions",
  PERMISSION_MATRIX_UPDATE = "permissions/update_permissions",
  PERMISSION_MATRIX_RESET = "permissions/set_default",
  CHAPTER_CREATE = 'chapters',
  CONTESTS = 'contests',
  SPONSORS = 'sponsors',
  TOURNAMENTS = "tournaments",
  PAIRING_PLAYERS = 'pairing_players',
  PAIRING = 'pairings',
  PAIRING_DETAILS = 'pairing_details',
  TOURNAMENT_ROUNDS = 'tournament_rounds',
  LIST_TOURNAMENTS = 'list_tournaments',
  LIST_TOURNAMENT_ROUNDS = "list_tournament_rounds",
  LIST_TOURNAMENT_ROUNDS_COURSES = 'list_tournament_round_courses',
  DELETE_PAIRINGS_COLUMN = 'delete_pairings',
  CREATE_FLIGHT = "standard_divisions",
  PLAYER_INFO = "player_info",
  COURSES = 'courses',
  FLIGHTS_DIVISIONS = "standard_divisions",
  DIVISIONS_SET_ACTIVE = "set_active",
  PLAYER_ADD_TAG = "single_player_tag",
  ADD_CUSTOM_HOLE = 'update_custom_holes',
  TOURNAMENT_BUDGETS = 'tournament_budgets',
  UPDATE_PAIRING_HOLES = 'update_pairing_holes',
  CART_SIGNS = 'cart_signs',
  SIDE_GAMES = "side_games",
  LIST_SIDE_GAMES = "list_side_games",
  PRIZES_AWARDS = 'payout_matrices',
  PAYOUT_MATRIX_ID = 'payout_matrix_id',
  PRINT_SCORECARDS = 'print_scorecards',
  NOTIFICATIONS = "notifications",
  LIST_NOTIFICATIONS = "list_notifications",
  UPDATE_NOTIFICATIONS = 'update_tournament_status',
  REGISTERED_PLAYERS = 'registered_players',
  COMMENTS = 'comments',
  PLAYERS = 'players',
  SCHEDULES = 'schedules',
  SHOW_SCORING_CONTROL = 'show_scoring_control',
  SCORINGS_ROUND_UPDATE = 'update_scoring_control',
  CHECKIN_PLAYERS = 'checkin_players',
  CHECKINS = 'checkins',
  CHAPTERS = 'chapters',
  ACCEPT_INVITATION = 'accept_invitation',
  ACTIVE_SEASONS = 'active_seasons',
  ACCOUNTING_TRANSACTIONS = 'accounting_transactions',
  TOURNAMENT_ACCOUNTINGS = 'tournament_accountings',
  CHAPTER_ACCOUNTINGS = 'chapter_accountings',
  COMPANY_ACCOUNTINGS = 'company_accountings',
  PROFIT_AND_LOSS = 'profit_and_loss',
  LIST_CHAPTER_REVENUES = 'list_chapter_revenues',
  LIST_CHAPTER_EXPENSES = 'list_chapter_expenses',
  LIST_TOURNAMENT_REVENUES = 'list_tournament_revenues',
  LIST_TOURNAMENT_EXPENSES = 'list_tournament_expenses',
  EVENT_CONTROLS = 'event_controls',
  MARKETINGS = 'marketings',
  IMPORT_CONTACTS = 'import_contacts',
  MAIL_TEMPLATES = 'mail_templates',
}
