import { forwardRef } from 'react';
import { Form, Select } from 'antd';
import { ReactComponent as DropDownOutlined } from '@assets/icons/dropdown-icon.svg';


const { Option } = Select;

const { Item } = Form;

const FormikSelect = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      options,
      style,
      setFieldValue,
      fieldValue,
      disabled,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: 'error', help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <Select
        className={value ? "form-select-field-custom" : 'form-select-field-custom opacity-5'}
        // suffixIcon={<DropDownOutlined />}
        placeholder={placeholder}
        value={value || placeholder}
        ref={ref}
        // value={value}
        onChange={(value: any) => {
          setFieldValue(fieldValue, value);
        }}
        disabled={disabled}
        {...props}
      >
        {options?.map((option: any) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Item>
  )
);

export default FormikSelect;