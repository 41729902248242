import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import {  ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { Error, Waraning,Success } from '../constant/toast/Toast';
import {  getCompanyID, getToken } from "../auth/localData";



function* searchCourse({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.COURSES
      }/search_courses`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.SEARCH_COURSES}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* fetchTeeDetails({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.COURSES
      }/tee_details`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_TEE_DETAILS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    if(error?.status === 422){
      yield call(Waraning, {message:'Not Found',description:`Tee Details Not Found.`,duration:2})
    }else{
      yield call(Error, { description: error?.data?.message })
    }
  }
}

function* createCourse({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    payload.company_id = getCompanyID();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COURSES}`,
      { ...payload }
    );

    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });

      yield put({
        type: `${SagaActions.CREATE_COURSE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });

      //To reload the courses after creating the course
      yield put({
        type: `${SagaActions.FETCH_COURSES}_${SagaActionType.REQUEST}`,
        payload:{}
      });
    }
  } catch (error: any) {
    console.log("API call error:", error);
    yield call(Error, { description: error?.data.message });
  }
}

function* fetchCourses({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.COURSES
      }`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_COURSES}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    if(error?.status === 401){
      yield call(Error, {message:`${error?.statusText}!`,description:`Please Login Again.`,duration:10})
    }else{
      yield call(Error, { description: error?.data?.message })
    }
  }
}

function* fetchCourseInfo({ payload ,id}: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.COURSES
      }/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_COURSE_INFO}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  }  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}


function* searchCourseRequest() {
  yield takeLatest(
      `${SagaActions.SEARCH_COURSES}_${SagaActionType.REQUEST}`,
      searchCourse
  );
}

function* fetchTeeDetailsRequest() {
  yield takeLatest(
      `${SagaActions.FETCH_TEE_DETAILS}_${SagaActionType.REQUEST}`,
      fetchTeeDetails
  );
}

function* createCourseRequest() {
  yield takeLatest(
      `${SagaActions.CREATE_COURSE}_${SagaActionType.REQUEST}`,
      createCourse
  );
}

function* fetchCoursesRequest() {
  yield takeLatest(
      `${SagaActions.FETCH_COURSES}_${SagaActionType.REQUEST}`,
      fetchCourses
  );
}

function* fetchCourseInfoRequest() {
  yield takeLatest(
      `${SagaActions.FETCH_COURSE_INFO}_${SagaActionType.REQUEST}`,
      fetchCourseInfo
  );
}


export default function* rootCourses() {
  yield spawn(searchCourseRequest);
  yield spawn(fetchTeeDetailsRequest);
  yield spawn(createCourseRequest);
  yield spawn(fetchCoursesRequest);
  yield spawn(fetchCourseInfoRequest);
}