import { Card, Col, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as AddNewContest } from "@assets/icons/addNewIcon.svg";
import { ReactComponent as SortClock } from "@assets/icons/sort-clock-ascending-outline.svg";
import { ReactComponent as SortClockUp } from "@assets/icons/timeupSort.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/twotone-search.svg";
import "./styles.css";
import PageHeader from "@components/page/PageHeader";
import { ReactComponent as EditIcon } from "@assets/icons/edit-iconV3.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/delete-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { SagaActionType, SagaActions } from "@sagas/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { URL_BINDER } from "@constant/url";
import Htag from "@layout/H1";

interface ContestLibraryProps {
    isModal?: boolean;
    setContest?: (params: any) => void;
}
const ContestLibrary = ({ isModal, setContest }: ContestLibraryProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setIsAscending] = useState<Boolean | null>(null);
    const [sortBy, setSortBy] = useState<string | null>(null);  // created_at
    const dispatch = useDispatch();
    const { data, page, hasMore, isLoading } = useSelector((state: any) => state?.contestLibrary);
    const Navigate = useNavigate();
    useEffect(() => {
        let timer: any;
        timer = setTimeout(() => {
            fetchData(false)
        }, 400);
        return () => clearTimeout(timer);
    }, [searchTerm, isAscending, sortBy]);

    const fetchData = (nextPage: boolean = true) => {
        const payload: any = { page: nextPage ? page + 1 : 1, term: searchTerm, sort_by: sortBy, direction: isAscending ? 'asc' : 'desc' }
        if (searchTerm === "") { delete payload.term; }
        if (isAscending === null) { delete payload.direction; delete payload.sort_by; }
        dispatch({
            type: `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
            payload: payload,
        });
    }
    const isBottom = (e: any) => {
        if (!hasMore || !isModal || isLoading) return;
        const totalHeight = e.target.scrollHeight;
        const currentScroll = e.target.scrollTop + e.target.clientHeight + 50;
        if (currentScroll >= totalHeight) {
            fetchData();
        }
    }

    const deleteContest = (id: string) => {
        dispatch({
            type: `${SagaActions.DELETE_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
            payload: { id },
        });
    }
    return (
        <>
            <Row gutter={[30, 30]}>
                <Col span={Boolean(data?.length > 0 || !isLoading) ? 18 : 24}>
                    <PageHeader hideVector isLoading={isModal && data.length === 0 && isLoading} className={isModal ? 'text-b' : ''} name={isModal ? "Select from Contest Library" : "Contest Library"} style={{ marginBottom: '3rem' }} />
                </Col>
                {Boolean(data?.length > 0 || !isLoading) &&
                    <Col sm={6} className="fd-icon-section">
                        <div className="content" style={{ width: 200 }}>
                            <div className="search" >
                                <input type="text" className="search__input" aria-label="search" placeholder="Search" onChange={(event) => {
                                    setSearchTerm(event.target.value);
                                }} />
                                <button className="search__submit" aria-label="submit search"><SearchIcon /></button>
                            </div>
                        </div>
                        <div className="fd-icon">
                            {(isAscending && sortBy === 'name') ? (
                                <Tooltip placement="bottom" title="Sorted A-Z " >
                                    <SortDescendingOutlined className="font-28" onClick={() => {
                                        setIsAscending(false);
                                        setSortBy('name');
                                    }} />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="bottom" title="Sorted Z-A " >
                                    <SortAscendingOutlined className="font-28" onClick={() => {
                                        setIsAscending(true);
                                        setSortBy('name');
                                    }} />
                                </Tooltip>
                            )}
                        </div>
                        {!isModal &&
                            ((isAscending && sortBy === 'created_at') ?
                                <SortClockUp className="fd-icon scale1-1 pointer" onClick={() => {
                                    setIsAscending(false);
                                    setSortBy('created_at');
                                }} />
                                :
                                <SortClock className="fd-icon scale1-1 pointer" onClick={() => {
                                    setIsAscending(true);
                                    setSortBy('created_at');
                                }} />
                            )
                        }
                    </Col>
                }
            </Row>
            <div style={isModal ? { height: '80vh', overflowY: 'auto' } : {}} onScroll={isBottom}>
                <InfiniteScroll
                    dataLength={data.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<Col span={24} className='h-80p flex-center mt-10'><Spin /></Col>}
                >
                    <Row gutter={[0, 15]} className="flights-contests p-4">
                        {!isModal && <CreateContest />}
                        {(data || []).map((contests: any, index: number) => (
                            <ContestCard 
                            contests={contests} 
                            index={index} 
                            key={'contestCard' + index} 
                            fn={() => { if (setContest) setContest(contests); else Navigate(`/admin/contest/${contests?.name + URL_BINDER + contests?.id +'(view)'}`) }} 
                            deleteFn={deleteContest} 

                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default ContestLibrary



const CreateContest = () => (
    <Col xl={6} lg={10} md={12} sm={24} xs={24} >
        <Card className="fdcard-container m-2">
            <div className="fdcard-container-inside">
                <div className="fd-add-container">
                    <Link to="/admin/contest/create">
                        <AddNewContest className="add-new-fd-image" />
                    </Link>
                    <p className="add-new-text">Create Contest</p>
                </div>
            </div>
        </Card>
    </Col>
);

type ContestProps = {
    contests: any;
    index: number;
    fn?: () => void;
    deleteFn: (id: string) => void;
}

const ContestCard: React.FC<ContestProps> = ({ contests, index, fn, deleteFn }) => (
    <Col xl={6} lg={10} md={12} sm={24} xs={24} key={'contestCard' + index}>
        <Card className="fdcard-container m-2">
            <div className="fdcard-container-inside">
                <div className="verticle-space-between">
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <div className={contests?.contest_visibility ? "visibilityTab publicTab all-caps" : "visibilityTab greyTab  all-caps"}>{contests?.contest_visibility}</div>
                        {contests?.contest_type === 'national' ? (<div className="visibilityTab nationalTab">NATIONAL</div>) : (<div className="visibilityTab greyTab">CHARLOTTE</div>)}
                        <Link to={`/admin/contest/${contests?.name + URL_BINDER + contests?.id}`} state={{ data: JSON.stringify(contests) }}><EditIcon className="iconStyles pointer" /></Link>
                        <DeleteIcon className="iconStyles pointer" onClick={() => { deleteFn(contests?.id) }} />
                    </div>
                    <div className="contestData pointer" onClick={() => { if (fn) fn() }}>
                        <Htag level={4} className='single-line' title={contests?.name}>{contests?.name}</Htag>
                        <p>{contests?.scoring_type}</p>
                        <p>{contests?.scoring_mode}</p>
                        {contests?.handicap_percentage !== '%' && <p>{contests?.handicap_percentage}</p>}
                        <p>Multiple Leaderboards: {contests?.is_multiple_leaderboard ? 'Yes' : 'No'}</p>
                    </div>
                </div>
            </div>
        </Card>
    </Col>
);

