import React, { lazy, Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import CustomRouter from "./CustomRouter";
import Success from "@components/SuccessInfo/Success";
import EventsTable from "@screens/admin/tables/PlayerTable";
import SimpleTables from "@screens/admin/tables/SampleTable";
import StaticLoader from "@components/loaders";
import { notification } from "antd";
import Pairings from "@screens/admin/pairings/Pairings";
import PairingsList from "@screens/admin/pairings/PairingsList";
import AddScoringContest from "@screens/admin/events/gcbStrokeplay/scorings/AddScoringContest";
import Accounting from "@screens/admin/accountSetup/Accounting/Accounting";
import AdminPrizesAwards from "@screens/admin/prizesAwards/AdminPrizesAwards";
import CreatePrizesAndAwards from "@screens/admin/prizesAwards/create/CreatePrizesAndAwards";
import ImportContact from "@screens/admin/marketing/contacts/ImportContact";
const CreateMailTemplate = lazy(() => import("@screens/admin/marketing/emailMarkting/CreateEmailTemplate"));
const SMSMarkting = lazy(() => import("@screens/admin/marketing/smsMarkting/SMSMarkting"));
export const history = createBrowserHistory();
const DND = lazy(() => import("@screens/admin/dnd/DND"));
// const AdminSignUp = lazy(() => import("@screens/admin/auth/SignUp"));
const EmailMarketing = lazy(() => import("@screens/admin/marketing/emailMarkting/EmailMarking"));
const CreateMailCampaign = lazy(() => import("@screens/admin/marketing/emailMarkting/CreateMailCampaign"));
const AdminLogin = lazy(() => import("@screens/admin/auth/Signin"));
const AdminForgot = lazy(() => import("@screens/admin/auth/ForgotPassword"));
const SetPassword = lazy(() => import("@screens/admin/auth/SetPassword"));
const InvitedUserLogin = lazy(
  () => import("@screens/admin/auth/InvitedUserLogin")
);
const AdminResetSuccess = lazy(
  () => import("@screens/admin/auth/PasswordResetSuccess")
);
const Dashboard = lazy(() => import("@screens/admin/events/DashBoard"));
const CreateContest = lazy(
  () => import("@screens/admin/events/creatContest/CreateContest")
);
const CreateEvent = lazy(
  () => import("@screens/admin/events/createEvent/CreateEvent")
);
const CreateChapter = lazy(
  () => import("@screens/admin/chapters/createChapter/CreateChapter")
);
const ViewChapter = lazy(
  () => import("@screens/admin/chapters/createChapter/ViewChapter")
);
const ArkansasChapter = lazy(
  () => import("@screens/admin/chapters/arkansasChapter/ArkansasChapter")
);
const AllChapters = lazy(
  () => import("@screens/admin/chapters/chapterList/ChapterList")
);
const UsersTable = lazy(() => import("@screens/admin/tables/UsersTable"));
const AccountSetup = lazy(
  () => import("@screens/admin/accountSetup/AccountSetup")
);
const AccountSetupSuccess = lazy(
  () => import("@screens/admin/accountSetup/AccountSetupSuccess")
);
const ChaptersSetup = lazy(
  () => import("@screens/admin/accountSetup/SetupChapters")
);
const TournamentOptions = lazy(
  () => import("@screens/admin/events/gcbStrokeplay/TournamentOptions")
);
const ContestsDashboard = lazy(
  () =>
    import("@screens/admin/events/contests/contestsDashboard/ContestsDashboard")
);
const UserManagement = lazy(
  () => import("@screens/admin/administration/userManagement/UserManagement")
);
const Membership = lazy(
  () => import("@screens/admin/administration/membership/Membership")
);
const PermissionMangement = lazy(
  () =>
    import(
      "@screens/admin/administration/permissionMangment/PermissionMangment"
    )
);
const PlayerManagement = lazy(
  () => import("@screens/admin/administration/playerMangement/PlayerManagement")
);
const CreateSponsors = lazy(
  () => import("@screens/admin/marketing/sponsors/CreateSponsors")
);
const CreateFlightsDivisions = lazy(
  () => import("@screens/admin/flightsDivisions/CreateFlightsDivisions")
);
const FlightsDivisionsDashBoard = lazy(
  () => import("@screens/admin/flightsDivisions/FlightsDivisionsDashBoard")
);
const ViewFlightsDivisions = lazy(
  () => import("@screens/admin/flightsDivisions/ViewFlightsDivisions")
);
const EditFlightsDivisions = lazy(
  () => import("@screens/admin/flightsDivisions/EditFlightsDivisions")
);

const ShambleContest = lazy(
  () => import("@screens/admin/events/shambleContest/CreateShambleContest")
);
const StableContest = lazy(
  () => import("@screens/admin/events/stableContest/CreateStableContest")
);
const NewSeason = lazy(() => import("@screens/admin/season/NewSeason"));
const MembershipDetails = lazy(
  () => import("@screens/admin/memberShips/MembershipDetails")
);
const SeasonTable = lazy(() => import("../screens/admin/season/SeasonTable"));
const LibraryCreateContest = lazy(
  () => import("../screens/admin/library/createContest/LibraryCreateContest")
);
const RoundSetUp = lazy(
  () => import("../screens/admin/events/gcbStrokeplay/RoundSetUp")
);

const CreateTouenament = lazy(
  () => import("../screens/admin/events/createTournament/CreateTournament")
);
const CreateSchedule = lazy(
  () => import("../screens/admin/events/createSchedule/CreateSchedule")
);
const Scorings = lazy(
  () => import("../screens/admin/events/gcbStrokeplay/scorings/Scorings")
);

const TournamentDashboard = lazy(
  () => import("../screens/admin/events/gcbStrokeplay/TournamentDashboard")
);

const ContestsLibrary = lazy(
  () => import("../screens/admin/contestsLibrary/ContestLibraryPage")
);

const OptionalSideGames = lazy(
  () => import("../screens/admin/events/gcbStrokeplay/OptionalSideGames")
);

const SideGameSetup = lazy(
  () =>
    import("../screens/admin/events/gcbStrokeplay/sideGameSetUp/SideGameSetup")
);

const CreateCourse = lazy(
  () => import("../screens/admin/courses/CreateCourse")
);

const Courses = lazy(() => import("../screens/admin/courses/Courses"));

const ViewCourse = lazy(() => import("../screens/admin/courses/ViewCourse"));

const Printings = lazy(
  () => import("../screens/admin/printingMaterials/Printings")
);

const SuperAdminNotificationCenter = lazy(
  () => import("../screens/admin/SuperAdminFlow/superAdminNotificationCenter")
);

const PrizesAwards = lazy(
  () => import("../screens/admin/prizesAwards/PrizesAwards")
);

const Players = lazy(
  () => import("@screens/admin/registeredPlayers/RegisteredPlayers")
);

const ViewTornament = lazy(
  () => import("@screens/admin/events/createEvent/ViewTornament")
);
const TournamentsList = lazy(
  () => import("../screens/admin/events/createTournament/TournamentsList")
);

const CheckIn = lazy(() => import("@screens/admin/checkin/CheckIn"));
const Leaderboards = lazy(
  () => import("@screens/admin/leaderboards/Leaderboards")
);
const TournamentAccounting = lazy(
  () => import("@screens/admin/accountSetup/Accounting/TournamentAccounting")
);
const CompanyAllPlayers = lazy(() => import("@screens/admin/companyAllPlayers/CompanyAllPlayers"));
const EventControls = lazy(() => import("@screens/admin/eventControls/EventControls"))

const SuperAdminDashboard = lazy(() => import("@screens/admin/superAdminDashboard/superAdminDashboard"))


// const SuccessInfo = lazy(() => import("@components/SuccessInfo/Success"));
const routesView = () => {
  const [api, contextHolder] = notification.useNotification();
  return (
    <Suspense fallback={<StaticLoader />}>
      {contextHolder}
      <CustomRouter history={history}>
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          {/* <Route path="/admin/SignUp" element={<AdminSignUp />} /> */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/forgot-password" element={<AdminForgot />} />
          <Route path="/accept-invite" element={<SetPassword />} />
          <Route path="/admin/table/users" element={<UsersTable />} />
          <Route path="/admin/account-setup" element={<AccountSetup />} />
          <Route
            path="/admin/account-setup/success"
            element={<AccountSetupSuccess />}
          />
          <Route path="/admin/reset_password" element={<InvitedUserLogin />} />
          <Route path="/admin/chapters-setup" element={<ChaptersSetup />} />
          {/* <Route path="/admin/success" element={<SuccessInfo />} /> */}
          <Route path="/success" element={<AdminResetSuccess />} />
          {/* These things below are MEANT to be protected/private routes */}
          <Route path="/admin/create/chapter" element={<CreateChapter />} />
          <Route
            path="/admin/create/chapter/view/:chapterID"
            element={<ViewChapter />}
          />
          <Route
            path="/admin/create/chapter/edit/:chapterID"
            element={<ViewChapter />}
          />
          <Route
            path="/admin/chapters/arkansas"
            element={<ArkansasChapter />}
          />
          <Route path="/admin/chapters" element={<AllChapters />} />
          <Route path="/admin/chapters/:CHAPTER" element={<ViewChapter />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/dashboard/event" element={<CreateEvent />} />
          {/* <Route path="/admin/events" element={<EventsTable />} /> */}
          <Route path="/admin/create/contest" element={<CreateContest />} />
          <Route path="/admin/menutable" element={<SimpleTables />} />
          <Route
            path="/admin/events/gcbStrokeplay"
            element={<TournamentOptions />}
          />
          <Route
            path="/admin/events/contests-dashboard"
            element={<ContestsDashboard />}
          />
          <Route path="/admin/user-management" element={<UserManagement />} />
          <Route path="/admin/memberships" element={<Membership />} />
          <Route
            path="/admin/memberships/create"
            element={<MembershipDetails />}
          />
          <Route
            path="/admin/memberships/:MEMBERSHIP"
            element={<MembershipDetails />}
          />

          <Route
            path="/admin/permission-access"
            element={<PermissionMangement />}
          />
          <Route
            path="/admin/player-management"
            element={<PlayerManagement />}
          />
          <Route path="/admin/sponsors" element={<CreateSponsors />} />
          <Route
            path="/admin/flights-divisions"
            element={<FlightsDivisionsDashBoard />}
          />
          <Route
            path="/admin/flights-divisions/create"
            element={<CreateFlightsDivisions />}
          />
          <Route
            path="/admin/flights-divisions/:FLIGHTS_DIVISIONS"
            element={<ViewFlightsDivisions />}
          />
          <Route
            path="/admin/flights-divisions/edit"
            element={<EditFlightsDivisions />}
          />

          <Route
            path="/admin/events/shambleContest/CreateShambleContest"
            element={<ShambleContest />}
          />
          <Route
            path="/admin/events/stableContest/CreateStableContest"
            element={<StableContest />}
          />
          <Route path="/admin/season/" element={<SeasonTable />} />
          <Route path="/admin/season/create" element={<NewSeason />} />
          <Route path="/admin/season/:SEASON" element={<NewSeason />} />
          <Route path="/admin/events/gcbStrokeplay/RoundSetUp" element={<RoundSetUp />} />

          <Route
            path="/admin/tournament/create-a-tournament"
            element={<CreateEvent />}
          />
          <Route
            path="/admin/tournament/create-a-tournament/budget"
            element={<CreateTouenament />}
          />
          <Route
            path="/admin/notification-center/:TOURNAMENT"
            element={<ViewTornament />}
          />

          <Route path="/admin/contest" element={<ContestsLibrary />} />
          <Route
            path="/admin/contest/create"
            element={<LibraryCreateContest />}
          />
          <Route
            path="/admin/contest/:CONTEST"
            element={<LibraryCreateContest />}
          />
          <Route element={<DND />} path="/admin/dnd" />

          <Route path="/admin/courses" element={<Courses />} />
          <Route path="/admin/courses/create" element={<CreateCourse />} />
          <Route path="/admin/courses/:COURSE" element={<ViewCourse />} />
          <Route path="/admin/email-marketing" element={<EmailMarketing />} />
          <Route path='/admin/email-marketing/create-mail-campaign' element={<CreateMailCampaign />} />
          <Route path="/admin/create-mail-template" element={<CreateMailTemplate />} />
          <Route path="/admin/sms-marketing" element={<SMSMarkting />} />
          <Route path="/admin/email-marketing/import-contacts" element={<ImportContact />} />
          <Route path="/admin/sms-marketing/import-contacts" element={<ImportContact />} />
          <Route
            path="/admin/notification-center"
            element={<SuperAdminNotificationCenter />}
          />
          <Route path="*" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/admin/tournaments" element={<TournamentsList />} />
          <Route
            path="/admin/prizes-awards"
            element={<AdminPrizesAwards />}
          />
          <Route
            path="/admin/prizes-awards/create"
            element={<CreatePrizesAndAwards />}
          />
          <Route
            path="/admin/prizes-awards/:PRIZES_AWARDS"
            element={<CreatePrizesAndAwards />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT"
            element={<TournamentDashboard />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/accounting"
            element={<TournamentAccounting />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/scorings"
            element={<Scorings />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/scorings/:NUM"
            element={<AddScoringContest />}
          />

          <Route
            path="/admin/tournaments/:TOURNAMENT/pairings"
            element={<PairingsList />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/pairings/:ROUND"
            element={<Pairings />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/players"
            element={<Players />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/check-ins"
            element={<CheckIn />}
          />

          <Route
            path="/admin/tournaments/:TOURNAMENT/side-games"
            element={<OptionalSideGames />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/side-games/:ROUND"
            element={<SideGameSetup />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/prizes-awards"
            element={<PrizesAwards />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/printings"
            element={<Printings />}
          />
          <Route
            path="/admin/tournaments/:TOURNAMENT/leaderboards"
            element={<Leaderboards />}
          />
          <Route
            path="admin/tournaments/:TOURNAMENT/schedules"
            element={<CreateSchedule />}
          />
          <Route path="admin/AllPlayers" element={<CompanyAllPlayers />} />
          <Route path="/admin/accounting" element={<Accounting />} />
          <Route
            path="admin/tournaments/:TOURNAMENT/event-controls"
            element={<EventControls />}
          />
          <Route
            path="admin"
            element={<SuperAdminDashboard />}
          />
        </Routes>
      </CustomRouter>
    </Suspense>
  );
};

export default routesView;
