import {
  takeLatest,
  call,
  put,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken, getCompanyID, getChapterID } from "../auth/localData";
import { Error, Success } from "../constant/toast/Toast";
import { history } from "@config/routes";

function* fetchChapterOwnerData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTER_OWNER}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.CHAPTER_OWNER}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);
  }

}

function* CreateChapterData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    payload.company_id = getCompanyID();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTER_CREATE}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CHAPTER_CREATE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      history.replace('/admin/chapters');
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CHAPTER_CREATE}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* viewChapter({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTERS}/${payload.id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.VIEW_CHAPTER}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* UpdateChapterData({ payload, id }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    payload.company_id = getCompanyID();
    const response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTERS}/${id}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.UPDATE_CHAPTER}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      history.replace('/admin/chapters');
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }

}



function* fetchAllChapterListData({ payload = {} }: any): Generator<any> {
  try {
      payload.access_token = getToken()
      let response: any = yield call(gcbAPI().get,
          `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTERS}`,
          { params: payload });
      if (response?.status === 200) {
          yield put({
              type: `${SagaActions.FETCH_CHAPTER_LIST}_${SagaActionType.SUCCESS}`,
              payload: { data: response?.data?.data, total_count: response?.data?.total_count, page: payload.page },
          });
      }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }

}

function* fetchChapterListData({ payload }: any): Generator<any> {
  const ID = getCompanyID();
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COMPANIES}/${ID}/${ApiEndpoints.CHAPTER_LIST}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.CHAPTER_LIST}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);

  }

}
function* fetchTornamentListData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTERS}/${payload.id}/${ApiEndpoints.LIST_TOURNAMENTS}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);

  }

}
function* DeleteChapter({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const id = payload.id
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTERS}/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_CHAPTER}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      //To reload list after deleting the Chapter
      yield put({
        type: `${SagaActions.CHAPTER_LIST}_${SagaActionType.REQUEST}`,
        payload: {}
      });
      // history.replace('/admin/chapters');
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CHAPTER_LIST}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchCourseListData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COURSES_LIST}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.COURSES_LIST}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);

  }

}

function* fetchStaffListData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.STAFF_LIST}`,
      { params: { access_token: getToken(), chapter_id: payload.id || getChapterID() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.STAFF_LIST}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);

  }

}

function* fetchDirectorListData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.DIRECTOR_LIST}`,
      { params: { access_token: localStorage.getItem("accessToken"), chapter_id: payload.id || getChapterID() } });
    if (response?.status === 200) {
      const { data } = response || {};
      yield put({
        type: `${SagaActions.DIRECTOR_LIST}_${SagaActionType.SUCCESS}`,
        payload: data
      });
    }
  }
  catch (error) {
    console.log(error);

  }

}
function* createBudgetAPI({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    payload.company_id = getCompanyID()
    const response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_BUDGETS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      history.replace('/admin/tournaments');
    }

  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.SUCCESS}`,
      payload: { data: undefined }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* createEventAPI({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    payload.company_id = getCompanyID()
    let payloadData = {
      access_token: payload.access_token,
      name: payload.name,
      tournament_type: payload.tournament_type,
      featured_tournament: payload?.featured_tournament,
      chapter_id: payload.chapter_id,
      company_id: payload.company_id,
      rounds_count: payload.rounds_count,
      registration_start_date: payload.registration_start_date,
      registration_end_date: payload.registration_end_date,
      director_id: payload.director_id,
      tournament_staff_ids: JSON.stringify([payload.tournament_staff_ids]),
      cancellation_fee: payload.cancellation_fee,
      deposit_fee: payload.deposit_fee,
      is_late_fee: payload.is_late_fee,
      late_fees: payload.late_fees,
      notes: payload.notes,
      poster: payload.poster,
      collect_last_fees_from: payload.collect_last_fees_from,
      tournament_rounds: payload.tournament_rounds,
      // deposit_due_date: payload.deposit_due_date,
      remaining_fee_start_date: payload.date_to_start_collecting_remaining_fees,
      is_accept_tour_credits: payload.is_accept_tour_credits,
      max_tour_credits: payload.max_tour_credits,
      description: payload.description,
    }
    const response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.EVENT}`,
      { ...payloadData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
    history.replace('/admin/tournament/create-a-tournament/budget');
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* createTournamentRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.REQUEST}`,
    createEventAPI
  );
}
function* createBudgetRequest() {
  yield takeLatest(
    `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.REQUEST}`,
    createBudgetAPI
  );
}

function* ChapterOwnerRequest() {
  yield takeEvery(
    `${SagaActions.CHAPTER_OWNER}_${SagaActionType.REQUEST}`,
    fetchChapterOwnerData
  );
}
function* ChapterCreateRequest() {
  yield takeEvery(
    `${SagaActions.CHAPTER_CREATE}_${SagaActionType.REQUEST}`,
    CreateChapterData
  );
}

function* ChapterListRequest() {
  yield takeEvery(
    `${SagaActions.CHAPTER_LIST}_${SagaActionType.REQUEST}`,
    fetchChapterListData
  );
}

function* CourseListRequest() {
  yield takeEvery(
    `${SagaActions.COURSES_LIST}_${SagaActionType.REQUEST}`,
    fetchCourseListData
  );
}

function* StaffListRequest() {
  yield takeEvery(
    `${SagaActions.STAFF_LIST}_${SagaActionType.REQUEST}`,
    fetchStaffListData
  );
}

function* DirectorListRequest() {
  yield takeEvery(
    `${SagaActions.DIRECTOR_LIST}_${SagaActionType.REQUEST}`,
    fetchDirectorListData
  );
}

function* ViewChapterRequest() {
  yield takeEvery(
    `${SagaActions.VIEW_CHAPTER}_${SagaActionType.REQUEST}`,
    viewChapter
  );
}

function* UpdateRequest() {
  yield takeEvery(
    `${SagaActions.UPDATE_CHAPTER}_${SagaActionType.REQUEST}`,
    UpdateChapterData
  );
}

function* DeleteChapterRequest() {
  yield takeEvery(
    `${SagaActions.DELETE_CHAPTER}_${SagaActionType.REQUEST}`,
    DeleteChapter
  );
}

function* AllChapterListRequest() {
  yield takeEvery(
    `${SagaActions.FETCH_CHAPTER_LIST}_${SagaActionType.REQUEST}`,
    fetchAllChapterListData
  );
}
function* fetchTornamentListDataRequest() {
  yield takeLatest(
    `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.REQUEST}`,
    fetchTornamentListData
  )
} 

export default function* rootChapterSaga() {
  yield spawn(ChapterOwnerRequest);
  yield spawn(ChapterListRequest);
  yield spawn(ChapterCreateRequest);
  yield spawn(CourseListRequest);
  yield spawn(StaffListRequest);
  yield spawn(DirectorListRequest);
  yield spawn(createTournamentRequest);
  yield spawn(createBudgetRequest);
  yield spawn(ViewChapterRequest);
  yield spawn(UpdateRequest);
  yield spawn(DeleteChapterRequest);
  yield spawn(AllChapterListRequest);
  yield spawn(fetchTornamentListDataRequest)
}