import axios, { AxiosError, AxiosResponse } from "axios";

//const isProduction = process.env.NODE_ENV === "production";   // THis IS correct ways to check for production
const isProduction = window.location.href.includes('https://gcb-admin.golf');
const BASE_URL_V_1 = isProduction ? 'https://gcb-be.golf.cloud/api/v1' : "https://stag-gcb-be.golf.cloud/api/v1";
//For future use when more versions come up
//const BASE_URL_V_2 = "https://stag-gcb-be.golf.cloud/api/v2";
//const BASE_URL_V_3 = "https://stag-gcb-be.golf.cloud/api/v3";


const commonConfig = {
  timeout: 60 * 1000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
export const gcbAPI = (token?: any) => {
  const gcbAPIInstance = axios.create({
    ...commonConfig,
    baseURL: BASE_URL_V_1,
  });

  gcbAPIInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      if (response?.status === 401 && !((window.location.href).includes('http://'))) {
        window.location.href = '/admin/login';
      }
      return Promise.reject(response || request);
    }
  );
  return gcbAPIInstance;
};

export const commonAPI = () => {
  const commonInstance = axios.create({
    ...commonConfig,
    baseURL: "",
  });
  commonInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  commonInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return commonInstance;
};

interface responseError extends AxiosResponse, Error {
  data: {} | string;
}

export const getErrorMessage = (
  error: responseError,
  errorheader: string = ""
) => {
  const { data, message } = error || {};
  if (typeof data === "string") {
    const FINAL_ERROR = data || message;
    console.log("ERROR", errorheader, FINAL_ERROR);
    return FINAL_ERROR;
  } else {
    const FINAL_ERROR = message;
    console.log("ERROR", errorheader, FINAL_ERROR);
    return FINAL_ERROR;
  }
};
