import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

type standardDivisionRanges = {
    id: number;
    name: string;
    start_range: string;
    end_range: string;
    inner_color: string;
    outer_color: string;
}

type flightsDivisionsData = {
    id: number;
    name: string;
    is_active: boolean;
    display_as: string;
    standard_division_ranges: standardDivisionRanges[];
}
export interface FlightsDivisionsInterface {
    data: any[]; // player data storage
    isLoading: boolean; // loader
    isActiveLoading: boolean;
    flightsDivisionsData: flightsDivisionsData;
}
const initialState: FlightsDivisionsInterface = {
    data: [],
    isLoading: true,
    isActiveLoading: false,
    flightsDivisionsData: {
        id: 0,
        name: "",
        is_active: false,
        display_as: "",
        standard_division_ranges: []
    },
};
export const FlightsDivisionsSlice = createSlice({
    name: "flightsDivisions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    data: action.payload.data,

                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DIVISIONS_SET_ACTIVE}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isActiveLoading: false,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DIVISIONS_SET_ACTIVE}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isActiveLoading: true,
                };;
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.VIEW_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };;
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.VIEW_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    flightsDivisionsData: action.payload.data,
                };;
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.UPDATE_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.UPDATE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false
                };
            }
        );

        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DELETE_FLIGHTS_DIVISIONS}_${SagaActionType.REQUEST}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        );
        builder.addMatcher(
            (action) =>
                action.type ===
                `${SagaActions.DELETE_FLIGHTS_DIVISIONS}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                };
            }
        );

    },
})

export default FlightsDivisionsSlice.reducer;  