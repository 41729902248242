const userData = (): any => {
    let data: string | null = localStorage.getItem('user')
    return JSON.parse(data!)
}
export const USER_ROLE_INTERFACE = 'user_role'
export const CHAPTER_ID = 'chapter_id'
export const NotificationCount = 'notification_count'

export function getLocalVal(params: any) {
    const user = userData()
    if (!user)
        window.location.pathname = 'admin/login'
    return user[params] || {}
}
export function getToken(): string | null {
    const user = userData()
    if (!user)
        window.location.pathname = 'admin/login'
    return user?.access_token
}
export function getEmail(): string | null {
    const user = userData()
    if (!user)
        window.location.pathname = 'admin/login'
    return user?.email
}
export function getCompanyID(): string | null {
    const user = userData()
    return user?.company_id
}
export function getChapterID(): string | null {
    const user = userData()
    return user?.chapter_id || undefined;
}
export function getUseRole(): string | null {
    const user = userData()
    return user?.user_role
}
export function setUserData(user: object) {
    const userData = JSON.stringify(user)
    localStorage?.setItem('user', userData)
}
export function getNotificationCount(): string | null {
    const user = userData()
    return user?.notification_count || undefined;
}