import { useCallback, useMemo, useState } from "react";
import { MainLayout } from "@components/layouts";
import PageHeader from "@components/page/PageHeader";
import { Col, Row } from "antd";
import './pairingsStyle.css'
import PairingHeader from "./PairingHeader";
import { AddCustomHoles, UserInfoCard } from "./Component";
import { useRef } from "react";
import { useEffect } from "react";
import { getCustomHolesArray, getHolesArray, getUniqIndex, getUniqKey, showWhenNeededFun } from "./HelperFuc";
import PairingButtons from "./PairingSheet";
import SheetHeader from "./SheetHeader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AllPlayers from "./AllPlayers";
import moment from "moment";
import { useAppSelector } from "@utils/reduxHooks";
import { useDispatch } from "react-redux";
import { SagaActionType, SagaActions } from "@sagas/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { URL_BINDER } from "@constant/url";

const sheet_width = 65
let showWhenNeededFlag = false;
interface DragedUser {
    [key: string]: {
        color_hex_code?: string,
        name: string,
    };
}
interface SelectedRoundProps {
    time?: any
    type?: any
    shotgun_time?: any
    tee_time?: any;
    time_difference?: any
    starting_format?: any
}
type RowDataType = { name: string, teeTime: string };

function Pairings() {
    const [selectedRoundIndex, setRoundIndex] = useState(0);
    const [column, setColumn] = useState(3)
    const [customHole, setCustomHole] = useState({ count: '-', value: '--' })
    const { isPlayerDraged, isLoadingCourse, isPlayerListDragedOut, isPlayerDragedOut, selectedCourse, dragedPlayerArray, isCourseListFetched, CourseList } = useAppSelector((state: any) => state.pairingPlayer);
    const [rowData, setRowData] = useState<RowDataType[]>([])
    const [dragedUser, setDragedUser] = useState<any>({})
    const [selectedInSheet, setSelectedInSheet] = useState<any>({})
    const [selectedInPlayer, setSelectedInPlayer] = useState<any>({})
    const [divColor, setDivColor] = useState<{ [key: string]: string }>({})

    const isTeeTime = useMemo(() => selectedCourse?.starting_format?.includes('Tee') || false, [selectedCourse?.starting_format]); // fix name dont know, future use
    const isDoubleTeeTime = useMemo(() => selectedCourse?.starting_format?.includes('Double') || false, [selectedCourse?.starting_format]); // later we can make it dynamic and add 2 diff condition into one
    const TIMESHIFTER: number = useMemo(() => { return (new Date(selectedCourse?.time_difference).getTime()) - (isDoubleTeeTime ? new Date(selectedCourse?.double_tee_time).getTime() : new Date(selectedCourse?.tee_time).getTime()) }, [selectedCourse]);
    const [rowState, setRowState] = useState({ format: 'A', count: 18 })
    const [sortSheet, setSortSheet] = useState("All")

    const allPlayersState = useAppSelector((state: any) => state.allPlayers);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { TOURNAMENT, ROUND } = useParams();
    const [T_NAME, ID] = TOURNAMENT?.split(URL_BINDER) || [];
    const [R_NAME, roundID] = ROUND?.split(URL_BINDER) || [];
    const TOURNAMENT_ID = ID
    const sheetScrollRef = useRef<any>(null);
    const swapTableView = useRef<any>(null);


    const scrollToEndOfSheet = useCallback(() => {
        showWhenNeededFlag = true
        if (sheetScrollRef && sheetScrollRef.current) {
            setTimeout(() => {
                sheetScrollRef.current.scrollLeft = sheetScrollRef.current.scrollWidth;
            }, 0)
        }
    }, []);

    const swapTableViewFun = useCallback(() => {
        if (swapTableView && swapTableView.current) {
            if (swapTableView?.current?.style?.flexDirection) {
                swapTableView.current.style.flexDirection = '';
            } else
                swapTableView.current.style.flexDirection = 'row-reverse';
        }
    }, []);
    const AddRowInSheet = () => {
        if (sheetScrollRef && sheetScrollRef.current) {
            setTimeout(() => {
                sheetScrollRef.current.scrollTop = sheetScrollRef.current.scrollHeight;
            }, 100)
        }
    }

    const deleteUser = async (key: string) => {
        dispatch({ type: `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.REQUEST}`, payload: dragedUser[key].id })
        setDragedUser((prev: DragedUser) => {
            const data: DragedUser = { ...prev }
            delete data[key]
            return data
        })
    }
    const handleDNDView = (show: boolean, draggableId: string | number) => {
        draggableId = Number(draggableId)
        const objLength = Object.keys(selectedInSheet).length
        if (objLength) {
            const key = Math.floor(draggableId / column) + '-' + (draggableId % column)
            const element: any = document.getElementById(key + 'CardView');
            const dndView: any = element.querySelector('.dnd-multidrag-view')
            dndView.style.display = show ? 'block' : ''
            if (objLength > 1)
                dndView.innerHTML = `<h1 class='dnd-multidrag-view-note'>+${objLength - 1}</h1>`
        }
    }
    const onDragStart = (start: any) => {
        if (start.source.droppableId === 'allPlayers') {
            const element: any = document.getElementById('PlayerView-' + start.source.index)
            element?.classList.add('d-box-shadow')
            if (Object.keys(selectedInPlayer).length > 1 && element?.innerHTML !== undefined) {
                element.innerHTML += `<div><b class='dnd-multidrag-allPlayer-view-note'>+${Object.keys(selectedInPlayer).length - 1}</b></div>`;
            }
        } else
            handleDNDView(true, start.source.index)
    };
    const selectedInSheetFun = (hole: number, player: number, e: any) => {
        if (e.ctrlKey || e.shiftKey || e.metaKey) {
            const data = { ...selectedInSheet }
            const key = hole + '-' + player
            if (data[key]) {
                delete data[key]
            } else if (dragedUser[key]) {
                if (Object.keys(selectedInSheet).length < column)
                    data[key] = true
            }
            setSelectedInSheet(data)
        }
    }
    const selectedInPlayerFun = (e: any) => {
        const { checked, name } = e.target
        const data = { ...selectedInPlayer }
        const key = name
        if (checked) {
            if (Object.keys(selectedInPlayer).length < column)
                data[key] = true
        } else {
            delete data[key]
        }
        setSelectedInPlayer(data)
    }
    const AddCustomHolesFuc = useCallback(() => {
        const { count, value } = { ...customHole }
        const [data, index, scrollBottom] = getCustomHolesArray(rowData, count, value)
        setCustomHole({ count: '-', value: '--' })
        const TIME = isDoubleTeeTime ? new Date(selectedCourse?.double_tee_time).getTime() : isTeeTime ? new Date(selectedCourse?.tee_time).getTime() : selectedCourse?.shotgun_time
        let newHoles: RowDataType[] = data.map((hole: any, index: number) => { if (isTeeTime) return { teeTime: new Date(TIME + (index * TIMESHIFTER)).toISOString(), name: hole }; else return { teeTime: TIME, name: hole } })
        setRowData(newHoles)
        if (scrollBottom) AddRowInSheet();
        dispatch({ type: `${SagaActions.ADD_CUSTOM_HOLE}_${SagaActionType.REQUEST}`, payload: { hole_details: newHoles, pattern: 'custom', id: CourseList[selectedRoundIndex].pairing_id } })
    }, [customHole])

    const onDragEnd = (result: any) => {
        const { destination, source, draggableId } = result;
        if (!destination) { return; }
        if (destination.droppableId === source.droppableId && destination.index === source.index) { return; }
        let tempObj = { ...dragedUser };
        let apiData = []
        let is_internal = true
        if (result.source.droppableId === 'allPlayers') {
            is_internal = false
            const element = document.getElementById('PlayerView-' + result.source.index)

            if (element?.innerHTML !== undefined) {
                const text = element.innerHTML
                const updateName = text.split('<')[0]
                element.innerHTML = updateName
            }
            if (Object.keys(selectedInPlayer).length) {
                const { index, droppableId } = destination
                let hole = Number(droppableId.split('-')[0])
                let player = (index) % column
                for (let keyS of Object.keys(selectedInPlayer)) {
                    let keyD = Math.floor(hole) + '-' + ((player++) % column)
                    if (!tempObj[keyD]?.player_name) {
                        const INDEX = allPlayersState.PlayersData.findIndex((player: any) => player.id === parseInt(keyS))
                        tempObj[keyD] = JSON.parse(JSON.stringify(allPlayersState.PlayersData[INDEX]))
                        tempObj[keyD].player_name = tempObj[keyD].name
                        delete tempObj[keyD].name
                        delete tempObj[keyD].id
                        const [holeD, playerD] = keyD.split('-')
                        apiData.push({ ...tempObj[keyD], player_number: Number(playerD), hole: Number(holeD), hole_name: rowData[Number(holeD)].name, tee_time: rowData[Number(holeD)].teeTime })
                    }
                }
                setSelectedInPlayer({})
            } else {
                const holeD = Math.floor(destination.index / column)
                const playerD = destination.index % column
                const keyD = holeD + '-' + playerD
                const INDEX = allPlayersState.PlayersData.findIndex((player: any) => player.id === result.source.index)
                if (!tempObj[keyD]?.player_name) {
                    tempObj[keyD] = JSON.parse(JSON.stringify(allPlayersState.PlayersData[INDEX]))
                    tempObj[keyD].player_name = tempObj[keyD].name
                    delete tempObj[keyD].name
                    delete tempObj[keyD].id
                    apiData.push({ ...tempObj[keyD], player_number: playerD, hole: holeD, hole_name: rowData[Number(holeD)].name, tee_time: rowData[Number(holeD)].teeTime })
                }
            }
        } else if (Object.keys(selectedInSheet).length) {
            handleDNDView(false, result.draggableId)
            const { index, droppableId } = destination
            let holeD = Number(droppableId.split('-')[0])
            let playerD = (index) % column
            for (let keyS of Object.keys(selectedInSheet)) {
                let keyD = Math.floor(holeD) + '-' + (playerD)
                let temp = tempObj[keyD] ? JSON.parse(JSON.stringify(tempObj[keyD])) : undefined
                tempObj[keyD] = tempObj[keyS]
                apiData.push({ ...tempObj[keyS], player_number: playerD, hole: holeD, hole_name: rowData[Number(holeD)].name, tee_time: rowData[Number(holeD)].teeTime })
                if (temp?.player_name) {
                    tempObj[keyS] = temp
                    const [holeS, playerS] = keyS.split('-')
                    apiData.push({ ...temp, player_number: Number(playerS), hole: Number(holeS), hole_name: rowData[Number(holeS)].name, tee_time: rowData[Number(holeS)].teeTime })
                } else {
                    delete tempObj[keyS]
                }
                playerD = (playerD + 1) % column
            }
            setSelectedInSheet({})
        } else {
            const holeD = Math.floor(destination.index / column)
            const holeS = Math.floor(source.index / column)
            const playerD = destination.index % column
            const playerS = source.index % column
            const keyD = Math.floor(holeD) + '-' + (playerD)
            const keyS = Math.floor(holeS) + '-' + (playerS)
            let temp = tempObj[keyD] ? JSON.parse(JSON.stringify(tempObj[keyD])) : undefined
            tempObj[keyD] = tempObj[keyS]
            apiData.push({ ...tempObj[keyS], player_number: playerD, hole: holeD, hole_name: rowData[Number(holeD)].name, tee_time: rowData[Number(holeD)].teeTime })
            if (temp?.player_name) {
                tempObj[keyS] = temp
                apiData.push({ ...temp, player_number: playerS, hole: holeS, hole_name: rowData[Number(holeS)].name, tee_time: rowData[Number(holeS)].teeTime })
            } else {
                delete tempObj[keyS]
            }
        }
        if (apiData.length)
            dispatch({
                type: `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.REQUEST}`,
                payload: { is_internal: is_internal, data: apiData, id: CourseList[selectedRoundIndex].id, pairing_id: CourseList[selectedRoundIndex].pairing_id, tournament_id: TOURNAMENT_ID }
            })
        setDragedUser(tempObj)
    }
    const handleScroll = (e: any) => {
        if (e.target.scrollLeft > 60) {
            if (!showWhenNeededFlag) return
            showWhenNeededFlag = false
            showWhenNeededFun('showWhenNeeded', '')
        } else {
            if (showWhenNeededFlag) return;
            showWhenNeededFlag = true
            showWhenNeededFun('showWhenNeeded', 'none')
        }
    };

    useEffect(() => {
        if (!isPlayerDraged && !isPlayerDragedOut) {
            let playerData: any = {}
            let colorData: any = {}
            for (let player of dragedPlayerArray) {
                const key = player.hole + '-' + player.player_number
                playerData[key] = { ...player }
                if (player?.division_name && !colorData[player?.division_name] && player?.inner_color)
                    colorData[player?.division_name] = player?.inner_color
            }
            setDragedUser(playerData)
            setDivColor(colorData)
        }
    }, [isPlayerDraged, isPlayerDragedOut, isLoadingCourse, dragedPlayerArray])

    useEffect(() => {
        const id = roundID
        if (id == "" || id === null || TOURNAMENT_ID == "" || TOURNAMENT_ID === null) {
            navigate('/tournaments');
        } else if (!isCourseListFetched) {
            dispatch({
                type: `${SagaActions.PAIRING_COURSE_LIST}_${SagaActionType.REQUEST}`,
                payload: { id: id }
            })
        }
    }, []);

    useEffect(() => {
        if (!isCourseListFetched && CourseList.length > 0) {
            dispatch({
                type: `${SagaActions.PAIRING_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
                payload: { id: CourseList[selectedRoundIndex].pairing_id }
            })
            setSelectedInPlayer({}); setSelectedInSheet({}) //clear multi selected players
        }
    }, [selectedRoundIndex, CourseList])

    useEffect(() => {
        try {
            if (rowState.format !== 'custom') {
                const data = getHolesArray({ ...rowState })
                const TIME = isDoubleTeeTime ? new Date(selectedCourse?.double_tee_time).getTime() : isTeeTime ? new Date(selectedCourse?.tee_time).getTime() : selectedCourse?.shotgun_time
                let newHoles: RowDataType[] = data.map((hole: any, index) => { if (isTeeTime) return { teeTime: new Date(TIME + (index * TIMESHIFTER)).toISOString(), name: hole }; else return { teeTime: TIME, name: hole } })
                if (CourseList[selectedRoundIndex]?.pairing_id && selectedCourse?.pattern !== rowState.format.toLowerCase())
                    dispatch({
                        type: `${SagaActions.UPDATE_PAIRING_HOLES}_${SagaActionType.REQUEST}`,
                        payload: { hole_details: newHoles, combination: rowState.format, id: CourseList[selectedRoundIndex].pairing_id }
                    })
                setRowData(newHoles)
            } else {
                const TIME = isDoubleTeeTime ? new Date(selectedCourse?.double_tee_time).getTime() : isTeeTime ? new Date(selectedCourse?.tee_time).getTime() : selectedCourse?.shotgun_time
                let newHoles: RowDataType[] = selectedCourse?.holes.map((hole: any, index: number) => { if (isTeeTime) return { teeTime: new Date(TIME + (index * TIMESHIFTER)).toISOString(), name: hole }; else return { teeTime: TIME, name: hole } })
                setRowData(newHoles)
            }
        } catch (error) {
            console.log(error)
        }
    }, [rowState])

    useEffect(() => {
        const players_count = selectedCourse?.last_column_name ? selectedCourse.last_column_name + 1 : 3
        setColumn(players_count)
        setRowState({ ...rowState, format: selectedCourse?.pattern || rowState.format })
    }, [selectedCourse])
    return (
        <MainLayout>
            <>
                <PageHeader name="Pairings" style={{ marginBottom: '1.5rem' }} />
                <PairingHeader assigned={dragedPlayerArray?.length} roundInfo={CourseList} setRoundInfo={setRoundIndex} selectedRound={selectedCourse} selectedIndex={selectedRoundIndex} />
                <Row ref={swapTableView} className="">
                    <DragDropContext
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <Col style={{ width: `${sheet_width}%` }}>
                            <SheetHeader colorArray={divColor} showCustom={selectedCourse?.pattern === 'custom'} isTeeTime={isTeeTime} setRowState={setRowState} setSortSheet={setSortSheet} sortSheet={sortSheet} rowState={rowState} />
                            <Row className="text-w text-bold d-border mt--1 pairing-sheet-scroll" ref={sheetScrollRef} onScroll={handleScroll} >
                                <Row className="w-100 ml--1">
                                    <div className="flex-row h-60 d-border mt--1">
                                        <Col className="flex-center w-120 bg-0076A8 flex-col" >
                                            Starting Hole
                                            {isTeeTime && <p>(Tee Time)</p>}
                                        </Col>
                                        {Array(column).fill('').map((_, index) =>
                                            <Col key={'player' + index} className="flex-center w-220 bg-0076A8 ml--1">
                                                Player {index + 1}
                                            </Col>
                                        )}
                                    </div>
                                </Row>
                                {(rowData).map((HOLE, INDEX) => {
                                    let hideFlag = false
                                    if (sortSheet === 'Unassigned' || sortSheet === 'Assigned') {
                                        let count = 0
                                        for (let i in Array(column).fill('')) {
                                            const key = INDEX + '-' + i;
                                            if (dragedUser[key]) count++;
                                        }
                                        if (count === column && sortSheet === 'Unassigned') hideFlag = true
                                        else if (count === 0 && sortSheet === 'Assigned') hideFlag = true
                                    }
                                    if (hideFlag) return null
                                    return (<Row className="w-100 mt--1" key={HOLE.name + INDEX}>
                                        <div className="flex-row d-border">
                                            <Col className="flex-center text-b flex-col w-120 ml--1 r-border h-80p " >
                                                <p className='b-skyBlue p-3 b-50'>{HOLE?.name.padStart(2, '0')}</p>
                                                <b>{(HOLE?.teeTime && isTeeTime) && moment(HOLE?.teeTime).format('hh:mm a')}</b>
                                            </Col>
                                            <Droppable droppableId={`${INDEX}-`} direction="horizontal">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="dispaly-inherite"
                                                    >
                                                        {Array(column).fill('').map((_, index) => {
                                                            const uniqKey: string = INDEX + '-' + index;
                                                            const dragIndex: number = column * INDEX + index
                                                            return (
                                                                <Col key={rowState.format + INDEX + '--' + index} style={selectedInSheet[uniqKey] ? { opacity: 0.5 } : {}} className="w-220 r-border  ml--1">
                                                                    <Draggable key={dragIndex} draggableId={String(dragIndex)} index={dragIndex} isDragDisabled={dragedUser[uniqKey]?.player_name ? false : true}>
                                                                        {(provided: any) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className="h-100 w-100 flex-center pointer-i p-rel"
                                                                            >
                                                                                {dragedUser[uniqKey]?.player_name ?
                                                                                    <UserInfoCard data={dragedUser[uniqKey]} deleteUser={deleteUser} uniqKey={uniqKey} handleDND={(e: any) => { selectedInSheetFun(INDEX, index, e) }} />
                                                                                    :
                                                                                    <span className="text-b opacity-5 showWhenNeeded" style={{ display: 'none' }}> <p className='b-skyBlue p-3 b-50 my-4'>{HOLE?.name.padStart(2, '0')}</p></span>
                                                                                }
                                                                            </div>)}
                                                                    </Draggable>
                                                                </Col>)
                                                        }
                                                        )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </Row>)
                                }
                                )}
                            </Row>
                            <AddCustomHoles rowData={rowData} customHole={customHole} setCustomHole={setCustomHole} AddCustomHolesFuc={AddCustomHolesFuc} isTeeTime={isTeeTime} rowState={rowState} />
                        </Col>
                        <PairingButtons column={column} isLoading={isPlayerListDragedOut} ID={CourseList[selectedRoundIndex]?.pairing_id} setColumn={setColumn} scrollToEndOfSheet={scrollToEndOfSheet} swapTableViewFun={swapTableViewFun} />
                        <Col style={{ width: `calc((100% - ${sheet_width}%) - 80px)` }} className="smooth-scrollable-container">
                            <Row align="middle" className="w-100 mb-4">
                                <Col>
                                    <b>All Players</b>
                                </Col>
                            </Row>
                            <AllPlayers ROUND_ID={roundID} ID={TOURNAMENT_ID} allPlayersState={allPlayersState} selectedInPlayerFun={selectedInPlayerFun} selectedInPlayer={selectedInPlayer} />
                        </Col>
                    </DragDropContext>
                </Row>
            </>
        </MainLayout >);
}

export default Pairings;