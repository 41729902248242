import moment from "moment";

export const dateMDY = (value: any) => new Date(value).toLocaleDateString("en-US")



export const createPreviewByMonth = (array: any[]) => {
    let sortedArray = array.sort((a: any, b: any) => a.date - b.date);
    let tempDate: any = '';
    let newPreview: any[] = [];
    for (let i = 0; i < sortedArray.length; i++) {
        if (tempDate !== sortedArray[i].date) {
            tempDate = moment(sortedArray[i]?.date).format('DD/MM/YYYY');
            let tempObj: any = { date: moment(sortedArray[i]?.date).format('DD'), month: moment(sortedArray[i]?.date).format('MMMM'), data: [] };
            while (tempDate === moment(sortedArray[i]?.date).format('DD/MM/YYYY') && i < sortedArray.length && tempDate !== '' && sortedArray[i]?.date) {
                tempObj.data.push(sortedArray[i]);
                i++;
            }
            i--;
            newPreview.push(tempObj);
        }
    }
    return newPreview;
}
export const getDaysBefore = (date: any = '7') => {
    let daysBefore:any = moment().subtract(date, 'days');
    return new Date(daysBefore);
} 