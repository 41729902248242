import React, { useState } from "react";
import "./styles.css";
import { CheckboxButton } from "./checkboxButton";
import { MainLayout } from "@components/layouts";

const columnHeaders = [
  "Module & Access level",
  "Chapter Admin",
  "Tournament Admin",
  "Tournament Director",
  "Tournament Staff",
];

const SimpleTables: React.FC = () => {
  const [subData, setSubData] = useState([
    { label: "All (Yes) ", value: false, disabled: false },
    { label: "Read (Yes)", value: null, disabled: true },
    { label: "Write (Yes)", value: null, disabled: true },
    { label: "Blocked (Yes)", value: true, disabled: false },
  ]);
  const tempTableData = [
    ["Email & Marketing", subData, subData, subData, subData],
    ["Events", subData, subData, subData, subData],
    ["Score Approval", subData, subData, subData, subData],
    ["Payments", subData, subData, subData, subData],
    ["Holes Info TeeBox", subData, subData, subData, subData],
  ];

  const [tableData, setTableData] = useState(tempTableData);
  const [, setForceUpdate] = useState<any>(null);
  return (
    <MainLayout>
      <div>
        <div style={{ display: "flex" }}>
          {columnHeaders.map((item, index) => (
            <div
              style={{
                width: "15vw",
                minHeight: 45,
                border: "solid",
                borderWidth: 0.1,
                borderColor: "#A6A8AB",
                textAlign: "center",
                paddingTop: 7,
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3
                style={{ fontWeight: "bold", fontFamily: "Plus Jakarta Sans" }}
                key={index}
              >
                {item}
              </h3>
            </div>
          ))}
        </div>
        {tableData.map((mainData, mainIndex) => (
          <div style={{ display: "flex" }}>
            {mainData.map((item, index) => (
              <div
                style={{
                  width: "15vw",
                  minHeight: "15vw",
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#A6A8AB",
                  color: "#000",
                  paddingTop: 7,
                  paddingLeft: 15,
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                }}
              >
                {typeof item === "object" ? (
                  <>
                    {item.map((subData: any, subIndex: any) => (
                      <div
                        style={{
                          padding: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="sampletable-checkbox"
                      >
                        <div
                          className="sampletable-checkbox-background"
                          style={{
                            fontWeight: "normal",
                            fontFamily: "Plus Jakarta Sans",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CheckboxButton
                            label={subData.label}
                            value={subData.value}
                            disabled={subData.disabled}
                            onClick={() => {
                              const tempItem = item;
                              tempItem.forEach((data: any) => {
                                if (subData.label == "All (Yes)") {
                                  setSubData([
                                    {
                                      label: "All (Yes) ",
                                      value: true,
                                      disabled: false,
                                    },
                                    {
                                      label: "Read (Yes)",
                                      value: null,
                                      disabled: false,
                                    },
                                    {
                                      label: "Write (Yes)",
                                      value: null,
                                      disabled: false,
                                    },
                                    {
                                      label: "Blocked (Yes)",
                                      value: false,
                                      disabled: false,
                                    },
                                  ]);
                                  setTableData(tempTableData);
                                  setForceUpdate({});
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontWeight: 600,
                      color: "#000",
                      padding: 10,
                    }}
                  >
                    {item}
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default SimpleTables;
