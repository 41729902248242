import React from 'react'
import StripeText from "@assets/images/stripeText1.png"

export const PaymentMethods = () => {
    return (
        <>
            <h2 className='payment-stripe-header'>Payment Methods/ Payouts</h2>
            <p className='payment-stripe-text'>We’ll take you to  Stripe to manage and make payouts.</p>
            <a href="https://dashboard.stripe.com/dashboard" target="_blank" >
                <img src={StripeText} alt="Stripe" className='payment-methods-stripe ' />
            </a>
        </>
    )
}
