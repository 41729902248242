import { Button, Col, Popover, Row } from "antd";
import tableStyle from '../../assets/style/table/table.module.css'
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-arrow.svg";
import { useState } from "react";

interface props {
    setPageSize: (pageSize: number) => void;
    pageSize: number;
    total?: number;
    style?: any;
}
function TableRange({ setPageSize, pageSize, total, style }: props) {
    const Range = [10, 25, 50, 100]
    const [visible, setVisible] = useState(false);

    const handleButtonClick = () => {
        setVisible(true);
    };

    const handlePopoverClose = () => {
        setVisible(false);
    };
    const content = <Col span={24} className={tableStyle.rangeContainer}>
        {Range.map((item, index) =>
            <Button
                className={`${tableStyle.editBtn} ${tableStyle.rangeBtn}`}
                key={index}
                onClick={() => { setPageSize(item); setVisible(false) }}
            >
                {item}
            </Button>
        )}
    </Col>
    return (
        <Row style={{display:'flex',color:'#047EB1', ...style}}>
            Rows per page: 
            <Popover content={content} trigger="click" visible={visible}
                onVisibleChange={setVisible}
            >
                <Row align='middle' justify='center' style={{cursor: 'pointer'}}>
                    {pageSize}
                    <DropdownIcon style={{ margin: '0.5rem'}} />
                </Row>
            </Popover>
        </Row>
    )
}

export default TableRange;