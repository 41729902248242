import {
  takeLatest,
  call,
  put,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken, getCompanyID, getChapterID } from "../auth/localData";
import { Error, Success } from "../constant/toast/Toast";
import { history } from "@config/routes";


function* fetchEventControls({ payload }: any): Generator<any> {
  try {
    const { tournamentId } = payload;
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.EVENT_CONTROLS}/${tournamentId}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}


function* fetchScoringControl({ payload }: any): Generator<any> {
  try {
    const { roundId } = payload;
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ROUNDS}/${roundId}/${ApiEndpoints.SHOW_SCORING_CONTROL}`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_SCORING_CONTROL}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* rewardDistribution({ payload }: any): Generator<any> {
  try {
    const { tournamentId, ...rest } = payload;
    rest.access_token = getToken();

    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${tournamentId}/distribute_rewards`,
      { ...rest }
      );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.REQUEST}`,
        payload: {
          tournamentId,
        }
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.REWARD_DISTRIBUTION}_${SagaActionType.SUCCESS}`,
      payload: {},
    });
  }
}

function* endTournament({ payload }: any): Generator<any> {
  try {
    const { tournamentId, ...rest } = payload;
    rest.access_token = getToken();

    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.EVENT_CONTROLS}/${tournamentId}/end_tournament`,
      { ...rest }
      );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.REQUEST}`,
        payload: {
          tournamentId,
        }
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.END_TOURNAMENT}_${SagaActionType.SUCCESS}`,
      payload: {},
    });
  }
}

function* FetchEventcontrolsRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_EVENT_CONTROLS}_${SagaActionType.REQUEST}`,
    fetchEventControls
  );
}

function* FetchScoringControlRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_SCORING_CONTROL}_${SagaActionType.REQUEST}`,
    fetchScoringControl
  );
}

function* RewardDistributionRequest() {
  yield takeLatest(
    `${SagaActions.REWARD_DISTRIBUTION}_${SagaActionType.REQUEST}`,
    rewardDistribution
  );
}

function* EndTournamentRequest() {
  yield takeLatest(
    `${SagaActions.END_TOURNAMENT}_${SagaActionType.REQUEST}`,
    endTournament
  );
}


export default function* rootEventControls() {
  yield spawn(FetchEventcontrolsRequest);
  yield spawn(FetchScoringControlRequest);
  yield spawn(RewardDistributionRequest);
  yield spawn(EndTournamentRequest);
}