import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";


const initialState: any = {
  owner_list: {},
  chapter_list: [],
  chapter_tournament_list: [],
  success: false,
  course_list: {},
  staff_list: [],
  director_list: [],
  create_success: false,
  chapterInfoData: {},
  isChapterInfoLoading: false,
  isLoading: false,
  isActiveLoading: false,
  page: 1,
  hasMore: false,
  all_chapter_list: [],
  total:0,
  isChapterListView:true,
};
export const chapterSlice = createSlice({
  name: "chapter",
  initialState,
  reducers: {
    setIsChapterListView: (state, action) => {
      state.isChapterListView = action.payload
  },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CHAPTER_OWNER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          owner_list: action.payload.data,

        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CHAPTER_CREATE}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          create_success: true,

        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.COURSES_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          course_list: action.payload.data,

        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CHAPTER_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          chapter_list: action.payload.data,
          success: true

        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.STAFF_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          staff_list: action.payload.data,
          success: true

        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DIRECTOR_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          director_list: action.payload.data,
          success: true

        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_CHAPTER}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isChapterInfoLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_CHAPTER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isChapterInfoLoading: false,
          chapterInfoData: action.payload.data,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_CHAPTER}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isChapterInfoLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_CHAPTER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isChapterInfoLoading: false,
          chapterInfoData: action.payload.data,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_CHAPTER_LIST}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
          page: action.payload.page,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          chapter_tournament_list: action.payload.data,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_CHAPTER_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { data, page, total_count }:any = action.payload
        const hasMore = (page * 10) < total_count
        let newData = page === 1 ? data : [...state?.all_chapter_list, ...data]

        return {
            ...state,
            isLoading: false,
            hasMore: hasMore,
            page: page,
            all_chapter_list:state.isChapterListView ?  data : newData,
            total:total_count
        };
    }
    );

  },
})

export const { setIsChapterListView } = chapterSlice.actions;

export default chapterSlice.reducer;  