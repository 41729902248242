import { Card, Statistic } from 'antd'

type StatisticCard = {
  title:string;
  value:number;
}

const StatisticCard = ({title,value}:StatisticCard) => {
  return (
    <Card bordered={false}>
    <Statistic
      title={title}
      value={value}
      precision={2}
      prefix={"$"}
    />
  </Card>
  )
}

export default StatisticCard;