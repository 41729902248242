import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectedSponsorsState {
  sponsorIds: number[]; // Array to store selected sponsor IDs
  isLoading: boolean;
  tempSponsors: string[];
  deletedSponsorIds:number[];
}

const initialState: SelectedSponsorsState = {
  sponsorIds: [],
  tempSponsors: [],
  isLoading: false,
  deletedSponsorIds:[],
};

const selectedSponsorsSlice = createSlice({
  name: "selectedSponsorIds",
  initialState,
  reducers: {
    setSelectedSponsorIds: (state, action: PayloadAction<number[]>) => {
      state.sponsorIds = action.payload;
    },
    setSelectedTempSponsors: (state, action: PayloadAction<string[]>) => {
      state.tempSponsors = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDeletedSponIds: (state, action) => {
      state.deletedSponsorIds = action.payload;
    },
  },
});

export const { setSelectedSponsorIds, setLoading,setSelectedTempSponsors,setDeletedSponIds } = selectedSponsorsSlice.actions;
export default selectedSponsorsSlice.reducer;