import { Button, Checkbox, Drawer, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SagaActionType, SagaActions } from '@sagas/index';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ReactComponent as MenuIcon } from "@assets/icons/MenuUnfoldIcon.svg";
import { ReactComponent as DndIcon } from "@assets/icons/dndIcon.svg";


const { Text } = Typography;

interface ColumnOption {
  label?: any;
  column_name: string;
  position: number;
}

interface ManageColumnsProps {
  ID: string;
  roundId:any
 }

const ManageColumns: React.FC<ManageColumnsProps> = ({ID,roundId}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [colPayload, setColPayload] = useState<ColumnOption[]>([]);
  const dispatch = useDispatch();

  const colOptions: ColumnOption[] = [
    { label: 'NAME', column_name: 'name', position: 1 },
    { label: 'HCP', column_name: 'hcp_value', position: 2 },
    { label: 'DIVISION', column_name: 'division_name', position: 3 },
    { label: 'AGE', column_name: 'age', position: 4 },
    { label: 'GENDER', column_name: 'gender', position: 5 },
    { label: 'PHONE', column_name: 'primary_phone1', position: 6 },
    { label: 'EMAIL', column_name: 'email', position: 7 },
    { label: 'TAGS', column_name: 'tags', position: 8 },
  ];

  //For storing the checked list options even after refresh of the page
  useEffect(() => {
    const storedCheckedList = localStorage.getItem('checkedList');
    const storedColPayload = localStorage.getItem('colPayload');

    if (storedCheckedList && storedColPayload) {
      setCheckedList(JSON.parse(storedCheckedList));
      setColPayload(JSON.parse(storedColPayload));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('checkedList', JSON.stringify(checkedList));
    localStorage.setItem('colPayload', JSON.stringify(colPayload));
  }, [checkedList, colPayload]);

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const updatedPayload: ColumnOption[] = colOptions
      .filter((col) => checkedValues.includes(col.label))
      .map((col) => ({
        column_name: col.column_name as string,
        position: col.position,
      }));

    setCheckedList(checkedValues as string[]);
    setIndeterminate(!!checkedValues.length && checkedValues.length < colOptions.length);
    setCheckAll(checkedValues.length === colOptions.length);
    setColPayload(updatedPayload);
  };


  const handleApply = async (e: any) => {
    let columnNames;
    if (colPayload.length === 0) {
      columnNames = [
          { column_name: 'name', position: 1 },
          { column_name: 'hcp_value', position: 2 },
          { column_name: 'division_name', position: 3 },
        ]
      
    } else {
      const updatedColPayload = [...colPayload];
      const defaultColumns = ['name', 'hcp_value', 'division_name'];

      for (const col of defaultColumns) {
        if (!updatedColPayload.find((c) => c.column_name === col)) {
          updatedColPayload.push({ column_name: col, position: 0 });
        }
      }

      columnNames = JSON.parse(JSON.stringify(updatedColPayload));
      
    }

    dispatch({
      type: `${SagaActions.MANAGE_PLAYERS_COLUMNS}_${SagaActionType.REQUEST}`,
      payload:{
        ID,
        column_names:columnNames,
        tournament_round_id:roundId 
      },
    });

    

    handleCloseDrawer();
  };



  const handleReset = () => {
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
    setColPayload([]);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <div className="player-col-filter-menu" onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </div>
      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text><b>Manage Columns</b></Text>
            <Text
              onClick={handleCloseDrawer}
              style={{ color: '#999999', cursor: 'pointer' }}
            >
              X
            </Text>
          </div>
        }
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        closable={false}
      >
        <div className="manage-columns-container">
          <Text>Manage and Customize your table columns</Text>
          <Checkbox
            indeterminate={indeterminate}
            onChange={(e) => {
              setCheckedList(e.target.checked ? colOptions.map((col) => col.label) : []);
              setIndeterminate(false);
              setCheckAll(e.target.checked);
              setColPayload(e.target.checked ? colOptions : []);
            }}
            checked={checkAll}
            className='mt-8'
          >
            SELECT/UNSELECT ALL
          </Checkbox>

          <Checkbox.Group
            options={colOptions.map((col) => ({
              label: (
                <span>
                  <DndIcon style={{ position: 'relative', left: -45, width: 12 }} />
                  {col.label}
                </span>
              ),
              value: col.label,
              disabled: col.label === 'NAME' || col.label === 'HCP' || col.label === 'DIVISION',
            }))}
            value={checkedList}
            onChange={handleCheckboxChange}
          />

          <div className='mt-4 btns'>
            <Button type="text" onClick={handleReset}>
              Reset
            </Button>
            <Button type="primary" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ManageColumns;
