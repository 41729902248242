import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error } from "@constant/toast/Toast";

function* fetchTournamentsList({ payload = {} }: any): Generator<any> {
    try {
        payload.access_token = getToken()
        let response: any = yield call(gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}`,
            { params: payload });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.TOURNAMENTS_LIST}_${SagaActionType.SUCCESS}`,
                payload: { data: response?.data?.data, total_count: response?.data?.total_count, page: payload.page },
            });
        }
    }
    catch (error: any) {
        yield call(Error, { description: error?.data?.message });
      }

}

function* fetchTournamentsListRequest() {
    yield takeLatest(
        `${SagaActions.TOURNAMENTS_LIST}_${SagaActionType.REQUEST}`,
        fetchTournamentsList
    );

}

export default function* rootcreateEvent() {
    yield spawn(fetchTournamentsListRequest);
}