import { Col, Row, Spin } from "antd";
import { PairingBtnLayout } from "./Component";
import { SwapOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { SagaActionType, SagaActions } from "@sagas/index";
interface PairingButtonsProps {
    setColumn: (column: any) => void;
    swapTableViewFun: () => void;
    scrollToEndOfSheet: () => void;
    ID: number | undefined
    isLoading: boolean
    column:number
  }
  
  const PairingButtons: React.FC<PairingButtonsProps> = ({ column, isLoading, ID, setColumn, swapTableViewFun, scrollToEndOfSheet }) => {
    const dispatch = useDispatch()
    const RemoveColumnFun = ():void=>{
        if(!isLoading && column > 1) {
            scrollToEndOfSheet()
            dispatch({
                type:`${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.REQUEST}`,
                payload:{ id: ID, player_column_number: column - 1}
            })
            setColumn((prev:number) => prev - 1)
        }
    }
    return (
        <Col style={{ width: '80px' }}>
            <Row>
                <div className="m-auto text-skyBlue">
                    <PairingBtnLayout className='mt-10' handleClick={() => { setColumn((prev: any) => prev + 1); scrollToEndOfSheet() }}>+</PairingBtnLayout>
                    <PairingBtnLayout handleClick={RemoveColumnFun}>{isLoading ? <Spin/>: '-'}</PairingBtnLayout>
                    <PairingBtnLayout className='mt-10 pairing-action-row-btn-swap' handleClick={swapTableViewFun}>
                        <SwapOutlined style={{ width: 20 }} />
                    </PairingBtnLayout>
                </div>
            </Row>
        </Col>
    );
}

export default PairingButtons;