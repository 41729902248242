import { useAppSelector } from '@utils/reduxHooks';
import React, { Fragment } from 'react';
import "./styles.css";


const PayoutMatrixTable = () => {
    const { PrizesAwardsCardData } = useAppSelector((state: any) => state.prizeAwards);

    const maxlength = PrizesAwardsCardData?.max_position;
    const fieldSize = PrizesAwardsCardData?.max_fields;
    const headerCells = [];
    for (let i = 1; i <= maxlength; i++) {
        headerCells.push(<th key={`header-${i}`}>{i}</th>);
    }

    const rows = [];
    for (let i = 1; i <= fieldSize; i++) {
        const rowCells = [];
        for (let j = 1; j <= maxlength; j++) {

            const value = PrizesAwardsCardData?.payout_matrix_distributions.find((entry: { field: number; position: number; }) => entry.field === i && entry.position === j)?.value;
            rowCells.push(
                <td className='payout-matrix-table-cell' key={`cell-${i}-${j}`}>
                    {value}
                    {value && PrizesAwardsCardData?.distribution_type.toLowerCase().includes('percentage') ? "%" : ""}
                </td>
            );
        }
        rows.push(
            <tr key={`row-${i}`}>
                <td>{i}</td>
                {rowCells}
            </tr>
        );
    }
    return (
        <table className='payout-matrix-table' >
            <thead>
                <tr >
                    <th colSpan={1} style={{ border: "none" }}></th>
                    <th colSpan={maxlength} className="table-header-cell">
                        Number Distribution By Positions
                    </th>
                </tr>
                <tr>
                    <th rowSpan={maxlength} className="payout-matrix-table-curve">Field Size</th>
                    {headerCells}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

export default PayoutMatrixTable;
