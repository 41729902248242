import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface createContestLibraryInterface {
    data: any[]; // player data storage
    isLoading: boolean; // loader
    page: number; // page number
    hasMore: boolean; // has more data
}
const initialState: createContestLibraryInterface = {
    data: [],
    isLoading: false,
    page: 1,
    hasMore: false,
};
export const createContestLibrarySlice = createSlice({
    name: "contestLibrary",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.CREATE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        (state, action) => {
          return {
              ...state,
              isLoading: false,
              // data: action.payload.data,

          };;
        }
      );
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.CREATE_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
        (state, action) => {
          return {
              ...state,
              isLoading: true,
          };;
        }
      );
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        (state, action) => {
          const { data, page, total_count } = action.payload
          const hasMore = (page * 10) < total_count
          let newData = page === 1 ? data : [...state.data, ...data]
          return {
              ...state,
              isLoading: false,
              hasMore: hasMore,
              page: page,
              data: newData,
          };;
        }
      );
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
        (state, action) => {
          return {
              ...state,
              isLoading: true,
          };;
        }
      );
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.DELETE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        (state, action) => {
          const { id } = action.payload
          const newArray = state.data.filter((item: any) => item.id !== id)
          return {
            ...state,
            data: newArray,
          };
        }
      );
      },
})

export default createContestLibrarySlice.reducer;  