import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface notificationInterface {
  notificationList: any[];
  notificationTable: any[];
  isLoading: boolean; // loader
  total: number | undefined; // number of results
  page: number; // current page
  isupdating: boolean; // loader
  notificationCount: any;
}
const initialState: notificationInterface = {
  notificationList: [],
  notificationTable: [],
  isLoading: false,
  total: undefined,
  page: 1,
  isupdating: false,
  notificationCount: localStorage.getItem("notificationCount"),
};
export const notificationInfoSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { page, data, total } = action.payload;
        const array: any = page === 1 ? data : [...state.notificationList, ...data];
        localStorage.setItem('notificationCount', total);
        return {
          ...state,
          isLoading: false,
          notificationList: array,
          total: total,
          page: page,
          notificationCount: total,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isupdating: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { data } = action.payload;
        const array: any = (data?.id) ? (state.notificationTable).map((item: any) => item.id == data?.id ? { ...item, status: data?.status } : item) : [...state.notificationTable];
        const newArray = (data?.id) ? state.notificationList.filter((item: any) => item.id !== data?.id) : [...state.notificationList];
        return {
          ...state,
          isupdating: false,
          notificationList: newArray,
          notificationTable: array,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_NOTIFICATIONS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { data, total } = action.payload;
        return {
          ...state,
          isLoading: false,
          notificationTable: data,
          total: total,
        };
      }
    );
  },
})

export default notificationInfoSlice.reducer;  