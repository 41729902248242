import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';

function* fetchRegPlayersStats({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/checkin_header_info`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_REG_PLAYERS_STATS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchRegisteredPlayers({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/${ApiEndpoints.CHECKIN_PLAYERS}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
          page: response.data.page,
          total: response.data.total_count
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchCheckInCSV({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/download_checkin_players_csv`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.CHECKIN_CSV}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchRegPlayerInfo({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/show_checkin_rounds`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.REGISTERED_PLAYER_INFO}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* createCheckIn({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHECKINS}`,
      { ...payload },
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_CHECKIN}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the players Table after creating the checkin
      yield put({
        type: `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.REQUEST}`,
        payload:{
          id:payload.tournament_id
        }
      });

      yield put({
        type: `${SagaActions.FETCH_REG_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
        payload:{
          id:payload.tournament_id
        }
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_CHECKIN}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* viewCheckIn({ payload}: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.CHECKINS
      }/${payload.id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.VIEW_CHECKIN}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  }catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* updateCheckIn({ payload,id }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CHECKINS}/${id}`,
      { ...payload }    
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.USERS_UPDATE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
       //To reload the players Table after creating the checkin
       yield put({
        type: `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.REQUEST}`,
        payload:{
          id:payload.tournament_id
        }
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}














function* RegPlayersStatsRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_REG_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
    fetchRegPlayersStats
  );
}

function* RegisteredPlayersRequest() {
  yield takeLatest(
    `${SagaActions.REGISTERED_PLAYERS_LIST}_${SagaActionType.REQUEST}`,
    fetchRegisteredPlayers
  );
}

function* CheckInCSVRequest() {
  yield takeLatest(
    `${SagaActions.CHECKIN_CSV}_${SagaActionType.REQUEST}`,
    fetchCheckInCSV
  );
}

function* RegisteredPlayerInfoRequest() {
  yield takeLatest(
    `${SagaActions.REGISTERED_PLAYER_INFO}_${SagaActionType.REQUEST}`,
    fetchRegPlayerInfo
  );
}


function* CreateCheckInRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_CHECKIN}_${SagaActionType.REQUEST}`,
    createCheckIn
  );
}

function* ViewCheckInRequest() {
  yield takeLatest(
    `${SagaActions.VIEW_CHECKIN}_${SagaActionType.REQUEST}`,
    viewCheckIn
  );
}

function* UpdateCheckInRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_CHECKIN}_${SagaActionType.REQUEST}`,
    updateCheckIn
  );
}








export default function* rootCheckIn() {
  yield spawn(RegPlayersStatsRequest);
  yield spawn(RegisteredPlayersRequest);
  yield spawn(CheckInCSVRequest);
  yield spawn(RegisteredPlayerInfoRequest);
  yield spawn(CreateCheckInRequest);
  yield spawn(ViewCheckInRequest);
  yield spawn(UpdateCheckInRequest);
}
