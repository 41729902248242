import { takeLatest, call, put, spawn } from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from "../constant/toast/Toast";
import { history } from "@config/routes";

function* fetchPayoutMatrix({ payload = {} }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PRIZES_AWARDS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error) {
    // yield put(setPermissionData(error));
  }
}

function* fetchpayoutMatrixId({ payload = {} }: any): Generator<any> {
  try {
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}/${payload.id}`,
      { params: { access_token: getToken() } }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data },
      });
      yield call(Success, { description: response?.data?.message });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.message });
  }
}

function* createPayoutMatrix({ payload = {} }: any): Generator<any> {
  const { id, ...values } = payload;
  try {
    let response: any = id ?
      yield call(
        gcbAPI().put,
        `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}/${id}`,
        {
          access_token: getToken(),
          ...values
        }
      ) : yield call(
        gcbAPI().post,
        `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}`,
        {
          access_token: getToken(),
          ...values
        }
      );
    if (response?.data.success) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_PAYOUT_MATRIX}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
      history.push('/admin/prizes-awards');
    }
  } catch (error: any) {
    yield call(Error, { description: error?.message });
  }
}
function* updatePayoutMatrix({ payload = {} }: any): Generator<any> {
  try {
    const access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}/${payload.id}`,
      {
        access_token,
        ...payload
      }
    );
    if (response?.data.success) {
      yield call(Success, { description: response?.data?.message });
      history.push('/admin/prizes-awards');
    }
  } catch (error: any) {
    yield call(Error, { description: error?.message });
  }
}
function* deletePayoutMatrix({ payload = {} }: any): Generator<any> {
  try {
    let response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRIZES_AWARDS}/${payload.id}`,
      { params: { access_token: getToken() } }
    );
    if (response?.data.success) {
      yield put({
        type: `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: { id: payload.id },
        },
      });
      yield call(Success, { description: response?.data?.message });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.message });
    yield put({
      type: `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.SUCCESS}`,
      payload: { data: { id: null } },
    });
  }
}

function* payoutMatrixRequest() {
  yield takeLatest(
    `${SagaActions.PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
    fetchPayoutMatrix
  );
}

function* payoutMatrixIdRequest() {
  yield takeLatest(
    `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.REQUEST}`,
    fetchpayoutMatrixId
  );
}

function* createPayoutMatrixRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_PAYOUT_MATRIX}_${SagaActionType.REQUEST}`,
    createPayoutMatrix
  );
}
function* updatePayoutMatrixRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
    updatePayoutMatrix
  );
}
function* deletePayoutMatrixRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
    deletePayoutMatrix
  );
}

export default function* rootprizesAwardsManagement() {
  yield spawn(payoutMatrixRequest);
  yield spawn(payoutMatrixIdRequest);
  yield spawn(createPayoutMatrixRequest);
  yield spawn(updatePayoutMatrixRequest);
  yield spawn(deletePayoutMatrixRequest);
}
