import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface schedulessInterface {
    defaultData: any[]; // player data storage
    isLoading: boolean; // loader
    isUpdating: boolean; // loader
}
const initialState: schedulessInterface = {
    defaultData: [],
    isLoading: true,
    isUpdating: false,
};
export const schedulesSlice = createSlice({
    name: "schedules",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.SET_SCHEDULES}_${SagaActionType.SUCCESS}`,
          (state, action) => {
            return {
                ...state,
                isLoading: false,
                defaultData: action.payload.data,
            };;
          }
        );
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.SET_SCHEDULES}_${SagaActionType.REQUEST}`,
          (state, action) => {
            return {
                ...state,
                isLoading: true,
            };;
          }
        );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  isUpdating: true,
              };;
            }
          );
          builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  isUpdating: false,
              };;
            }
          );
          builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.UPDATE_SCHEDULES}_${SagaActionType.FAIL}`,
            (state, action) => {
              return {
                  ...state,
                  isUpdating: false,
              };;
            }
          );
      },
})

export default schedulesSlice.reducer;  