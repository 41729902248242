export const sortArrayFun = (key, data) => {
     data.sort((a, b) => {
        if(a[key] < b[key]) { return -1; }
        if(a[key] > b[key]) { return 1; }
        return 0;
    });
    return data
}

export const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
}