import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "@sagas/index";
// import chargersReducer from "@slices/chargersSlice";
// import loadingReducer from "@slices/loadingSlice";
// import errorReducer from "@slices/errorSlice";
import userInfoSlice from "@slices/userInfoSlice";
import permissionSlice from "@slices/permissionSlice";
import playersInfoSlice from "@slices/playerSlice";
import authSlice from "@slices/authSlice";
import membershipSlice from "@slices/membershipSlice";
import chapterSlice from "@slices/chapterSlice";
import seasonSlice from "@slices/seasonSlice";
import sponsorSlice from "@slices/sponsorSlice";
import userManagementSlice from "@slices/userManagementSlice";
import StatesListSlice from "@slices/StatesListSlice";
import createTournamentSlice from "@slices/CreateTournament";
import contestLibrary from "@slices/createContest";
import allPlayersSlice from "@slices/allPlayersSlice";
import flightsDivisionsSlice from "@slices/FlightsDivisionsSlice";
import pairingPlayerSlice from "@slices/pairingPlayerSlice";
import IgolfSlice from "@slices/IgolfSlice";
import selectedSponsorsReducer from '@slices/selectedSponsorsSlice';
import SideGameslice from "@slices/sideGamesSlice";
import CoursesSlice from "@slices/coursesSlice";
import PrintingsSlice from "@slices/PrintingsSlice";
import PrizeAwardsSlice from "@slices/PrizeAwardsSlice";
import notificationInfoReducer from "@slices/notificationSlice";
import RegisteredPlayersSlice from "@slices/registeredPlayersSlice";
import TournamentsListSlice from "@slices/TournamentsListSlice";
import schedulesSlice from "@slices/schedulesSlice";
import scoringRoundSlice from "@slices/scoringRoundSlice";
import CheckInSlice from "@slices/CheckInSlice";
import LeaderboardSlice from "@slices/LeaderboardSlice";
import AccountingsSlice from '@slices/AccountingsSlice';
import EventControlsSlice from "@slices/EventControlsSlice";
import MarketingsSlice from "@sagas/marketingsSaga";

let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    userInfo: userInfoSlice,
    permissionInfo: permissionSlice,
    player: playersInfoSlice,
    auth: authSlice,
    membership: membershipSlice,
    chapter: chapterSlice,
    season: seasonSlice,
    sponsor: sponsorSlice,
    userManagement: userManagementSlice,
    commonList: StatesListSlice,
    createTournament: createTournamentSlice,
    contestLibrary: contestLibrary,
    allPlayers: allPlayersSlice,
    pairingPlayer: pairingPlayerSlice,
    IgolfData: IgolfSlice,
    courses: CoursesSlice,
    flightsDivisions: flightsDivisionsSlice,
    printings: PrintingsSlice,
    selectedSponsors: selectedSponsorsReducer,
    sideGames: SideGameslice,
    prizeAwards: PrizeAwardsSlice,
    notification: notificationInfoReducer,
    registeredPlayers: RegisteredPlayersSlice,
    tournamentsList: TournamentsListSlice,
    schedules: schedulesSlice,
    scoringRound: scoringRoundSlice,
    checkIn:CheckInSlice,
    leaderboards:LeaderboardSlice,
    accounting: AccountingsSlice,
    EventControls: EventControlsSlice,
    Marketing: MarketingsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({

    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
