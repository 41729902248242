import { Tooltip, Tag, Checkbox, TableProps } from "antd";
import { Table } from "antd";
import type { ColumnsType, FilterValue } from "antd/lib/table/interface";
import { ReactComponent as NewSearch } from "@assets/icons/newSearch.svg";
import React, { useEffect, useMemo, useState, ChangeEvent } from "react";
import "./pairingsStyle.css";
import { useDispatch } from "react-redux";
import { SagaActionType, SagaActions } from "@sagas/index";
import '../../admin/flightsDivisions/styles.css'
import TableRange from "@components/dropdowns/TableRange";
import ManageColumns from "./ManageColumns";
import { MoreOutlined } from "@ant-design/icons";
import { Draggable, Droppable } from "react-beautiful-dnd";
import PlayerInfoToolTip from "./PlayerInfoToolTip";
import { useLocation } from "react-router-dom";


interface DataType {
  key: any;
  id: string;
  gender: string;
  age: number;
  hcp_value: number;
  name: string;
  division_name: string;
  tags: string[];
  email: string
}
type Player = {
  id: string;
  name: string;
};

type AllPlayersProps = {
  allPlayersState: {
    PlayersData: DataType[];
    isLoading: boolean;
  };
  selectedInPlayerFun: (e: any) => void;
  selectedInPlayer: any;
  ID: any;
  ROUND_ID: any;
};

const AllPlayers: React.FC<AllPlayersProps> = ({ ROUND_ID, ID, allPlayersState, selectedInPlayerFun, selectedInPlayer }) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataType[]>(allPlayersState.PlayersData);
  const [page, setPage] = useState<number>(1);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const searchFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
    const dataFilter = allPlayersState.PlayersData.filter(
      (entry) =>
        entry?.name?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        entry?.email?.toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    setFilteredData(dataFilter);
    setPage(1);
  };

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
  };

  const uniqueDivisons = new Set(allPlayersState.PlayersData?.map((data: any) => data.division_name));

  const divisionsArr = Array.from(uniqueDivisons).map((division: any) => ({
    text: division,
    value: division
  }));


  const columns: ColumnsType<DataType> = useMemo(() => {
    const columnKeys = Array.from(new Set(allPlayersState.PlayersData.flatMap((data: any) => Object.keys(data))));
    const excludedColumns = new Set(["id", "player_id", "inner_color", "outer_color"]);
    const filteredColumnKeys = columnKeys.filter(key => !excludedColumns.has(key));


    // Find the index of the "name" key
    const nameIndex = filteredColumnKeys.indexOf("name");

    // After filtering columns in the manage columns for 'Name' 
    //to be appeared in always in 1st column 
    if (nameIndex !== -1) {
      filteredColumnKeys.splice(nameIndex, 1);
      filteredColumnKeys.unshift("name");
    }

    const dynamicColumns: ColumnsType<DataType> = filteredColumnKeys.map((key) => {
      let columnTitle: string;

      switch (key) {
        case "division_name":
          columnTitle = "Division";
          break;
        case "hcp_value":
          columnTitle = "HCP";
          break;
        case "age":
          columnTitle = "Age";
          break;
        case "gender":
          columnTitle = "Gender";
          break;
        case "name":
          columnTitle = "Name";
          break;
        case "tags":
          columnTitle = "Tags";
          break;
        case "primary_phone1":
          columnTitle = "Phone";
          break;
        case "email":
          columnTitle = "Email";
          break;
        default:
          columnTitle = key;
      }

      if (key === 'name') {
        return {
          title: columnTitle,
          dataIndex: key,
          key: key,
          ellipsis: true,
          width: '150px',
          sorter: (a, b) => b.name.localeCompare(a.name),
          render: (name: string, data: any, index) => {
            return (
              <div className="flex-center name-column">
                <MoreOutlined className="p-abs" style={{ left: 0 }} />
                <Checkbox className="p-abs" style={{ left: '.75rem' }} onChange={selectedInPlayerFun} checked={selectedInPlayer[data?.id]} name={data?.id} />
                <Draggable key={String(data.id)} draggableId={data.id + '0' + index} index={data.id}>
                  {(provided: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      id={'PlayerView-' + data.id}
                      className="cursor-grab h-50 w-220 flex-center w-100 pt-1"
                    >
                      {name}
                    </div>
                  )}
                </Draggable>
                <PlayerInfoToolTip id={data?.id} />
              </div>
            )
          },
        };
      }

      if (key === "division_name") {
        return {
          title: columnTitle,
          dataIndex: key,
          key: key,
          ellipsis: true,
          render: (division: string, data: any) =>
          (division ?
            <div style={{
              color: data?.inner_color,
              border: `1px solid ${data?.inner_color}`,
              background: data?.inner_color + '30',
              width: 100,
              padding: 3
            }}
              className="text-bold b-r-5"
            >{division}
            </div>
            :
            <>-</>),
          filters: divisionsArr,
          filterMode: "tree",
          onFilter: (value: any, record) => record.division_name.includes(value),
          //filterSearch: true,
          filteredValue: filteredInfo.division_name || null,
        };
      }

      if (key === 'tags') {
        return {
          title: columnTitle,
          dataIndex: key,
          key: key,
          ellipsis: true,
          render: (tagsArr: any[]) => {
            if (tagsArr && tagsArr.length >= 1) {
              const displayedTags = tagsArr.slice(0, 2);
              const extraTags = tagsArr.slice(2);

              return (
                <>
                  {displayedTags.map((tag: any, index: number) => (
                    <div className="mb-1" key={index}><Tag color={index === 0 ? '#F28F1B' : '#1462BF'}>{tag.name}</Tag></div>
                  ))}
                  {extraTags.length > 0 && (
                    <Tooltip title={extraTags.map((tag: any) => <div key={tag.id}>{tag.name}</div>)}>
                      <div style={{ textDecoration: 'underline' }}>+{extraTags.length} Tags</div>
                    </Tooltip>
                  )}
                </>
              );
            }
          },
        };
      }

      return {
        title: columnTitle,
        dataIndex: key,
        key: key,
        ellipsis: true,
        sorter: key === 'primary_phone1'
          ? false
          : (a, b) => {
            switch (key) {
              case 'hcp_value':
              case 'age':
                return a[key] - b[key];
              case 'email':
              case 'gender':
                return (b[key] || '').localeCompare(a[key] || '');
              default:
                return 0; // Return 0 for equal values (no sorting)
            }
          }
      };

    });

    return dynamicColumns;
  }, [allPlayersState.PlayersData, selectedInPlayer, filteredInfo.division_name]);

  useEffect(() => {
    if (ID)
      dispatch({
        type: `${SagaActions.ALL_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
        payload: { id: ID, tournament_round_id: ROUND_ID }
      });
  }, [ID]);

  return (
    <>
      <div className="players-header p-4">
        <div className="search" >
          <input type="text" className="search__input" value={userInput} aria-label="search" placeholder="Search" onChange={searchFilter} />
          <button className="search__submit" aria-label="submit search"><NewSearch /></button>
        </div>
        <ManageColumns ID={ID} roundId={ROUND_ID} />
      </div>
      <Droppable droppableId={'allPlayers'} direction='horizontal' isDropDisabled={true}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            <Table
              columns={columns}
              dataSource={userInput === '' ? allPlayersState.PlayersData : filteredData}
              loading={allPlayersState.isLoading}
              rowClassName={() => "editable-row"}
              scroll={{ x: '100%' }}
              rowKey={'id'}
              onChange={handleChange}
              pagination={{
                current: page,
                size: 'small',
                onChange: (page, pageSize) => {
                  setPage(page);
                },
                total: allPlayersState.PlayersData.length || 0,
                pageSize,
                showTotal: (total, range) => {
                  return (total > 10 &&
                    <TableRange
                      pageSize={pageSize}
                      setPageSize={(pageSize) => { setPageSize(pageSize); setPage(1) }}
                      total={total}
                    />
                  );
                },
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                  setPage(1);
                },
                className: 'allPlayersPagination',
              }}
            />
          </div>
        )}
      </Droppable>
    </>
  );
};

export default AllPlayers;
