import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface seasonsInterface {
    data: any[]; // player data storage
    isLoading: boolean; // loader
    total:number; // total count for pagination
  active_seasons_list: any[];
}
const initialState: seasonsInterface = {
    data: [],
    isLoading: true,
    total: 0,
  active_seasons_list: [],
};
export const seasonSlice = createSlice({
    name: "season",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.SEASONS}_${SagaActionType.SUCCESS}`,
          (state, action) => {
            return {
                ...state,
                isLoading: false,
                total:action.payload.total,
                data: action.payload.data,

            };;
          }
        );
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.SEASONS}_${SagaActionType.REQUEST}`,
          (state, action) => {
            return {
                ...state,
                isLoading: true,
            };;
          }
        );
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.DELETE_SEASONS}_${SagaActionType.REQUEST}`,
          (state, action) => {
            return {
                ...state,
            };
          }
        );

      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.ACTIVE_SEASONS}_${SagaActionType.SUCCESS}`,
        (state, action) => {
          return {
            ...state,
            isLoading: false,
            active_seasons_list: action.payload.data,
          };;
        }
      );
      builder.addMatcher(
        (action) =>
          action.type ===
          `${SagaActions.ACTIVE_SEASONS}_${SagaActionType.REQUEST}`,
        (state, action) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      );
      },
})

export default seasonSlice.reducer;  