import { Col, Row, Spin } from 'antd'
import AccountingPieChart from '../Charts/AccountingPieChart'
import StatisticCard from './StatisticCard'
import '../AccountingStyle.css'
import Balance from '../Balance'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SagaActionType, SagaActions } from '@sagas/index'
import { useParams } from 'react-router-dom'
import { URL_BINDER } from '@constant/url'
import { useAppSelector } from '@utils/reduxHooks'

interface ProfitAndLossProps {
  dateRange?: any
  filter?: any
}

const ProfitAndLoss = ({ dateRange, filter = {} }: ProfitAndLossProps) => {
  const dispatch = useDispatch();
  const { TOURNAMENT } = useParams();
  const [tempNAME, ID] = TOURNAMENT?.split(URL_BINDER) || [];
  const { isProfitAndLossLoading, ProfitAndLoss, istournamentAccountingsLoading, isMainAccountingsLoading } = useAppSelector((state: any) => state.accounting);
  const isTournament = ID ? true : false;

  useEffect(() => {
    if (isTournament && (!istournamentAccountingsLoading || !isMainAccountingsLoading) && !isProfitAndLossLoading) {
      const payload: any = {
        id: ID,
      }
      if (dateRange[0] && dateRange[1]) {
        payload.start_date = new Date(dateRange[0]);
        payload.end_date = new Date(dateRange[1]);
      }
      dispatch({
        type: `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
        payload: {
          id: ID,
          ...payload,
        },
      })
    }
  }, [isMainAccountingsLoading, istournamentAccountingsLoading]);


  return (<>
    {isProfitAndLossLoading ? <div style={{ textAlign: 'center', marginTop: 50 }} className='mb-20'><Spin /></div> :
      <Row gutter={16} className="profitLoss space-between mb-8">
        <Col className='m-auto'>
          <AccountingPieChart isTournament={isTournament} />
        </Col>
        <Col className='flex-end'>
          <Col >
            <StatisticCard
              title='Net Profit'
              value={ProfitAndLoss?.net_profit}
            />
          </Col>
          <Col >
            <StatisticCard
              title='Net Profit/ Player'
              value={ProfitAndLoss?.net_profit_per_player}
            />
          </Col>
        </Col>
      </Row>
    }
  </>
  )
}

export default ProfitAndLoss