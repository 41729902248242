import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface UsersInterface {
    UsersData: any[]; // User data storage
    isLoading: boolean; // loader
    total:number; // number of results
    UsersTag:any[]; // tags data storage
    deleteUserReq: boolean;
    updateUserReq: boolean;
    createUserReq: boolean;
    UserInfo:any;
    isUserInfoLoading:boolean;
}
// create index with pageNumber + filterType + searchStrings =====> this will help to reduce api call
const initialState: UsersInterface = {
    UsersData: [],
    isLoading: true,
    total: 0,
    UsersTag: [],
    deleteUserReq: false,
    updateUserReq: false,
    createUserReq: false,
    UserInfo:[],
    isUserInfoLoading: true,
};
export const UsersInfoSlice = createSlice({
    name: "userManagement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.USERS_FETCH}_${SagaActionType.SUCCESS}`,
          (state, action) => {
            return {
                ...state,
                isLoading: false,
                total:action.payload.total,
                UsersData: action.payload.data,

            };;
          }
        );
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.USERS_FETCH}_${SagaActionType.REQUEST}`,
          (state, action) => {
            return {
                ...state,
                isLoading: true,
            };;
          }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_CREATE}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  createUserReq: true,
              };;
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_CREATE}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                const tagArray = state.UsersTag.filter((tag) => tag.id !== action.payload.id)
              return {
                  ...state,
                  createUserReq: false,
              };;
            }
        );

        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_DELETE}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  deleteUserReq: true,
              };;
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_DELETE}_${SagaActionType.SUCCESS}`,
            (state, action) => {
              return {
                  ...state,
                  deleteUserReq: false,
              };;
            }
        );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_UPDATE}_${SagaActionType.REQUEST}`,
            (state, action) => {
              return {
                  ...state,
                  updateUserReq: true,
              };;
            }
          );
        builder.addMatcher(
            (action) =>
              action.type ===
              `${SagaActions.USERS_UPDATE}_${SagaActionType.SUCCESS}`,
            (state, action) => {
                const tagArray = state.UsersTag.filter((tag) => tag.id !== action.payload.id)
              return {
                  ...state,
                  updateUserReq: false,
              };;
            }
        );

        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.USER_INFO_FETCH}_${SagaActionType.REQUEST}`,
          (state, action) => {
            return {
              ...state,
              isUserInfoLoading: true,
            };
          }
        );
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.USER_INFO_FETCH}_${SagaActionType.SUCCESS}`,
          (state, action) => {
            return {
              ...state,
              isUserInfoLoading: false,
              UserInfo: action.payload.data,
            };
          }
        );
      },
})

export default UsersInfoSlice.reducer;  