import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { ReactNode, memo, useCallback, useEffect, useState } from "react";

interface PairingBtnLayoutProps {
  children: ReactNode;
  style?: object;
  handleClick?: (e: any) => void;
  className?: string;
}

export const PairingBtnLayout: React.FC<PairingBtnLayoutProps> = ({ children, style, handleClick, className = '' }) =>
  <Col className={`b-skyBlue b-r-3 pairing-action-row-btn line-height1 mb-2 pointer hover-grow-span ${className}`} style={style} onClick={handleClick}>
    <span className="" >{children}</span>
  </Col>
interface UserInfoCardProps {
  deleteUser: (key: string) => void;
  data: { player_name: string, inner_color: string, outer_color:string };
  uniqKey: string;
  handleDND: (e: any) => void
}
export const UserInfoCard: React.FC<UserInfoCardProps> = ({ deleteUser, data, uniqKey, handleDND }) =>
(<Card className="w-90  p-rel pairing-user-card-info cursor-grab p-rel" id={uniqKey + 'CardView'} onClick={handleDND} style={{ border: `1px solid ${data?.inner_color ? data.inner_color : '#E3E3E3'}`, borderLeft: `8px solid ${data?.inner_color ? data.inner_color : '#E3E3E3'}` }}>
  <MoreOutlined className='p-abs pairing-user-info-btn' />
  <span className="text-cap">{data.player_name}</span>
  <CloseOutlined onClick={() => deleteUser(uniqKey)} className="p-abs pairing-user-remove-btn pointer-i" style={{ top: 6, right: 6, padding: 2 }} />
  <div className=' dnd-multidrag-view p-abs d-box-shadow' style={{}}></div>
</Card>)

interface AddCustomHolesProps {
  customHole: { count: string, value: string },
  setCustomHole: (value: any) => void
  AddCustomHolesFuc: () => void
  isTeeTime: boolean
  rowState: { format: string, count: number }
  rowData: {name:string, teeTime:string}[]
}
export const AddCustomHoles: React.FC<AddCustomHolesProps> = memo(({ rowData, rowState, isTeeTime, customHole, setCustomHole, AddCustomHolesFuc}) => {
  useEffect(() => {
    if (isTeeTime) {
      if (rowState.format === '1&10' || rowState.format === 'custom') {
        setCustomHole((prev: any) => { return { ...prev, count: '-' } })
      } else {
        setCustomHole((prev: any) => { return { ...prev, count: rowState.format } })
      }
    }
  }, [rowState, rowData])

  console.log(rowState.format, customHole.count, customHole.value)
  return (
    <Row className="pairing-sheet-add-hole-wrapper d-border p-rel">
      <p className="pairing-sheet-add-note">Add Custom Hole</p>
      {isTeeTime ?
        <Select style={{ display: (rowState.format === '1&10' || rowState.format === 'custom') ? '' : 'none' }} className="h-60p w-50p b-r-5 dropdown-align m-2" value={customHole.count} onChange={(val: any) => { setCustomHole((prev: any) => { return { ...prev, count: val } }) }}>
          <Option value="1">1</Option>
          <Option value="10">10</Option>
        </Select>
        :
        <>
          <Select className="h-60p w-50p b-r-5 dropdown-align m-2" value={customHole.count} onChange={(val: any) => { setCustomHole((prev: any) => { return { ...prev, count: val } }) }}>
            {
              Array(18).fill('').map((_, index) =>
                <Option value={String(index + 1)}>{(index + 1)}</Option>
              )}
          </Select>
          <Select className="h-60p w-80p b-r-5 dropdown-align m-2" value={customHole.value} onChange={(val: any) => { setCustomHole((prev: any) => { return { ...prev, value: val } }) }}>
            {(rowState.format === 'custom' || rowState.format === 'a' || rowState.format === 'ab') && <Option value="AB">AB</Option>}
            <Option value="ABC">ABC</Option>
          </Select>
        </>
      }
      <Button className="h-60p w-120 m-2 b-skyBlue b-r-5"
        style={{ opacity: (isTeeTime) ? (rowState.format === '1&10' || rowState.format === 'custom') ? customHole.count !== '-' ? 1 : .5 : 1 : (customHole.count !== '-' && customHole.value !== '--') ? 1 : .5 }}
        onClick={() => { if (isTeeTime) { if (!(rowState.format === '1&10')) { if(customHole.count !== '-')AddCustomHolesFuc() } else if (customHole.count !== '-') { AddCustomHolesFuc() } } else if (customHole.count !== '-' && customHole.value !== '--') { AddCustomHolesFuc() } }}>
        <b className="hover-grow text-skyBlue">+ Add</b>
      </Button>
    </Row>
  )
})
