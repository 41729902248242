import { Button, Col, Popover, Radio, Row } from "antd";
import { ReactComponent as EllipseIcon } from "@assets/icons/ellipse.svg";

interface SheetHeaderProps {
    setRowState: (updateFunc: (prev: any) => any) => void;
    setSortSheet: (updateFunc: (prev: any) => any) => void;
    sortSheet: string;
    isTeeTime: boolean;
    rowState: { count: number, format: string };
    showCustom: boolean;
    colorArray: { [key: string]: string };
}
const ACTION = ['Publish Pairings', 'Email Pairings', 'SMS Pairings', 'Download CSV', 'Download PDF']
const SheetHeader: React.FC<SheetHeaderProps> = ({ colorArray, isTeeTime, setRowState, rowState, setSortSheet, sortSheet, showCustom }) => {
    const ACTIVE_STYLE = 'bg-skyBlue text-w pairing-sheet-tee-time-btn text-bold';
    const NONACTIVE_STYLE = 'text-skyBlue pairing-sheet-tee-time-btn';

    const handleAction = (action_type: string) => {
        alert(action_type)
    }
    const CONTENT = (
        <Col className="p-2 ">
            {ACTION.map((action) => <Row key={action}><Button className="changes-button pairing-action-btn" key={action} onClick={() => handleAction(action)}><b>{action}</b></Button></Row>)}
        </Col>
    );
    const handleFormat = (format: string) => {
        setRowState((prev: any) => { return { ...prev, format } })
    }
    const handleSort = (sort: string) => {
        setSortSheet(() => sort)
    }
    return (<>
        <Row justify="space-between" align="middle" className="w-100 mb-4">
            <Col span={4}>
                <b>Pairings Sheet</b>
            </Col>
            <Col span={17} className="flex-end">
                {colorArray && Object.keys(colorArray).map((key) =>
                    <span className="flex-row color-square-wrapper mr-4 text-cap"> <div className='mr-1 my-auto color-square' style={{ backgroundColor: colorArray[key] }} /> {key}</span>
                )}
            </Col>
        </Row>
        <Row className='d-border p-4'>
            <Col span={16} className="black-radio my-auto flex">
                {
                    !isTeeTime ?

                        <Radio.Group
                            onChange={(e) => {
                                if (!showCustom)
                                    setRowState((prev: any) => { return { ...prev, format: e.target.value } })
                            }}
                            value={showCustom ? 'custom' : rowState.format}
                            className="flex-center"
                        >
                            <Row>
                                <Col className="ml-2"><Radio value={'a'} disabled={showCustom}>A</Radio></Col>
                                <Col><Radio value={'ab'} disabled={showCustom}>AB</Radio></Col>
                                <Col><Radio value={'abc'} disabled={showCustom}>ABC</Radio></Col>
                                {showCustom &&
                                    <Col><Radio value={'custom'}>Custom</Radio></Col>}
                            </Row>
                        </Radio.Group>
                        :
                        <Row className='b-skyBlue text-w pairing-sheet-tee-time mr-4'>

                            <Col>
                                <button disabled={showCustom} onClick={() => handleFormat('1')} className={(rowState.format === '1' && !showCustom) ? ACTIVE_STYLE : showCustom ? NONACTIVE_STYLE + ' pointer-disabled' : NONACTIVE_STYLE}>1</button>
                                <button disabled={showCustom} onClick={() => handleFormat('10')} className={(rowState.format === '10' && !showCustom) ? ACTIVE_STYLE : showCustom ? NONACTIVE_STYLE + ' pointer-disabled' : NONACTIVE_STYLE}>10</button>
                                <button disabled={showCustom} onClick={() => handleFormat('1&10')} className={(rowState.format === '1&10' && !showCustom) ? ACTIVE_STYLE : showCustom ? NONACTIVE_STYLE + ' pointer-disabled' : NONACTIVE_STYLE}>1 & 10</button>
                                {showCustom && <button onClick={() => { }} className={showCustom ? ACTIVE_STYLE : NONACTIVE_STYLE}>Custom</button>}
                            </Col>
                        </Row>
                }
                <Row className='b-skyBlue text-w pairing-sheet-tee-time'>
                    <Col>
                        <button onClick={() => handleSort('All')} className={sortSheet === 'All' ? ACTIVE_STYLE : NONACTIVE_STYLE}>All</button>
                        <button onClick={() => handleSort('Assigned')} className={sortSheet === 'Assigned' ? ACTIVE_STYLE : NONACTIVE_STYLE}>Assigned</button>
                        <button onClick={() => handleSort('Unassigned')} className={sortSheet === 'Unassigned' ? ACTIVE_STYLE : NONACTIVE_STYLE}>Unassigned</button>
                    </Col>
                </Row>
            </Col>
            <Col span={8} className="flex-end my-auto">
                <div className="my-auto mr-4" title="Saved">Save As Draft</div>
                <div className='b-skyBlue bg-decent pointer text-skyBlue b-r-5 flex-center  mr-2'>
                    <Popover content={CONTENT} placement="bottom">
                        <div className="pairings-action-btn flex-center flex-row">
                            <b>Actions </b>
                            <EllipseIcon style={{ height: 14 }} className='ml-2' />
                        </div>
                    </Popover>
                </div>
            </Col>
        </Row>

    </>);
}

export default SheetHeader;