import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Typography, Breadcrumb, Button, Row, Col, Badge } from "antd";
import {
  ArrowRightOutlined,
  BellOutlined,
  MailOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "./styles.css";
import type { MenuProps } from "antd";
// import { ReactComponent as ShopIcon } from "@assets/icons/shop-icon.svg";
import { ReactComponent as BankingIcon } from "@assets/icons/bank-icon.svg";
import { ReactComponent as SupportIcon } from "@assets/icons/help-icon.svg";
import { ReactComponent as AdministrationIcon } from "@assets/icons/administration-icon.svg";
import { ReactComponent as MarketingIcon } from "@assets/icons/marketing-icon.svg";
// import { ReactComponent as ReportIcon } from "@assets/icons/report-icon.svg";
// import { ReactComponent as PersonIcon } from "@assets/icons/person-icon.svg";
import { ReactComponent as AcademyIcon } from "@assets/icons/academy-icon.svg";
// import { ReactComponent as LogoutIcon } from "@assets/icons/log-icon.svg";
import { ReactComponent as CourseIcon } from "@assets/icons/course-icon.svg";
import { ReactComponent as Library } from "@assets/icons/Library.svg"
import PersonLogo from "@assets/images/logperson.png";
import Logo from "@assets/images/LogoGC.png";
import LogOut from "@assets/images/switch.png";
import { Link, matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "@config/storeConfig";
import { useDispatch } from "react-redux";
import { SagaActionType, SagaActions } from "@sagas/index";
import { history } from "@config/routes";
import { navData } from "../../constant/mainNav";
import SuperAdminNotificationModal from "@screens/admin/SuperAdminFlow/superAdminNotificationModal";
import { isC_Admin, isC_Owner, isCh_Owner, isTournament_Director, isTournament_Staff, isSuper_Admin, whoCanSeeNotification } from "@auth/permission";
import { getPathName } from "@constant/url";
import { ReactComponent as GolfCloudLogo } from "@assets/icons/GCBWhiteLogo.svg";
import { useSelector } from 'react-redux';


const { Title } = Typography;
const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;

type MenuItem = Required<MenuProps>["items"][number];



function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
//side bar 
const menuList: MenuItem[] = [
  getItem(
    <Link to="">Chapters<br />/ Events</Link>,
    "Chapters / Events",
    <AcademyIcon width={22} height={20} />
  ),
  // getItem(
  //   <Link to="">Marketing</Link>,
  //   "Marketing",
  //   <MarketingIcon width={22} height={20} />
  // ),

  getItem(
    <Link to="">Accounting</Link>,
    "Accounting",
    <BankingIcon width={22} height={20} />
  ),
  // getItem(
  //   <Link to="">Reports</Link>,
  //   "Reports",
  //   <ReportIcon width={22} height={20} />
  // ),
  getItem(
    <Link to="">Administration</Link>,
    "Administration",
    <AdministrationIcon width={22} height={20} />
  ),
  getItem(
    <Link to="">Marketing</Link>,
    "Marketing",
    <MarketingIcon width={22} height={20} />
  ),
  // getItem(
  //   <Link to="">Pro-Shop</Link>,
  //   "Pro-Shop",
  //   <ShopIcon width={22} height={20} />
  // ),
  getItem(
    <Link to="">Courses</Link>,
    "Courses",
    <CourseIcon width={22} height={20} />
  ),
  getItem(
    <Link to="">Library</Link>,
    "Library",
    <Library width={22} height={20} />
  ),
  getItem(
    <Link to="">Support</Link>,
    "Support",
    <SupportIcon width={22} height={20} />
  ),
];

interface MainLayoutType {
  children: React.ReactElement;
  onClick?: () => void;
}

const items: MenuProps["items"] = [
  getItem("Navigation One", "sub1", <MailOutlined />, [
    getItem("Item 1", "g1", null, [
      getItem("Option 1", "1"),
      getItem("Option 2", "2"),
    ]),
    getItem("Item 2", "g2", null, [
      getItem("Option 3", "3"),
      getItem("Option 4", "4"),
    ]),
  ]),
];



const MainLayout: React.FC<MainLayoutType> = React.memo(({ children }) => {
  const { notificationCount, total } = useSelector((state: any) => state.notification);
  const [selectedRoute, setSelectedRoute] = useState<any>("");
  const [currentTabState, setCurrentTabState] = useState<any>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsModal] = useState(window.innerWidth < 800 ? true : false)
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPath = location.pathname

  const handleDrawerClose = () => {
    setTimeout(() => { setSelectedRoute(currentTabState) }, 200)
    setIsDrawerOpen(false);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    setIsDrawerOpen(true)
  };



  const paths = window.location.pathname.split('/').slice(1);


  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (localStorage.getItem('accessToken') === (undefined || null)) {
      history.replace("/admin/login");
    }
  })
  async function logoutUser() {
    dispatch({ type: `${SagaActions.SIGNOUT_USER}_${SagaActionType.REQUEST}`, payload: { accessToken: localStorage.getItem('accessToken') } });
  }

  useEffect(() => {
    if (total === undefined) {
      dispatch({
        type: `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
        payload: {}
      });
    }
  }, []);

  const showNotification = whoCanSeeNotification();
  useEffect(() => {
    let breakFlag: boolean = false;
    for (let menu in navData) {
      for (let i of navData[menu]) {
        if (selectedPath.toLowerCase().includes(i.link.toLowerCase())) {
          setCurrentTabState(menu)
          setSelectedRoute(menu)
          breakFlag = true
        }
        if (breakFlag) {
          break;
        }
      }
      if (breakFlag) {
        break;
      }
    }
  }, [])

  return (
    <Layout className="r-admin-sider-layout" hasSider>
      <Drawer
        placement={"left"}
        closable={true}
        onClose={handleDrawerClose}
        className="check"
        visible={isDrawerOpen}
        key={"left"}
        style={{ color: "#0ABBF2", margin: 0, padding: 0 }}
        bodyStyle={{ backgroundColor: "#0ABBF2", color: "#FFF", margin: 0, padding: 0 }}
        zIndex={98}
        closeIcon={null}
        drawerStyle={{ backgroundColor: "#0ABBF2", margin: 0, padding: 0 }}
        headerStyle={{
          backgroundColor: "#0ABBF2",
          border: 0,
          margin: 0,
          padding: 0,
        }}
        contentWrapperStyle={{ left: "20px", margin: 0, padding: 0 }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            color: "#FFF",
            fontWeight: "bold",
            paddingLeft: 88,
            paddingTop: 30,
            cursor: "pointer",
          }}
          onClick={() => handleDrawerClose()}
        >
          <Row align="middle" justify="start" style={{ width: '100%' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 20, margin: '0 0.5rem' }}>
              {selectedRoute}
            </Title>
            <ArrowRightOutlined style={{ marginTop: 6 }} />
          </Row>
          <Row className="sub-menu">
            {
              (navData[selectedRoute] || []).map(({ name, link }) => {
                return (
                  <Col span={24}>
                    <Button key={name} className={(selectedPath.includes(link)) ? "menu-style menu-selected-style w-100" : "menu-style menu-nonselected-style w-100"}
                      onClick={() => {
                        setSelectedRoute("")
                        setTimeout(() => { navigate(`/admin/${link}`) }, 400)
                      }}>
                      {name}
                    </Button>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </Drawer>
      <Sider trigger={null} width={112} className="r-admin-sider" style={isMobile ? { display: 'none' } : {}}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div className="ll">
            <div className="logo">
              <GolfCloudLogo />
            </div>

            <Menu
              mode="inline"
              items={menuList}
              selectedKeys={[currentTabState, selectedRoute]}
              selectable={true}
              style={{
                marginTop: 1,
              }}
              onClick={(e) => { setTimeout(() => { setSelectedRoute(e.key); }, 200); setIsDrawerOpen(true); }}
              onSelect={(e) => {
                setIsDrawerOpen(false);
                setTimeout(() => {
                  setIsDrawerOpen(true);
                }, 300);
              }}
            />
          </div>
          <div onClick={logoutUser}
            style={{ cursor: 'pointer', marginBottom: 10 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="golfcloud-logo flex-center"
                src={PersonLogo}
                alt=""
                style={{
                  marginTop: 50,
                }}
              />
            </div>
            <div className="flex-row flex-center">
              <img
                className="golfcloud-logo flex-center"
                src={LogOut}
                alt=""
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "#A0A0A0",
                  padding: 6,
                  fontWeight: "bold",
                }}

              >
                Logout
              </p>
            </div>
          </div>
        </div>
      </Sider>
      <Layout
        className="site-layout r-admin-layout"
        style={isMobile ? {} : { marginLeft: 112 }}
      >
        <Header className="r-admin-header">
          <Breadcrumb className="r-admin-header-section">
            {isMobile && <MenuOutlined onClick={() => { setIsModal(prev => !prev) }} className="my-auto mr-4 scale1-3 r-admin-header-section-mobile-menu pointer" />}
            <Breadcrumb.Item><Link to="/admin/dashboard">Home</Link></Breadcrumb.Item>
            {paths.map((path, index) => {
              if (path === 'admin') return null;
              const pathName = getPathName(path)
              return (
                <Breadcrumb.Item key={index}>
                  <Link to={`/${paths.slice(0, index + 1).join('/')}`} style={{ textTransform: 'capitalize' }}>
                    {pathName}
                  </Link>
                </Breadcrumb.Item>)
            })}
          </Breadcrumb>

          {showNotification ?
            (
              <Badge count={notificationCount} >
                <BellOutlined className="BellOutlined" onClick={() => setOpen(true)} />
              </Badge>
            ) : (
              ""
            )}
        </Header>
        <Content style={{ overflow: "initial", background: "#fff" }}>
          <div className="site-layout-background">{children}</div>
        </Content>
        <Col className="footer-margin-gap" />
        <FooterLayout isMobile={isMobile} />
        {open && <SuperAdminNotificationModal open={open} handleCancel={() => setOpen(false)} />}
      </Layout>
    </Layout>

  );
});
export default MainLayout;
interface FooterLayoutI {
  isMobile: boolean
}
const FooterLayout: React.FC<any> = React.memo(({ isMobile }: FooterLayoutI) => {
  const currentYear = new Date();
  const ROLE = isC_Admin() ? 'Company Admin' : isC_Owner() ? 'Company Owner' : isCh_Owner() ? 'Chapter Owner' : isTournament_Director() ? 'Tournament Director' : isTournament_Staff() ? 'Tournament Staff' : isSuper_Admin() ? 'Super Admin' : 'Chapter Admin'

  return (
    <Footer
      className={isMobile ? 'mobile-footer-wrapper footer-wrapper d-box-shadow' : "footer-wrapper"}
    >
      <Col className="flex-center">
        <div className="green-circle my-auto mr-1" />
        Logged in as {ROLE} | {`Golf Cloud For Business (c) ${currentYear.getFullYear()}`}
      </Col>
    </Footer>
  )
}, () => true);