import { useAppSelector } from "@utils/reduxHooks";
import  { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";



const COLORS = ["#198658", "#F28F1B"];

const renderActiveShape = (props: any,revenue:number) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius) * cos;
  const sy = cy + (outerRadius) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const isIncome = payload.name === 'Income'

  return (
    <g>
      <text x={cx} y={cy} dy={0} textAnchor="middle" fill={'#00000080'} style={{ fontSize: 20, fontWeight: 600 }}>Revenue</text>
      <text x={cx} y={cy} dy={30} textAnchor="middle" style={{ fontSize: 24, fontWeight: 600 }}>${revenue.toLocaleString('en-US', {
        style: 'decimal',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={6}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}`}
        stroke='#000'
        fill="none"
      />
      <circle cx={mx} cy={my} r={3} fill='#000' stroke="none" />
      <text
        x={mx + (cos >= 0 ? 1 : -1) * 12}
        y={my}
        dy={isIncome ? -25 : 15}
        textAnchor="middle"
        fill="#000"
        style={{ fontWeight: 700, fontSize: 14 }}
      >{payload.name}</text>
      <text
        x={mx + (cos >= 0 ? 1 : -1) * 12}
        y={my}
        textAnchor="middle"
        fill={isIncome ? '#198658' : '#F28F1B'}
        dy={isIncome ? -5 : 35}
        style={{ fontWeight: 700, fontSize: 16 }}
      >${value.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
      </text>
    </g>
  );
};
const AccountingPieChart = ({isTournament}:any) => {
  const {tournamentAccountings,mainAccountings} = useAppSelector((state: any) => state.accounting);
 
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const data = [
    { name: "Income", value: isTournament ? tournamentAccountings?.income?.total : mainAccountings?.income?.total },
    { name: "Expense", value: tournamentAccountings?.expense?.total || mainAccountings?.expense?.total  },
  ]

  return (
    <PieChart
      width={450}
      height={400}
    >
      <Pie
        activeIndex={activeIndex}
        activeShape={(props)=>renderActiveShape(props,isTournament ? tournamentAccountings?.revenue?.total.toFixed(2) :  mainAccountings?.revenue?.total )}
        data={data}
        cx={200}
        cy={180}
        innerRadius={100}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
        paddingAngle={3}
        cornerRadius={6}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}

export default AccountingPieChart;
