import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

type sponsorsList = {
  id?: number;
  name: string;
  url: string;
  chapter_id: number;
  company_id: number;
  sponsor_logo: string;
}

type sponsors = {
  isLoading: boolean;
  sponsorsList: sponsorsList[]
}
const initialState: sponsors = {
  isLoading: true,
  sponsorsList: [],
};

export const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_SPONSORS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_SPONSORS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_SPONSOR}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_SPONSOR}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_SPONSORS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_SPONSORS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          sponsorsList: action.payload.data,
        };
      }
    );

  }
});

export default sponsorSlice.reducer;  