
import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getCompanyID, getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';


function* viewBudget({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ACCOUNTINGS}/${payload.id}/view_budgets`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.VIEW_BUDGET}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
          tournamentId: payload.id
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* AddAccountingEntry({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    payload.company_id = getCompanyID();
    let response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.ACCOUNTING_TRANSACTIONS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      yield put({
        type: `${SagaActions.BALANCE_SHEET}_${SagaActionType.REQUEST}`,
        payload: { id: payload.tournament_id },
      });
      if (payload.isBudgetAPIReload) {
        yield put({
          type: `${SagaActions.VIEW_BUDGET}_${SagaActionType.REQUEST}`,
          payload: { id: payload.tournament_id },
        });
        yield put({
          type: `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
          payload: { id: payload.tournament_id },
        });
      }

    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
    yield put({
      type: `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.SUCCESS}`,
      payload: {},
    });
  }
}
function* getCompanyChapterAccountings({ payload }: any): Generator<any> {
  const { isChapter, id, ...value } = payload;
  let endPoint: any = isChapter ? ApiEndpoints.CHAPTER_ACCOUNTINGS : ApiEndpoints.COMPANY_ACCOUNTINGS;
  endPoint = endPoint + '/' + id + '/dashboard'
  try {
    value.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${endPoint}`,
      { params: value });
    yield put({
      type: `${SagaActions.GET_COMPANY_CHAPTER_ACCOUNTINGS}_${SagaActionType.SUCCESS}`,
      payload: response.data.data,
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}
function* getTournamentAccountings({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ACCOUNTINGS}/${payload.id}/dashboard`,
      { params: payload });
    yield put({
      type: `${SagaActions.GET_TOURNAMENT_ACCOUNTINGS}_${SagaActionType.SUCCESS}`,
      payload: response.data.data,
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* balanceSheet({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ACCOUNTINGS}/${payload.id}/balance_sheet`,
      { params: { access_token: getToken() } });
    if (response?.status === 200) {
      if (payload.isChapter) {
        yield put({
          type: `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.SUCCESS}`,
          payload: {
            data: response.data.data,
            ...payload
          },
        });
      }
      else
        yield put({
          type: `${SagaActions.BALANCE_SHEET}_${SagaActionType.SUCCESS}`,
          payload: {
            data: response.data.data,
            tournamentId: payload.id
          },
        });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* tournamentProfitAndLoss({ payload }: any): Generator<any> {
  try {
    const { id, ...value } = payload;
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ACCOUNTINGS}/${id}/${ApiEndpoints.PROFIT_AND_LOSS}`,
      { params: { access_token: getToken(), ...value } });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.SUCCESS}`,
        payload: { data: response.data.data },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}



function* companyChapterProfitAndLoss({ payload }: any): Generator<any> {
  const { isChapter, id, ...value } = payload;
  let endPoint: any = isChapter ? ApiEndpoints.CHAPTER_ACCOUNTINGS : ApiEndpoints.COMPANY_ACCOUNTINGS;
  endPoint = endPoint + '/' + id
  try {
    value.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${endPoint}/${ApiEndpoints.PROFIT_AND_LOSS}`,
      { params: value });
    yield put({
      type: `${SagaActions.COMPANY_CHAPTER_PROFIT_AND_LOSS}_${SagaActionType.SUCCESS}`,
      payload: response.data.data,
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* deleteEntry({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.ACCOUNTING_TRANSACTIONS}/${payload.id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_ENTRY}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      yield put({
        type: `${SagaActions.BALANCE_SHEET}_${SagaActionType.REQUEST}`,
        payload: { id: payload.tournament_id },
      });
      if (payload.isBudgetAPIReload) {
        yield put({
          type: `${SagaActions.VIEW_BUDGET}_${SagaActionType.REQUEST}`,
          payload: { id: payload.tournament_id },
        });
        yield put({
          type: `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
          payload: { id: payload.tournament_id },
        });
        yield put({
          type: `${SagaActions.GET_TOURNAMENT_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
          payload: { id: payload.tournament_id },
        });
      }
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* getTournamentRevenue({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTER_ACCOUNTINGS}/${payload.id}/${ApiEndpoints.LIST_TOURNAMENT_REVENUES}`,
      { params: { access_token: getToken() } });
    yield put({
      type: `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.SUCCESS}`,
      payload: { flag: payload?.isChapter, data: [...response.data.data.tournament_revenues_list, { total_amount: response.data.data.total_amount }], id: payload.id },
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}
function* getTournamentExpenses({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.CHAPTER_ACCOUNTINGS}/${payload.id}/${ApiEndpoints.LIST_TOURNAMENT_EXPENSES}`,
      { params: { access_token: getToken() } });
    yield put({
      type: `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.SUCCESS}`,
      payload: { flag: payload?.isChapter, id: payload.id, data: [...response.data.data.tournament_expense_list, { total_amount: response.data.data.total_amount }] },
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}
function* getChapterRevenue({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.COMPANY_ACCOUNTINGS}/${getCompanyID()}/${ApiEndpoints.LIST_CHAPTER_REVENUES}`,
      { params: { access_token: getToken() } });
    yield put({
      type: `${SagaActions.LIST_CHAPTER_REVENUES}_${SagaActionType.SUCCESS}`,
      payload: [...response.data.data.chapter_revenues_list, { total_amount: response.data.data.total_amount }]
      ,
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}
function* getChapterExpenses({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.COMPANY_ACCOUNTINGS}/${getCompanyID()}/${ApiEndpoints.LIST_CHAPTER_EXPENSES}`,
      { params: { access_token: getToken() } });
    yield put({
      type: `${SagaActions.LIST_CHAPTER_EXPENSES}_${SagaActionType.SUCCESS}`,
      payload: [...response.data.data.chapter_expense_list, { total_amount: response.data.data.total_amount }],
    });
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}
function* ViewBudgetRequest() {
  yield takeLatest(
    `${SagaActions.VIEW_BUDGET}_${SagaActionType.REQUEST}`,
    viewBudget
  );
}
function* AddAccountingEntryRequest() {
  yield takeLatest(
    `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.REQUEST}`,
    AddAccountingEntry
  );
}
function* getCompanyChapterAccountingsRequest() {
  yield takeLatest(
    `${SagaActions.GET_COMPANY_CHAPTER_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
    getCompanyChapterAccountings
  );
}
function* getTournamentAccountingsRequest() {
  yield takeLatest(
    `${SagaActions.GET_TOURNAMENT_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
    getTournamentAccountings
  );
}
function* TournamentDetailsRequest() {
  yield takeLatest(
    `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.REQUEST}`,
    balanceSheet
  );
}
function* BalanceSheetRequest() {
  yield takeLatest(
    `${SagaActions.BALANCE_SHEET}_${SagaActionType.REQUEST}`,
    balanceSheet
  );
}
function* TournamentProfitAndLossRequest() {
  yield takeLatest(
    `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
    tournamentProfitAndLoss
  );
}

function* CompanyChapterProfitAndLossRequest() {
  yield takeLatest(
    `${SagaActions.COMPANY_CHAPTER_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
    companyChapterProfitAndLoss
  );
}

function* DeleteEntryRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_ENTRY}_${SagaActionType.REQUEST}`,
    deleteEntry
  );
}

function* getTournamentRevenueRequest() {
  yield takeLatest(
    `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.REQUEST}`,
    getTournamentRevenue
  );
}
function* getTournamentExpensesRequest() {
  yield takeLatest(
    `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.REQUEST}`,
    getTournamentExpenses
  );
}
function* getChapterRevenueRequest() {
  yield takeLatest(
    `${SagaActions.LIST_CHAPTER_REVENUES}_${SagaActionType.REQUEST}`,
    getChapterRevenue
  );
}
function* getChapterExpensesRequest() {
  yield takeLatest(
    `${SagaActions.LIST_CHAPTER_EXPENSES}_${SagaActionType.REQUEST}`,
    getChapterExpenses
  );
}
export default function* rootAccountings() {
  yield spawn(AddAccountingEntryRequest);
  yield spawn(getCompanyChapterAccountingsRequest);
  yield spawn(getTournamentAccountingsRequest);
  yield spawn(ViewBudgetRequest);
  yield spawn(BalanceSheetRequest);
  yield spawn(TournamentProfitAndLossRequest);
  yield spawn(CompanyChapterProfitAndLossRequest);
  yield spawn(DeleteEntryRequest);
  yield spawn(getTournamentRevenueRequest);
  yield spawn(getTournamentExpensesRequest);
  yield spawn(getChapterRevenueRequest);
  yield spawn(getChapterExpensesRequest);
  yield spawn(TournamentDetailsRequest);
}
