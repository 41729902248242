import { Input } from "antd";
import "../styles.css";

const FormikPayoutMatrix = ({ data, setFieldValue }: any) => {
  const maxlength = data?.max_position;
  const fieldSize = data?.max_fields;

  const headerCells = [];
  for (let i = 1; i <= maxlength; i++) {
    headerCells.push(<th key={`header-${i}`}>{i}</th>);
  }

  const rows = [];
  for (let i = 1; i <= fieldSize; i++) {
    const rowCells = [];
    for (let j = 1; j <= maxlength; j++) {
      const valueIdx =
        data?.distributions.length > 0
          ? data?.distributions.findIndex(
              (entry: { field: number; position: number }) =>
                entry.field === i && entry.position === j
            )
          : 0;
      const value =
        data?.distributions.length > 0 && valueIdx !== -1
          ? data?.distributions.find(
              (entry: { field: number; position: number }) =>
                entry.field === i && entry.position === j
            )?.value
          : "";
      rowCells.push(
        <td className="payout-matrix-table-cell" key={`cell-${i}-${j}`}>
          <Input
            placeholder="Enter value"
            maxLength={3}
            onChange={(e: any) => {
              const valIdx =
                data?.distributions.length > 0 &&
                data?.distributions.findIndex(
                  (entry: { field: number; position: number }) =>
                    entry.field === i && entry.position === j
                );
              let currentIdx =
                valIdx === -1 && data?.distributions.length > 0
                  ? Number(data.distributions.length)
                  : valIdx === false ? 0 : valIdx;
              setFieldValue(`distributions[${currentIdx}]`, {
                field: i,
                position: j,
                value: e.target.value,
              });
            }}
            suffix={
              data?.distribution_type.toLowerCase().includes("percentage")
                ? "%"
                : ""
            }
            value={value}
          />
        </td>
      );
    }
    rows.push(
      <tr key={`row-${i}`}>
        <td>{i}</td>
        {rowCells}
      </tr>
    );
  }
  return (
    <table className="payout-matrix-table">
      <thead>
        <tr>
          <th colSpan={1} style={{ border: "none" }}></th>
          <th colSpan={maxlength} className="table-header-cell">
            Number Distribution By Positions
          </th>
        </tr>
        <tr>
          <th rowSpan={maxlength} className="payout-matrix-table-curve">
            Field Size
          </th>
          {headerCells}
        </tr>
      </thead>
      <tbody className="formik-payout-matrix-table-body">{rows}</tbody>
    </table>
  );
};

export default FormikPayoutMatrix;
