import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface pairingPlayersInterface {
  isLoadingCourse:boolean,
  isPlayerDraged: boolean,
  isPlayerDragedOut: boolean,
  isPlayerListDragedOut: boolean,
  selectedCourse: any,
  isCourseListFetched: boolean,
  dragedPlayerArray: any[],
  CourseList: any[],
}
const initialState: pairingPlayersInterface = {
  isLoadingCourse:false,
  isPlayerDraged: false,
  isPlayerDragedOut: false,
  isPlayerListDragedOut: false,
  isCourseListFetched: false,
  selectedCourse: {},
  dragedPlayerArray: [],
  CourseList: [],
};
export const pairingPlayerSlice = createSlice({
  name: "pairingPlayer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        let updatedPlayerArray: any[] = JSON.parse(JSON.stringify(state.dragedPlayerArray))
        const { data } = action.payload
        for (let i = 0; i < data.length; i++) {
          const INDEX = updatedPlayerArray.findIndex((player) => player.id === data[i].id)
          if (INDEX > -1) {
            updatedPlayerArray[INDEX] = data[i]
          } else {
            updatedPlayerArray.push(data[i])
          }
        }
        return {
          ...state,
          isPlayerDraged: false,
          dragedPlayerArray: updatedPlayerArray,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayerDraged: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_DRAGED}_${SagaActionType.FAIL}`,
      (state, action) => {
        return {
          ...state,
          isPlayerDraged: false,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.FAIL}`,
      (state, action) => {
        return {
          ...state,
          isPlayerDragedOut: false,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        let updatedPlayerArray: any[] = [...state.dragedPlayerArray]
        const INDEX = state.dragedPlayerArray.findIndex((player) => player.player_id === action.payload.data.player_id)
        if (INDEX > -1) {
          updatedPlayerArray.splice(INDEX, 1)
        }
        return {
          ...state,
          isPlayerDragedOut: false,
          dragedPlayerArray: updatedPlayerArray,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_REMOVED}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayerDragedOut: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_FETCH}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoadingCourse: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_PLAYERS_FETCH}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { pairing_players, solts_available, ...props } = action.payload
        const slotAvailable = Number(solts_available) + pairing_players.length
        return {
          ...state,
          isLoadingCourse: false,
          dragedPlayerArray: pairing_players,
          selectedCourse: { ...props, solts_available: slotAvailable },
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_COURSE_LIST}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isCourseListFetched: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PAIRING_COURSE_LIST}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isCourseListFetched: false,
          CourseList: [...action.payload]
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayerDragedOut: true,
          isPlayerListDragedOut: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_PAIRINGS_COLUMN}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        let updatedPlayerArray = state.dragedPlayerArray.filter(player =>
          !action.payload.data.some((i: any) => i.player_id === player.player_id));

        return {
          ...state,
          isPlayerDragedOut: false,
          isPlayerListDragedOut: false,
          dragedPlayerArray: updatedPlayerArray,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ADD_CUSTOM_HOLE}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const temp = {...state.selectedCourse, pattern:'custom', holes: action.payload.holes}
        let updatedPlayerArray = action.payload.data.length > 0 ? action.payload.data : state.dragedPlayerArray
        return {
          ...state,
          dragedPlayerArray: updatedPlayerArray,
          selectedCourse: temp,
        };;
      }
    );
  },
})

export default pairingPlayerSlice.reducer;  