import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";


const initialState = {
  registeredPlayers: [],
  isregisteredPlayersLoading: true,
  playersStats: [],
  isPlayersStatsLoading: true,
  isPlayersCSVLoading: true,
  playersCSV: [],
  playerComments: [],
  isPlayerCommentLoading: true,
  total: 0
};

export const RegisteredPlayersSlice = createSlice({
  name: "registeredPlayers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayersStatsLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_PLAYERS_STATS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          playersStats: action.payload.data,
          isPlayersStatsLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isregisteredPlayersLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          registeredPlayers: action.payload.data,
          isregisteredPlayersLoading: false,
          total: action.payload.total,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYERS_CSV}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayersCSVLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.PLAYERS_CSV}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          playersCSV: action.payload.data,
          isPlayersCSVLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_COMMENT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isPlayerCommentLoading: true
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_COMMENT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          playerComments: action.payload.data,
          isPlayerCommentLoading: false
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_COMMENT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_COMMENT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };;
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.EDIT_COMMENT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.EDIT_COMMENT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_COMMENT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DELETE_COMMENT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.WAITLIST_TO_REGISTERED}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.WAITLIST_TO_REGISTERED}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.SEND_EMAIL_SMS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.SEND_EMAIL_SMS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_PLAYER_STATUS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.UPDATE_PLAYER_STATUS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REMOVE_PLAYER}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.REMOVE_PLAYER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
        };
      }
    );

  },
})

export default RegisteredPlayersSlice.reducer;  