import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getToken } from "../auth/localData";
import { Error, Success } from '../constant/toast/Toast';

function* fetchTournamentRounds({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS}/${payload.id}/${ApiEndpoints.LIST_TOURNAMENT_ROUNDS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.LIST_TOURNAMENT_ROUNDS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* createSideGame({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SIDE_GAMES}`,
      { ...payload } 
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the sidegames list after creating the side game
      yield put({
        type: `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.REQUEST}`, 
        payload:{id:payload.tournament_round_id}
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* listSideGames({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENT_ROUNDS}/${ApiEndpoints.LIST_SIDE_GAMES}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* deleteSideGame({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const id = payload.id
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SIDE_GAMES}/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
        payload: { },
      });
      //To reload the sidegames list after deleting the side game
      yield put({
        type: `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.REQUEST}`, 
        payload:{id:payload.roundId}
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.DELETE_SIDE_GAME}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}


function* TournamentRoundsRequest() {
  yield takeLatest(
    `${SagaActions.LIST_TOURNAMENT_ROUNDS}_${SagaActionType.REQUEST}`,
    fetchTournamentRounds
  );
}

function* CreateSideGameRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_SIDE_GAME}_${SagaActionType.REQUEST}`,
    createSideGame
  );
}

function* ListSideGamesRequest() {
  yield takeLatest(
    `${SagaActions.LIST_SIDE_GAMES}_${SagaActionType.REQUEST}`,
    listSideGames
  );
}

function* DeleteSideGamesRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_SIDE_GAME}_${SagaActionType.REQUEST}`,
    deleteSideGame
  );
}

export default function* rootSideGames() {
  yield spawn(TournamentRoundsRequest);
  yield spawn(CreateSideGameRequest);
  yield spawn(ListSideGamesRequest);
  yield spawn(DeleteSideGamesRequest);
}