import { CloseCircleOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { MainLayout } from '@components/layouts'
import PageHeader from '@components/page/PageHeader'
import { Button, Col, Drawer, Row, Select, Spin } from 'antd'
import { DatePicker } from 'antd';
import './AccountingStyle.css'
import { AccountingCards, AccountingHeader, AccountingMenu, AccountingdropdownOption, HorizontalMenuUI, RenderPageMenu } from './Component';
import { useEffect, useState } from 'react';
import Htag from '@layout/H1';
import { isC_Admin, isC_Owner } from '@auth/permission';
import { useDispatch } from 'react-redux';
import { SagaActionType, SagaActions } from '@sagas/index';
import NPSelectM from '@components/inputFields/NPSelectM';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getChapterID, getCompanyID } from '@auth/localData';
import { Link } from 'react-router-dom';
import { getDaysBefore } from '@constant/date';

const { RangePicker } = DatePicker;

export default function Accounting() {
    const [activeMenu, setActiveMenu] = useState(AccountingMenu[1])
    const [showFilter, setShowFilter] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string>('30')
    const [dateRange, setDateRange] = useState<any>([null, null])
    const [filter, setFilter] = useState<any>({ chapter_id: undefined, tournament_id: undefined });
    const [filterName, setFilterName] = useState<any>('');

    const canSelectChapter = isC_Admin() || isC_Owner();
    const dispatch = useDispatch();
    useEffect(() => {
        if (canSelectChapter) {
            dispatch({ type: `${SagaActions.CHAPTER_LIST}_${SagaActionType.REQUEST}`, payload: {} });
        }
    }, []);

    const { chapter_list, chapter_tournament_list } = useSelector((state: any) => state.chapter);
    const { mainAccountings, ismainAccountingsLoading, isEntryAdded } = useSelector((state: any) => state.accounting);
    const formikMain = useFormik({
        initialValues: {
            chapter_id: canSelectChapter ? undefined : getChapterID(),
            tournament_id: undefined,
        },
        onSubmit: async () => {
            hitAPI();
            setShowFilter(false);
            setFilter(values);
            let name: string = '';
            if (values.chapter_id) {
                name = chapter_list.find((item: any) => item.id === values?.chapter_id)?.name;
            }
            if (values.tournament_id) {
                name += ' - ' + chapter_tournament_list.find((item: any) => item.id === values?.tournament_id)?.name;
            }
            setFilterName(name);
        },
        enableReinitialize: true,
    });

    const { getFieldProps, handleSubmit, values, setFieldValue, errors, touched } = formikMain;
    const clearForm = (e: any) => {
        e.preventDefault();
        formikMain.resetForm();
    }
    const hitAPI = () => {
        const payload: any = {
            isChapter: !canSelectChapter,
            id: canSelectChapter ? getCompanyID() : getChapterID(),
        }
        if (dateRange[0] && dateRange[1]) {
            payload['start_date'] = new Date(dateRange[0]);
            payload['end_date'] = new Date(dateRange[1]);
        }
        if (values.chapter_id) {
            payload['chapter_id'] = values.chapter_id;
        }
        if (values.tournament_id) {
            payload['tournament_id'] = values.tournament_id;
        }
        dispatch({
            type: `${SagaActions.GET_COMPANY_CHAPTER_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
            payload: payload
        })
        dispatch({
            type: `${SagaActions.COMPANY_CHAPTER_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
            payload: payload
        })
    }
    useEffect(() => {
        if (values.chapter_id) {
            setFieldValue('tournament_id', '');
            dispatch({ type: `${SagaActions.List_CHAPTER_TOURNAMENNT}_${SagaActionType.REQUEST}`, payload: { id: values.chapter_id } })
        }
    }, [values.chapter_id])
    useEffect(() => {
        if (!selectedOption.includes('Custom') && isEntryAdded === false) {
            setDateRange([getDaysBefore(selectedOption), new Date()])
            hitAPI();
        }
    }, [selectedOption, isEntryAdded])
    return (
        <MainLayout>
            <Row className='accounting-main-wrapper'>
                <Col span={24} className='accounting-header-with-filter mb-8'>
                    <Row>
                        <Col md={8} xs={24}>
                            <PageHeader name='Accounting' />
                        </Col>
                        <Col md={16} xs={24} className='flex-center flex-end'>
                            {selectedOption !== 'Custom' &&
                                <Select value={selectedOption ? selectedOption : undefined} placeholder="Select Date Range" className='p-2 accounting-custom-dateRangePicker text-bold' onSelect={setSelectedOption} >
                                    {
                                        AccountingdropdownOption.map((item: any, index: number) => (
                                            <Select.Option key={index} value={item.value} className="m-auto w-100">{item.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            }
                            {selectedOption === 'Custom' &&
                                <RangePicker
                                    format={'MM/DD/YYYY'}
                                    className='h-50 accounting-date-picker b-r-5'
                                    open={true}
                                    onChange={(date, dateString) => { setDateRange(dateString); setSelectedOption(`Custom (${dateString[0]}-${dateString[1]}) `); hitAPI(); }}
                                />
                            }
                            <Col className='ml-2 b-r-5 accouting-filter-chapter pointer flex' onClick={() => { setShowFilter(true) }}>
                                <div className='mr-2 text-bold single-line' title={filterName ? filterName: ''}>{canSelectChapter ? filterName ? filterName : 'All Chapter & All Tournaments' : 'All Tournaments'}</div>
                                <span className='accounting-verticle-line p-abs' />
                                <FilterOutlined className='ml-4 mr-2 scale1-3 my-auto' />
                            </Col>
                        </Col>
                    </Row>
                </Col>
                <Row className='mb-8'>
                    {(AccountingHeader || []).map((item, index) => (
                        <Col className='mrb-4 accounting-card' key={'AccountingCards' + index} >
                            <AccountingCards key={index} {...item} data={mainAccountings} isLoading={ismainAccountingsLoading} />
                        </Col>
                    ))}
                </Row>
                <HorizontalMenuUI menuList={AccountingMenu} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
                <RenderPageMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} filter={filter} dateRange={dateRange} />
                <Drawer
                    placement="right"
                    open={showFilter}
                    className="commonDrawer"
                    title={null}
                    onClose={() => setShowFilter(false)}
                    headerStyle={{ padding: 0 }}
                    closable={false}
                >
                    <Row className='p-rel mt-4'>
                        <Col span={24}>
                            <CloseOutlined onClick={() => setShowFilter(false)} className='pointer p-abs' style={{ right: 16, top: -4 }} />
                            <Col span={16} className='mb-4 p-2'>
                                <Htag level={3} className='mb-0'>Select</Htag>
                            </Col>
                            <form className='com-form-container eventcreate input50' onSubmit={handleSubmit}>
                                <Col span={24} className='mt-4'>
                                    {canSelectChapter &&
                                        <NPSelectM
                                            label="Select Chapter"
                                            placeholder="Select Chapter"
                                            fname="chapter_id"
                                            options={chapter_list}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    }
                                    <NPSelectM
                                        label='Select Event'
                                        placeholder='Select Event'
                                        fname='tournament_id'
                                        options={chapter_tournament_list}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        getFieldProps={getFieldProps}
                                    />
                                    <Col span={24} className='mt-10 flex-center'>
                                        <Button className='raw-button btnSpin' htmlType="submit" style={{ background: '#047EB1', color: '#fff' }} >
                                            Submit
                                        </Button>
                                    </Col>
                                    <Col span={24} className='mt-4 flex-center'>
                                        <Link to='/' onClick={clearForm} className="com-cancel" style={{ textDecoration: 'underline' }}>
                                            Reset
                                        </Link>
                                    </Col>
                                </Col>
                            </form>
                        </Col>
                    </Row>
                </Drawer>
            </Row >
        </MainLayout >
    )
}