import {
    takeLatest,
    call,
    put,
    spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getCompanyID, getToken } from "../auth/localData";

function* fetchStatesListData({ payload }: any): Generator<any> {
    try {
        let response: any = yield call(gcbAPI().get,
            `/${ApiEndpoints.STATESLIST}`,
            { params: payload });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.STATESLIST}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data
                },
            });
        }
    }
    catch (error) { }
}
function* fetchDivisionListData({ payload = {} }: any): Generator<any> {
    const ID = getCompanyID();
    payload.access_token = getToken()
    try {
        let response: any = yield call(gcbAPI().get,
            `${ApiEndpoints.ADMIN}/${ApiEndpoints.COMPANIES}/${ID}/${ApiEndpoints.LIST_STANDARD_DIVISION}`,
            { params: payload });
        if (response?.status === 200) {
            yield put({
                type: `${SagaActions.LIST_STANDARD_DIVISION}_${SagaActionType.SUCCESS}`,
                payload: {
                    data: response.data.data
                },
            });
        }
    }
    catch (error) { }
}

function* StatesListRequest() {
    yield takeLatest(
        `${SagaActions.STATESLIST}_${SagaActionType.REQUEST}`,
        fetchStatesListData
    );
}
function* DivisionListRequest() {
    yield takeLatest(
        `${SagaActions.LIST_STANDARD_DIVISION}_${SagaActionType.REQUEST}`,
        fetchDivisionListData
    );
}
export default function* rootStatesListManagement() {
    yield spawn(StatesListRequest);
    yield spawn(DivisionListRequest);
}