import { forwardRef } from 'react';
import { DatePicker, Form } from 'antd';
import { ReactComponent as DropDownOutlined } from '@assets/icons/dropdown-icon.svg';
import moment from 'moment';



const { Item } = Form;
const { RangePicker } = DatePicker;


const FormikRangePicker = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      value2,
      style,
      setFieldValue,
      fieldValue,
      format,

      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: 'error', help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <RangePicker
        format={format}
        showTime
        allowClear={false}
        suffixIcon={<DropDownOutlined />}
        className="form-date-field-custom"
        placeholder={placeholder}
        name={name}
        onChange={(date, dateString) => {
          setFieldValue(fieldValue, date);
        }}
        value={value !== '' ? [moment(value[0]), moment(value[1])] : ''}
        onBlur={onBlur}
        ref={ref}
        {...props}
      />
    </Item>
  )
);

export default FormikRangePicker;