import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface permissionInterface {
    permissionList: any[]; 
    isLoading: boolean; 
}
const initialState: permissionInterface = {
  permissionList: [],
    isLoading: true,
};
export const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
          (action) =>
            action.type ===
            `${SagaActions.PERMISSION_MATRIX}_${SagaActionType.SUCCESS}`,
          (state, action) => {
            return {
                ...state,
                permissionList:action.payload.data,
                isLoading: false,

            };
          }
        );
      },
})

export default permissionSlice.reducer;  