import { Card, Col, Row, Select, Skeleton, Tooltip } from "antd";
import './AccountingStyle.css'
import { ReactComponent as ExpenseIcon } from "@assets/icons/Accounting/expenseVector.svg";
import { ReactComponent as IncomeIcon } from "@assets/icons/Accounting/bi_arrow-down-left-circle-green.svg";
import { ReactComponent as RevenueIcon } from "@assets/icons/Accounting/money gcb.svg";
import { ReactComponent as ExpenseGraphIcon } from "@assets/icons/Accounting/red-graph.svg";
import { ReactComponent as IncomeGraphIcon } from "@assets/icons/Accounting/green-graph.svg";
import { ReactComponent as RevenueGraphIcon } from "@assets/icons/Accounting/blue-graph.svg";
import { ReactComponent as ReceivablesIcon } from "@assets/icons/Accounting/in payable gcb.svg";
import { ReactComponent as PayableIcon } from "@assets/icons/Accounting/out payable gcb.svg";
import { memo, useEffect, useRef } from "react";
import CreateEntry from "./CreateEntry";
import Balance from "./Balance";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ProfitAndLoss from "./ProfitAndLoss/ProfitAndLoss";
import FormikRangePicker from "@components/inputFields/FormikRangePicker";
import React, { useMemo } from 'react';
import { isNumber } from "@constant/number";
import { PaymentMethods } from "./PaymentMethods";

interface IAccountingCards {
    title: string;
    Icon: any;
    price?: string;
    percentage?: string;
    graphIcon?: any;
    IconBackground?: string;
    priceColor?: string;
    data?: any;
    isLoading: boolean;
    count?: number;
    name?: string;
}

export const AccountingCards = ({ name, title, Icon, graphIcon, IconBackground, priceColor, data = {}, isLoading = false, count }: IAccountingCards) => {
    // Get the total value from data
    const total = data[title]?.total ? data[title]?.total : count;

    // Format the total value if it's greater than 3 digits, otherwise, keep it as is
    const formattedTotal = useMemo(() => {
        if (count) return count.toLocaleString('en-US');
        if (total !== undefined) {
            if (total !== 0) {
                if (Math.abs(total) >= 99999) {
                    return `$${(total / 1000).toFixed(2).replace('.', '.')}k`;
                } else {
                    return `$${total.toLocaleString('en-US')}`;
                }
            } else {
                return '$0';
            }
        } else {
            return '';
        }
    }, [total]);

    return (
        <Card className='d-box-shadow b-r-12'>
            <Row>
                <Col span={12}>
                    <div className={`accounting-card-icon flex-center`} style={{ backgroundColor: IconBackground }}>{Icon}</div>
                </Col>
                <Col span={12} className="graph-section">
                    <div className="graph-right">
                        {/* {graphIcon} */}
                    </div>
                </Col>
                <Col span={24} className='mb-2 mt-4 text-cap accounting-title'>{  name? name : title === "revenue" ? "Revenue (Gross)" : title}</Col>
                {(data[title] || count) && (
                    <Col span={24} className='accounting-price-number mt-4' style={{ color: priceColor }}>
                        {isLoading ? (
                            <Skeleton.Input active size='small' />
                        ) : (
                            <> <Tooltip title={`$${total.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}`} placement="topRight">
                                <b>
                                    {total !== undefined &&
                                        Math.abs(total) >= 99999 ? (
                                        <>
                                            {/* {title === "expense" ? "(" : null} */}
                                            <span className="dollar-part">{formattedTotal.split('.')[0]}</span>
                                            {Math.abs(total) >= 99999 ? "." : ""}
                                            <span className="decimal-part">{formattedTotal.split('.')[1]}</span>
                                            {/* {title === "expense" ? ")" : null} */}
                                        </>
                                    ) : (
                                        <span className="no-decimal-part">{formattedTotal}</span>
                                    )}
                                </b>
                            </Tooltip>
                                {count && <span>+{25}%</span>}
                            </>
                        )}
                    </Col>
                )}
            </Row>
        </Card>
    );
};


interface IHorizontalMenuUI {
    menuList: any;
    activeMenu: any;
    setActiveMenu: any;
}
export const HorizontalMenuUI = ({ menuList, activeMenu, setActiveMenu }: IHorizontalMenuUI) => {
    const titleRef: any = useRef()
    function handleMenuChange() {
        setTimeout(() => {
            titleRef.current.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    }
    useEffect(() => {
        handleMenuChange()
    }, [activeMenu])
    return (
        <Row className='mt-4 mb-4 accounting-menu w-100' ref={titleRef}>
            {(menuList || []).map((menu: any, index: number) =>
                <Col onClick={() => { setActiveMenu(menu) }} className={activeMenu === menu ? 'accounting-menu-button accounting-menu-button-selected pointer' : 'accounting-menu-button pointer'}>
                    {menu}
                </Col>)}
            < Col span={24} className='line' />
        </Row>
    )
}


export const AccountingMenu = ['Create Entry', 'Balance Sheet', 'Profit & Loss',
    // 'Cash Flow',
    // 'Taxes & FCs Setup',
    'Payment Methods',
]

export const AccountingHeader = [
    {
        title: 'revenue',
        Icon: <RevenueIcon />,
        price: '54K',
        percentage: '25',
        graphIcon: <RevenueGraphIcon />,
        IconBackground: '#C5DFFE',
        priceColor: '#047EB1',
    },
    {
        title: 'expense',
        Icon: <ExpenseIcon />,
        price: '54K',
        percentage: '25',
        graphIcon: <ExpenseGraphIcon />,
        IconBackground: '#FFE0E0',
        priceColor: '#BF0000',
    },
    {
        title: 'income',
        Icon: <IncomeIcon />,
        price: '54K',
        percentage: '25',
        graphIcon: <IncomeGraphIcon />,
        IconBackground: '#D8FFD8',
        priceColor: '#198658',
    },

    // {
    //     title: 'Payable',
    //     Icon: <PayableIcon />,
    //     price: '54K',
    //     percentage: '5'
    // },
    // {
    //     title: 'Receivables',
    //     Icon: <ReceivablesIcon />,
    //     price: '54K',
    //     percentage: '25'
    // },

]
interface RenderPageMenu {
    activeMenu: any
    setActiveMenu: (val: string) => void
    filter?: any
    dateRange: any
}
export const RenderPageMenu = memo(({ activeMenu, setActiveMenu, dateRange, filter = {} }: RenderPageMenu) => {
    return (
        <Col span={24} className="mt-4">
            {
                activeMenu === 'Create Entry' ?
                    <CreateEntry />
                    :
                    activeMenu === 'Balance Sheet' ?
                        <Balance setActiveMenu={setActiveMenu} dateRange={dateRange} filter={filter} />
                        :
                        activeMenu === 'Profit & Loss' ?
                            <Col>
                                <ProfitAndLoss dateRange={dateRange} filter={filter} />
                                <Col span={24}>
                                    <Balance dateRange={dateRange} filter={filter} />
                                </Col>
                            </Col>
                            :
                            activeMenu === 'Payment Methods' ?
                                <Col>
                                    <PaymentMethods />
                                </Col>
                                :
                                <Col>
                                </Col>
            }
        </Col>
    )
}, (prevProps, nextProps) => {
    return (prevProps.activeMenu === nextProps.activeMenu && prevProps.dateRange === nextProps.dateRange && prevProps.filter === nextProps.filter)
}
)

interface IAccountingHeader {
    title: string;
    price?: string | number | undefined;
}
export const RevenueTitle = ({ title, price }: IAccountingHeader) =>
    <Col span={24} className='text-w h-50 space-between vertical-center balence-Revenue-title'>
        <span className="ml-4">{title}</span>
        {price && isNumber(price) && <span className="mr-8">${price.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}</span>}
    </Col>

export const ExpenseTitle = ({ title, price }: IAccountingHeader) =>
    <Col span={24} className="text-w h-50 vertical-center space-between balence-Expense-title">
        <span className="ml-4">{title}</span>
        {price && isNumber(price) && <span className="mr-8">${price.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}</span>}
    </Col>

interface ICustomDateRange {
    setDateRange: (val: any) => void;
    selectedOption: string;
    setSelectedOption: (val: any) => void;
}
export const CustomDateRange = ({ setDateRange, selectedOption, setSelectedOption }: ICustomDateRange) => {
    const dropdownOption = [{ name: 'Last 7 Days', value: '7' }, { name: 'Last Financial Year', value: 'year' }, { name: 'Last Year', value: 'lastYear' }, { name: 'Custom', value: 'custom' }]
    return (
        <>
            {selectedOption !== 'custom' &&
                <Select value={selectedOption} placeholder='' className='p-2 accounting-custom-dateRangePicker text-bold' onSelect={setSelectedOption} >
                    {
                        dropdownOption.map((item: any, index: number) => (
                            <Select.Option key={index} value={item.value} className="m-auto w-100">{item.name}</Select.Option>
                        ))
                    }
                </Select>
            }
            {selectedOption === 'custom' &&
                <FormikRangePicker
                    format={'MM/DD/YYYY'}
                    className='h-50 accounting-date-picker b-r-5'
                    open={true}
                    onChange={(date: any, dateString: any) => { setDateRange(dateString); if (dateString[0]) setSelectedOption(`Custom (${dateString[0]}-${dateString[1]}) `) }}
                />
            }
        </>
    )
}
interface BalenceDescription {
    index: number;
    edit?: boolean;
    onDelete?: () => void;
    isId?: boolean
}
interface combinedBalenceDescription extends IAccountingHeader, BalenceDescription { }
export const BalenceDescription = ({ title, price, index, edit, onDelete, isId }: combinedBalenceDescription) => {
    return (
        <Col span={24} className='h-50 space-between vertical-center' style={index % 2 === 0 ? {} : { backgroundColor: '#F3F3F3' }}>
            <span className="ml-8 text-cap">{title}</span>
            <span className="mr-8">
                {price && <span contentEditable={edit} className="balence-edit-price">${price.toLocaleString('en-US')}</span>}
                {edit && isId && <>
                    {/* <EditOutlined className="ml-4 mr-2 font-16" /> */}
                    <DeleteOutlined className="ml-2 font-18" onClick={onDelete} />
                </>}
            </span>
        </Col>
    )
}
export const AccountingdropdownOption = [{ name: 'Last 7 Days', value: '7' }, { name: 'Last 15 Days', value: '15' }, { name: 'Last 30 Days', value: '30' }, { name: 'Custom Date Range', value: 'Custom' }]
