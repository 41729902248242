import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getChapterID,getToken,getCompanyID } from "../auth/localData";
import { history } from "@config/routes";
import { Error, Success } from "../constant/toast/Toast";


function* createUpdateSponsors({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();      

    let response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SPONSORS}/bulk_create_and_update`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_SPONSORS}}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
        //To reload the sponsors list after deleting the sponsor
      yield put({
          type: `${SagaActions.FETCH_SPONSORS}_${SagaActionType.REQUEST}`, 
          payload:{}
        });
    }
  } catch (error:any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* createFlightData({ payload }: any): Generator<any> {
  try {
    let response: any = yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CREATE_FLIGHT}`,
      {   access_token:getToken(),
          company_id: getCompanyID(),
          is_active:false,
          name: payload.level_name,
          display_as: payload.display_as,
          ranges: payload.divisions
       });
    if (response?.status === 201) {
      yield put({
        type: `${SagaActions.CREATE_FLIGHT}_${SagaActionType.SUCCESS}`,
      });
      yield call(Success,{description:'division created successfully.'});
      yield call(history.replace, `/admin/flights-divisions`);
    }
  }
  catch (error:any) {
    yield call(Error,{description:error.data.message});

  }

}

function* fetchSponsors({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(gcbAPI().get,
      `/${ApiEndpoints.ADMIN}/sponsors`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_SPONSORS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data
        },
      });
    }
  }
  catch (error: any) {
    yield call(Error, { description: error?.data?.message })
  }
}

function* deleteSponsor({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    payload.chapter_id = getChapterID();
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SPONSORS}/${payload.id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_SPONSOR}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      //To reload the sponsors list after deleting the sponsor
      yield put({
        type: `${SagaActions.FETCH_SPONSORS}_${SagaActionType.REQUEST}`, 
        payload:{}
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.error });
  }
}

function* updateSponsors({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();      
    payload.chapter_id = getChapterID();

    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.SPONSORS}/bulk_update`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.UPDATE_SPONSORS}}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
       //To reload the sponsors list after updating the sponsors
      yield put({
        type: `${SagaActions.FETCH_SPONSORS}_${SagaActionType.REQUEST}`, 
        payload:{}
      });
    }
  } catch (error:any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* CreateSponsorsRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_SPONSORS}_${SagaActionType.REQUEST}`,
    createUpdateSponsors
  );
}


function* DivisionRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_FLIGHT}_${SagaActionType.REQUEST}`,
    createFlightData
  );
}

function* UpdateSponsorsRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_SPONSORS}_${SagaActionType.REQUEST}`,
    updateSponsors
  );
}

function* FetchSponsorsRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_SPONSORS}_${SagaActionType.REQUEST}`,
    fetchSponsors
  );
}

function* DeleteSponsorRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_SPONSOR}_${SagaActionType.REQUEST}`,
    deleteSponsor
  );
}

export default function* rootSeasonManagement() {
  yield spawn(CreateSponsorsRequest);
  yield spawn(DivisionRequest);
  yield spawn(UpdateSponsorsRequest);
  yield spawn(FetchSponsorsRequest);
  yield spawn(DeleteSponsorRequest);
}