import {
  message,
  Space,
  Upload,
  UploadProps,
  Typography,
  Button,
  Row,
} from "antd";
import { toast } from "react-toastify";
import Dragger from "antd/lib/upload/Dragger";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Slide } from "react-toastify";
import { ReactComponent as EditIcon } from "@assets/icons/edit-icon.svg";
import "./styleImage.css";
import { ReactComponent as RepeatArrow } from "@assets/icons/Repeat.svg";
import globalStyle from '../../assets/style/Global.module.css'
import { useEffect, useState } from "react";
import { isString } from "@constant/helperFunction";
const { Text } = Typography;

const ImageUpload = ({
  logoBase,
  setLogoBase,
  uploadIcon,
  customText,
  customPara,
  replaceBtn,
  editBtn,
  customIcon,
  textClasName = '',
  text,
  updatePreviewUrl, // Prop to update the previewUrl in SponsorUploadModal
  setShowPreview,
  hideAllText = false,
  disabled = false,
}: any) => {
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [viewData, setViewData] = useState({} as { url: string, name: string });
  // Function for reading image from system
  const getFile = (file: any, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setLogoBase(file);
    reader.onload = function () {
      cb(reader.result);
      setPreviewUrl(reader.result as string);
      updatePreviewUrl(reader.result as string); // Update the previewUrl in SponsorUploadModal
    };
    reader.onerror = function (error) {
      console.warn("Error: ", error);
    };
    setShowPreview(true)
  };

  const removeLogo = () => {
    toast.error(
      <>
        <p>Image removed</p>
      </>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
      }
    );
    setLogoBase("");
  };

  // Fake upload
  function uploadMimic(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      uploadMimic(2000);
      onSuccess("Ok", file);
    } catch (err) {
      console.log("Error: ", err);
      onError({ err });
    }
  };

  const props: UploadProps = {
    showUploadList: false,
    customRequest: uploadImage,
    beforeUpload(file, FileList) {
      const isPNG = file.type === "image/png" || file.type === "image/jpeg";
      console.log("a", previewUrl);

      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      getFile(info.file.originFileObj, () => { });
    },
    onRemove: () => {
      removeLogo();
    },
  };

  useEffect(() => {
    if (isString(logoBase) && logoBase.includes('http')) {
      const name = logoBase.split('/').pop()
      setViewData({ url: logoBase, name: name || '' })
    }
  }, [logoBase])
  return (
    <>
      <Dragger {...props} maxCount={1} disabled={disabled}>
        <div
          style={hideAllText ? { margin: '0 0.5rem' } : {}}
          className={hideAllText ? "imag-div upload-image-wrapper flex-center" : "imag-div upload-image-wrapper"}
        >
          {logoBase ? (
            <div className="flex-center">
              <img src={previewUrl ? previewUrl : viewData?.url} style={{ height: 64, width: 64 }} />
              <div className="uploadedFileName">
                {logoBase.name
                  ? logoBase.name.length > 20
                    ? logoBase.name.substring(0, 20) + '...' // Truncate to 20 characters and add ellipsis
                    : logoBase.name
                  : viewData?.name
                    ? viewData.name.length > 20
                      ? viewData.name.substring(0, 20) + '...' // Truncate to 20 characters and add ellipsis
                      : viewData.name
                    : ''}
              </div>            </div>
          ) :
            hideAllText ? null
              :
              (customText) ? (
                <div className="uploadedFileName">
                  <div>{customText}</div>
                  {customPara && <p className="upload-hint">
                    {customPara}
                  </p>}
                </div>
              )
                : (
                  <div className={textClasName}>
                    <p className="upload-text">
                      {text ? text : 'Select a file or drag and drop Image here'}
                    </p>
                    <p className="upload-hint">
                      Images Only, file size no more than 400*400px & & &#60; 5MB
                    </p>
                  </div>
                )}

          <div className="upload-container">
            {!logoBase ? (
              <>
                <Button className={globalStyle.uploadButton}>
                  <p style={{ marginBottom: 3, marginRight: 5 }}>
                    <u>SELECT FILE</u>
                  </p>
                  <CloudUploadOutlined width={20} />
                </Button>
              </>
            ) : (
              <>
                <button className="upload-button" type="button">
                  {editBtn && <Text>
                    <u>Edit</u>
                  </Text>}
                  {replaceBtn && <Text className="upload-button">
                    {replaceBtn}
                  </Text>}
                  {customIcon ? <RepeatArrow width="16px" stroke="#004992" /> : <EditIcon width="10px" stroke="#004992" />}
                  {/* <EditIcon width="10px" stroke="#004992" /> */}
                </button>

              </>
            )}
          </div>
        </div>
      </Dragger>
    </>
  );
};

export default ImageUpload;
