import { createSlice } from "@reduxjs/toolkit";
import { SagaActions, SagaActionType } from "@sagas/index";

const initialState: any = {
  userData:{},
  compnayDetails:{},
  basicDetails:{},
  isLoading:false,
  isAcceptInvitation:false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: action.payload, isLoading:false };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return { ...state, isLoading:true };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.AUTHENTICATE_USER}_${SagaActionType.FAIL}`,
      (state, action) => {
        return { ...state, isLoading:false };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.FORGET_PASSWORD}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: action.payload };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.RESET_PASSWORD}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: action.payload };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VERIFY_EMAIL}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: action.payload };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_USER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, compnayDetails: {... action.payload} };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.STORE_BASIC}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, basicDetails: {... action.payload} };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_NEW_USER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: action.payload };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.SIGNOUT_USER}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, userData: {} };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ACCEPT_INVITATION}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return { ...state, isAcceptInvitation: false };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ACCEPT_INVITATION}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return { ...state, isAcceptInvitation: true };
      }
    );
  },

});

export default authSlice.reducer;
