import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

interface AccountingState {
  BudgetData: any,
  isBudgetLoading: boolean,
  tournamentId: string,
  isEntryAdded: boolean,
  mainAccountings: any,
  isMainAccountingsLoading: any,
  tournamentAccountings: any,
  istournamentAccountingsLoading: any,
  BalanceSheet: any[],
  isBalanceSheetLoading: boolean,
  balanceSheetId: string,
  isProfitAndLossLoading: boolean,
  ProfitAndLoss: any[],
  BalanceSheetData: {
    expense: any,
    revenue: any,
  },
  BalanceSheetDataLoading: boolean,
}
const initialState: AccountingState = {
  BudgetData: [],
  isBudgetLoading: true,
  tournamentId: '',
  isEntryAdded: false,
  mainAccountings: {},
  tournamentAccountings: {},
  isMainAccountingsLoading: false,
  istournamentAccountingsLoading: false,
  BalanceSheet: [],
  isBalanceSheetLoading: false,
  balanceSheetId: '',
  isProfitAndLossLoading: false,
  ProfitAndLoss: [],
  BalanceSheetData: {
    expense: [],
    revenue: [],
  },
  BalanceSheetDataLoading: false,
};

export const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_BUDGET}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isBudgetLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.VIEW_BUDGET}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isBudgetLoading: false,
          BudgetData: action.payload.data,
          tournamentId: action.payload.tournamentId
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isEntryAdded: false,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.ACCOUNTING_TRANSACTIONS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isEntryAdded: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_COMPANY_CHAPTER_ACCOUNTINGS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          mainAccountings: action.payload,
          ismainAccountingsLoading: false,
          balanceSheetId: '',
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_COMPANY_CHAPTER_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          ismainAccountingsLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_TOURNAMENT_ACCOUNTINGS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          tournamentAccountings: action.payload,
          istournamentAccountingsLoading: false,
          tournamentId: '',
          balanceSheetId: '',
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_TOURNAMENT_ACCOUNTINGS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          istournamentAccountingsLoading: true,
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.BALANCE_SHEET}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isBalanceSheetLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.BALANCE_SHEET}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isBalanceSheetLoading: false,
          BalanceSheet: action.payload.data,
          balanceSheetId: action.payload.tournamentId
        };
      }
    );

    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isProfitAndLossLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.TOURNAMENT_PROFIT_AND_LOSS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isProfitAndLossLoading: false,
          ProfitAndLoss: action.payload.data,
        };
      }
    );


    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.COMPANY_CHAPTER_PROFIT_AND_LOSS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          ProfitAndLoss: action.payload,
          isProfitAndLossLoading: false,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.COMPANY_CHAPTER_PROFIT_AND_LOSS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isProfitAndLossLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_CHAPTER_EXPENSES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const data = action.payload;
        const { revenue } = state.BalanceSheetData;
        return {
          ...state,
          BalanceSheetData: {
            revenue,
            expense: data,
          },
          BalanceSheetDataLoading: false,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_CHAPTER_REVENUES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const data = action.payload;
        const { expense } = state.BalanceSheetData;
        return {
          ...state,
          BalanceSheetData: {
            revenue: data,
            expense,
          },
          BalanceSheetDataLoading: false,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_CHAPTER_EXPENSES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          BalanceSheetDataLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_CHAPTER_REVENUES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          BalanceSheetDataLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { revenue, expense } = state.BalanceSheetData;
        const { data, id, flag } = action.payload;
        let newArray = [];
        if (flag) {
          newArray = [{ id: 'fix', name: 'All Tournaments Expenses', data: [...data] }];
        }
        else
          for (let i in expense) {
            if (expense[i]?.id === parseInt(id)) {
              newArray.push({ ...expense[i], data: [...data] });
            } else {
              newArray.push(expense[i]);
            }
          }
        return {
          ...state,
          BalanceSheetDataLoading: false,
          BalanceSheetData: {
            revenue,
            expense: [...newArray],
          }
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { revenue, expense } = state.BalanceSheetData;
        const { data, id, flag } = action.payload;
        let newArray = [];
        if (flag) {
          newArray = [{ id: 'fix', name: 'All Tournament Revenues', data: [...data] }];
        }
        else
          for (let i in revenue) {
            if (revenue[i]?.id === parseInt(id)) {
              newArray.push({ ...revenue[i], data: [...data] });
            } else {
              newArray.push(revenue[i]);
            }
          }
        return {
          ...state,
          BalanceSheetDataLoading: false,
          BalanceSheetData: {
            revenue: [...newArray],
            expense,
          }
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_EXPENSES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          BalanceSheetDataLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.LIST_TOURNAMENT_REVENUES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          BalanceSheetDataLoading: true,
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        const { revenue, expense }: any = state.BalanceSheetData;
        const { data, id, isExpense } = action.payload;
        let newArray: any = isExpense ? JSON.parse(JSON.stringify(expense)) : JSON.parse(JSON.stringify(revenue));
        if (isExpense) {
          for (let i in expense) {
            for (let j in expense[i]?.data) {
              if (expense[i]?.data[j]?.id === parseInt(id)) {
                newArray[i].data[j] = { ...expense[i].data[j], data: [...data.expense.expenses, { total_amount: data.expense.total }] };
              }
            }
          }
        } else {
          for (let i in revenue) {
            for (let j in revenue[i]?.data) {
              if (revenue[i]?.data[j]?.id === parseInt(id)) {
                newArray[i].data[j] = { ...revenue[i].data[j], data: [...data.revenue.revenues, { total_amount: data.revenue.total }] };
              }
            }
          }
        }
        return {
          ...state,
          BalanceSheetDataLoading: false,
          BalanceSheetData: {
            revenue: isExpense ? [...revenue] : [...newArray],
            expense: isExpense ? [...newArray] : [...expense],
          }
        };
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.DETAILS_TOURNAMENT_EXPENSES_REVENUES}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          BalanceSheetDataLoading: true,
        };
      }
    );
  },
})

export default accountingSlice.reducer;

