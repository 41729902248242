import FormikSelect from "./FormikSelect";
import { FieldInputProps, FormikTouched, FormikErrors } from 'formik';
import NPFormikSelectArr from "./NPFormikSelectetArr";

interface SelectProps {
    label: string,
    className?: string,
    placeholder?: string,
    errors?: FormikErrors<any>,
    fname: string, // field name
    touched?: FormikTouched<any>,
    getFieldProps?: (name: string) => FieldInputProps<any>,
    options: any[],
    autoComplete?: string,
    setFieldValue: (name: string, val: string) => void,
    optionName?: string,
    valueName?: string,
    disabled?:boolean,
}

const NPSelectM: React.FC<SelectProps> = ({ errors = {}, fname, touched = {}, getFieldProps = () => {},disabled, ...props }) => {

    return(
    <NPFormikSelectArr
        autoComplete="off"
        error={
            Boolean(errors[fname] && touched[fname]) &&
            errors[fname]
        }
        fieldValue={fname}
        {...getFieldProps(fname)}
        {...props}
        disabled={disabled}
    />)
}

export default NPSelectM;
