import React, { useMemo } from 'react';
import { MainLayout } from '@components/layouts';
import PageHeader from '@components/page/PageHeader';
import { FormikProvider } from 'formik';
import { Checkbox, Col, Row } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Radio, Button, Spin } from 'antd';
import FormikTextField from '@components/inputFields/FormikTextField';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SagaActionType, SagaActions } from '@sagas/index';
import { isC_Admin, isC_Owner } from '@auth/permission';
import { getChapterID } from '@auth/localData';

function ImportContact() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isImporting } = useSelector((state: any) => state.Marketing);
    const canSelectChapter = isC_Admin() || isC_Owner();
    const formikMain = useFormik({
        initialValues: {
            list_name: '',
            group_name: '',
            csv_file: undefined,
            checked: false,
        },
        onSubmit: async () => {
            const { checked, ...payload } = values
            if (!isImporting) {
                dispatch({
                    type: `${SagaActions.IMPORT_CONTACTS}_${SagaActionType.REQUEST}`,
                    payload: { ...payload, chapter_id: canSelectChapter ? undefined : getChapterID(), has_header: true }
                });
            }
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            list_name: yup.string().required('List Name Required'),
            group_name: yup.string().required('Group Name Required'),
            csv_file: yup.mixed().required('Contact File Required'),
            checked: yup.boolean().oneOf([true], 'Please Enter Mandatory Details*'),
        }),
    });

    const propsq: UploadProps = {

    };
    const { getFieldProps, handleSubmit, values, setFieldValue, errors, touched, setErrors, setValues } = formikMain;
    const allErrors = useMemo(() => {
        return (errors['checked'] || errors['csv_file'] || errors['list_name'] || errors['group_name']);
    }, [errors]);
    return (
        <MainLayout>
            <>
                <PageHeader name="Import Contacts" />
                <Col md={24} lg={12} xxl={8} >
                    <FormikProvider value={formikMain}>
                        <form className='com-form-container eventcreate input50' onSubmit={handleSubmit}>
                            <Col span={24} className='mb-10'>
                                {allErrors && <div className='ant-form-item-explain-error'>Please Enter Mandatory Details*</div>}
                            </Col>
                            <Col span={24} className='mb-6'>
                                {/* <Row className='mb-2 black-radio'>
                                <label className="mb-2">
                                    <p className="text-field-label radio">
                                        Uploaded csv contains header?
                                    </p>
                                </label>
                                <Radio.Group
                                    onChange={(e) => { resetForm(); setFieldValue('transaction_record_type', e.target.value) }}
                                    value={values?.transaction_record_type}
                                    className="mb-4 w-100"
                                >
                                    <Row>
                                        <Col sm={12}><Radio value={'yes'}>Yes</Radio></Col>
                                        <Col sm={12}><Radio value={'no'}>No</Radio></Col>
                                    </Row>
                                </Radio.Group>
                            </Row> */}
                                <FormikTextField
                                    label='List Name'
                                    placeholder="Marketing List 1"
                                    className={'d-border b-r-5 input-name-padding'}
                                    fieldValue="list_name"
                                    error={
                                        Boolean(errors['list_name'] && touched['list_name']) &&
                                        errors['list_name']
                                    }
                                    {...getFieldProps("list_name")}
                                />
                                <FormikTextField
                                    label='group Name'
                                    placeholder="New Players 2023"
                                    className='d-border b-r-5'
                                    fieldValue="group_name"
                                    error={
                                        Boolean(errors['group_name'] && touched['group_name']) &&
                                        errors['group_name']
                                    }
                                    {...getFieldProps("group_name")}
                                />
                            </Col>
                            <div className="mb-10">
                                <label>
                                    <p className="text-field-label">
                                        Upload CSV
                                    </p>
                                </label>
                                <Upload
                                    maxCount={1}
                                    {...propsq}
                                    onChange={(info) => {
                                        if (info.fileList.length > 0)
                                            setFieldValue('csv_file', info.file.originFileObj);
                                        else
                                            setFieldValue('csv_file', undefined);
                                    }}
                                    className='csv-upload-wrapper'
                                    accept=".csv"
                                >
                                    <Row className='border-dash space-between p-3 b-r-5'>
                                        <Col className='flex-start mr-8'>
                                            <p className='font-16'>Select a CSV file or drag and drop it here</p>
                                            <p className='opacity-5 font-14'>CSV with following headers only</p>
                                            <b className='opacity-5 font-14'>First Name | Last Name  | Email | Phone </b>
                                        </Col>
                                        <Col className='flex-end'>
                                            <Button className="csvButton text-skyBlue my-auto">
                                                Select file
                                                <CloudUploadOutlined
                                                    className="icon-upload"
                                                    style={{
                                                        fontSize: "17px",
                                                    }}
                                                />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Upload>
                                {errors['csv_file'] && <div className='ant-form-item-explain-error'>{errors['csv_file']}</div>}
                            </div>
                            <Col>
                                <Checkbox className='p-abs' onClick={(e: any) => { setFieldValue('checked', e.target.checked) }} />
                                <span className='ml-8 flex'>I acknowledge that the above data is aligned with headers and I have permission to add them to the subscription list.</span>
                            </Col>
                            <Col span={24} className='mt-10'>
                                <Button className='raw-button btnSpin' htmlType="submit" style={{ background: '#047EB1', color: '#fff' }} >
                                    {isImporting ? <Spin size='small' /> : 'Import Contacts'}
                                </Button>
                                <Link to='/admin/' onClick={(e: any) => { e.preventDefault(); navigate(-1) }} className="com-cancel" style={{ marginLeft: '2rem' }}>
                                    Cancel
                                </Link>
                            </Col>
                        </form>
                    </FormikProvider>
                </Col>
            </>
        </MainLayout>
    );
}
export default ImportContact;
