import MainLayout from "@components/layouts/MainLayout";
import { Col, Row, Radio, Typography, Spin } from "antd";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import FormikSelect from "@components/inputFields/FormikSelect";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { createContest } from "@slices/userInfoSlice";
import PageHeader from "@components/page/PageHeader";
import FormikPayoutMatrix from "../components/FormikPayoutMatrix";
import FormikTextField from "@components/inputFields/FormikTextField";
import { SagaActionType, SagaActions } from "@sagas/index";
import { getIDfromURL } from "@constant/url";
import { useAppSelector } from "@utils/reduxHooks";

const { Text } = Typography;

const CreatePrizesAndAwards = () => {
  const { PRIZES_AWARDS } = useParams<any>();
  const ID = getIDfromURL(PRIZES_AWARDS);
  const dispatch = useDispatch<AppDispatch>();
  const { PrizesAwardsCardData, isLoading } = useAppSelector((state: any) => state.prizeAwards);
  const scoringType = [
    "Stroke Play (Single Player Only)",
    "Modified Stableford (Single Player)",
    "Stableford (Single Player)",
  ];
  const fieldsPositionsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const timeZone = [
    "Eastern Standard Time – EST (UTC-5)",
    "Central Standard Time – CST (UTC-6)",
    "Mountain Standard Time – MST (UTC-7)",
    "Pacific Standard Time – PST (UTC-8)",
    "Alaska Standard Time – AKST (UTC-9)",
    "Hawaii-Aleutian Standard Time – HAST (UTC-10)",
  ];

  const formikMain = useFormik({
    initialValues: {
      name: "",
      distribution_type: "percentage",
      distribution: "prize_pool",
      max_position: 5,
      max_fields: 5,
      max_pool_limit: 0,
      distributions: [],
    },
    onSubmit: async (values) => {
      if (!isLoading)
        dispatch({
          type: `${SagaActions.CREATE_PAYOUT_MATRIX}_${SagaActionType.REQUEST}`,
          payload: { ...values, id: ID },
        });
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required("*Payout matrix must have a name"),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
    values,
  } = formikMain;

  useEffect(() => {
    if (ID) {
      dispatch({
        type: `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.REQUEST}`,
        payload: { id: ID },
      });
    }
  }, [ID]);
  useEffect(() => {
    if (PrizesAwardsCardData?.id === ID) {
      setFieldValue("name", PrizesAwardsCardData?.name)
      setFieldValue("distribution_type", PrizesAwardsCardData?.distribution_type)
      setFieldValue("distribution", PrizesAwardsCardData?.distribution)
      setFieldValue("max_position", PrizesAwardsCardData?.max_position)
      setFieldValue("max_fields", PrizesAwardsCardData?.max_fields)
      setFieldValue("max_pool_limit", PrizesAwardsCardData?.max_pool_limit)
      setFieldValue("distributions", PrizesAwardsCardData?.payout_matrix_distributions)
    }
  }, [PrizesAwardsCardData])
  return (
    <MainLayout>
      <div className="community-container">
        <PageHeader
          name={ID ? "Edit Payout Matrix" : "Create Payout Matrix"}
          style={{ marginBottom: 20 }}
        />
        <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className="form-container">
            <Row>
              <Col xl={16}>
                <Row>
                  <Col sm={14} className="input50">
                    <label className="text-field-label-custom">
                      <p className="text-field-label-custom">
                        Name of payout matrix
                      </p>
                    </label>
                    <FormikTextField
                      className="community-input-name"
                      autoComplete="off"
                      error={
                        Boolean(errors.name && touched.name) && errors.name
                      }
                      placeholder="Enter a name for the payout matrix"
                      {...getFieldProps("name")}
                    />
                  </Col>
                </Row>
                <Row className="form-row first-oc black-radio">
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <label>
                      <p className="text-field-label-custom radio">
                        Distribution Type
                      </p>
                    </label>
                    <Radio.Group
                      defaultValue="percentage"
                      buttonStyle="solid"
                      {...getFieldProps("distribution_type")}
                    >
                      <div className="radio-options">
                        <Col xl={15}>
                          <Radio value="percentage" style={{ width: "100%" }}>
                            By Percentage
                          </Radio>
                        </Col>
                        <Col span={20}>
                          <Radio value="points" style={{ width: "100%" }}>
                            By Numbers
                          </Radio>
                        </Col>
                      </div>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row className="form-row  black-radio">
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <label>
                      <p className="text-field-label-custom radio">
                        Distribution
                      </p>
                    </label>
                    <Radio.Group
                      defaultValue="prize_pool"
                      buttonStyle="solid"
                      {...getFieldProps("distribution")}
                    >
                      <div className="radio-options">
                        <Col xl={15}>
                          <Radio value="prize_pool" style={{ width: "100%" }}>
                            Use Total Prize Pool
                          </Radio>
                        </Col>
                        <Col span={24}>
                          <Radio value="custom" style={{ width: "100%" }}>
                            Custom
                          </Radio>
                        </Col>
                      </div>
                    </Radio.Group>
                  </Col>
                </Row>
                {formikMain.values.distribution === "custom" && (
                  <Row className="form-row">
                    <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                      <label>
                        <p className="text-field-label-custom radio">
                          Enter max Pool Limit for Payout
                        </p>
                      </label>
                      <FormikTextField
                        className="community-input-name input48"
                        autoComplete="off"
                        error={
                          Boolean(errors.name && touched.name) && errors.name
                        }
                        prefix={"$"}
                        placeholder="Enter a name for the payout matrix"
                        {...getFieldProps("max_pool_limit")}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="form-row">
                  <Col xl={7} lg={6} sm={20} className='mr-2'>
                    <FormikSelect
                      label="Maximum Fields/ Players"
                      autoComplete="off"
                      options={fieldsPositionsOptions}
                      setFieldValue={setFieldValue}
                      fieldValue="max_fields"
                      {...getFieldProps("max_fields")}
                    />
                  </Col>
                  <Col xl={7} lg={6} sm={20}>
                    <FormikSelect
                      label="Maximum Positions"
                      autoComplete="off"
                      options={fieldsPositionsOptions}
                      setFieldValue={setFieldValue}
                      fieldValue="max_position"
                      {...getFieldProps("max_position")}
                    />
                  </Col>

                  <Col xl={24}>
                    <FormikPayoutMatrix
                      setFieldValue={setFieldValue}
                      data={formikMain.values}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={24}>
                <Row>
                  <Col xl={6}>
                    {/* <Link to={"/admin/chapters/arkansas"}> */}
                    <button className="chapter-button btnSpin" type="submit">
                      {isLoading ? <Spin /> : 'Save'}
                    </button>
                    {/* </Link> */}
                    <Link to="/admin/prizes-awards">
                      <button className="chapter-cancel">Cancel</button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col></Col>
            </Row>
          </form>
        </FormikProvider>
      </div>
    </MainLayout>
  );
  //end
};

export default CreatePrizesAndAwards;
