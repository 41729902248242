import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';

function* fetchPlayersStats({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/player_details`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_PLAYERS_STATS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchPlayers({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/${ApiEndpoints.REGISTERED_PLAYERS}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
          page: response.data.page,
          total: response.data.total_count
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* fetchPlayersCSV({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/download_player_csv`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PLAYERS_CSV}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}


function* fetchPlayersComments({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYERS
      }/${payload.id}/${ApiEndpoints.COMMENTS}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.VIEW_COMMENT}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}


function* createComment({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COMMENTS}`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_COMMENT}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the players Table after creating the comment
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_COMMENT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* editComment({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COMMENTS}/${payload.id}`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'Comment edited successfully.' });
      yield put({
        type: `${SagaActions.EDIT_COMMENT}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the View Comment after editing the comment
      yield put({
        type: `${SagaActions.VIEW_COMMENT}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.playerId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.EDIT_COMMENT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* deleteComment({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const id = payload.id
    const response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.COMMENTS}/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.DELETE_COMMENT}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
      //To reload the View Comment after deleting the comment
      yield put({
        type: `${SagaActions.VIEW_COMMENT}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.playerId
        },
      });
      //To reload the players Table after deleting the comment
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.DELETE_COMMENT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* waitlistToRegistered({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYERS}/${payload.id}/move_from_waitlist`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.WAITLIST_TO_REGISTERED}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the players Table after moving waitlist to registered
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
      //To reload the players Stats after waitlist to registered
      yield put({
        type: `${SagaActions.FETCH_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_COMMENT}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}




function* sendMailOrSMS({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.TOURNAMENTS
      }/${payload.id}/send_player_email`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.SEND_EMAIL_SMS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error: any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* updatePlayerStatus({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYERS}/${payload.id}/update_player_status`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.UPDATE_PLAYER_STATUS}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the players Table after creating the comment
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.UPDATE_PLAYER_STATUS}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* removePlayer({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PLAYERS}/${payload.id}/remove_player`,
      { ...payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.REMOVE_PLAYER}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the players Table after creating the comment
      yield put({
        type: `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
        payload: {
          id: payload.tournamentId
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.REMOVE_PLAYER}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}




function* PlayerStatsRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_PLAYERS_STATS}_${SagaActionType.REQUEST}`,
    fetchPlayersStats
  );
}

function* PlayersRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_REGISTERED_PLAYERS}_${SagaActionType.REQUEST}`,
    fetchPlayers
  );
}

function* PlayersCSVRequest() {
  yield takeLatest(
    `${SagaActions.PLAYERS_CSV}_${SagaActionType.REQUEST}`,
    fetchPlayersCSV
  );
}

function* PlayersCommentsRequest() {
  yield takeLatest(
    `${SagaActions.VIEW_COMMENT}_${SagaActionType.REQUEST}`,
    fetchPlayersComments
  );
}

function* CreateCommentRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_COMMENT}_${SagaActionType.REQUEST}`,
    createComment
  );
}

function* EditCommentRequest() {
  yield takeLatest(
    `${SagaActions.EDIT_COMMENT}_${SagaActionType.REQUEST}`,
    editComment
  );
}

function* DeleteCommentRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_COMMENT}_${SagaActionType.REQUEST}`,
    deleteComment
  );
}

function* WaitListToRegisteredRequest() {
  yield takeLatest(
    `${SagaActions.WAITLIST_TO_REGISTERED}_${SagaActionType.REQUEST}`,
    waitlistToRegistered
  );
}

function* SendEmailOrSMSRequest() {
  yield takeLatest(
    `${SagaActions.SEND_EMAIL_SMS}_${SagaActionType.REQUEST}`,
    sendMailOrSMS
  );
}

function* UpdatePlayerStatusRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_PLAYER_STATUS}_${SagaActionType.REQUEST}`,
    updatePlayerStatus
  );
}

function* RemovePlayerRequest() {
  yield takeLatest(
    `${SagaActions.REMOVE_PLAYER}_${SagaActionType.REQUEST}`,
    removePlayer
  );
}

export default function* rootRegisteredPlayers() {
  yield spawn(PlayerStatsRequest);
  yield spawn(PlayersRequest);
  yield spawn(PlayersCSVRequest);
  yield spawn(PlayersCommentsRequest);
  yield spawn(CreateCommentRequest);
  yield spawn(EditCommentRequest);
  yield spawn(DeleteCommentRequest);
  yield spawn(WaitListToRegisteredRequest);
  yield spawn(SendEmailOrSMSRequest);
  yield spawn(UpdatePlayerStatusRequest);
  yield spawn(RemovePlayerRequest);
}
