import { CloseOutlined } from "@ant-design/icons";
import { notification } from "antd";

interface ToastProps {
    message?: string;
    description: string;
    duration?: number;
}

export const Success: React.FC<ToastProps> = ({ message = "Success", description = "", duration = 3 }) => {
    notification.success({
        message,
        description,
        style: { borderLeft: '0.5rem solid #DDF7E0', borderRadius: 4 },
        duration,
    });
    return null
};
export const Error: React.FC<ToastProps> = ({ message = "Error!", description = "", duration = 5 }) => {

    const closeNotification = () => {
        notification.close('error');
    };

    const content = (
        <div>
            {description && <p>{description}</p>}
            <CloseOutlined onClick={closeNotification} style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '20px' }} />
        </div>
    );
    notification.error({
        key: 'error',
        message,
        description: content,
        style: { borderLeft: '0.5rem solid #FFA6A6', borderRadius: 4 },
        duration: duration,
    });
    return null;
};

export const Waraning: React.FC<ToastProps> = ({ message = "Waraning", description = "", duration = 4 }) => {
    notification.warning({
        message,
        description,
        style: { borderLeft: '0.5rem solid #fcdc9b', borderRadius: 4 },
        duration,
    });
    return null
};