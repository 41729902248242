import { takeLatest, call, put, spawn } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getCompanyID, getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';

const usersPayload: any = {
  page: 1,
  per_page: 10,
}

function* fetchUserData({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.COMPANIES
      }/${getCompanyID()}/list_users`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.USERS_FETCH}_${SagaActionType.SUCCESS}`,
        payload: {
          page: response.data.page,
          data: response.data.data,
          total: response.data.total_count,
        },
      });
    }
  } catch (error) {
    yield call(Error, { description: 'Something went wrong' });
  }
}
function* deleteUser({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().delete,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${payload.id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'User deleted successfully.' });
      yield put({
        type: `${SagaActions.USERS_DELETE}_${SagaActionType.SUCCESS}`,
        payload: {},
      });
    }
  } catch (error) {
    yield call(Error, { description: 'Something went wrong' });
  }
}
function* editUser({ payload,id }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}/${id}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }    
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'User edited successfully.' });
      yield put({
        type: `${SagaActions.USERS_UPDATE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
       //To reload the Users Table after editing the user
       yield put({
        type: `${SagaActions.USERS_FETCH}_${SagaActionType.REQUEST}`, payload:usersPayload
      });
    }
  } catch (error) {}
}

function* createUserAPI({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.USERS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.USERS_CREATE}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      //To reload the Users Table after creating the user
      yield put({
        type: `${SagaActions.USERS_FETCH}_${SagaActionType.REQUEST}`, payload:usersPayload
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.USERS_CREATE}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* userInfo({ payload ,id}: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.USERS
      }/${id}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.USER_INFO_FETCH}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error) {
    yield call(Error, { description: 'Something went wrong' });
  }
}

function* UserManagementRequest() {
  yield takeLatest(
    `${SagaActions.USERS_FETCH}_${SagaActionType.REQUEST}`,
    fetchUserData
  );
}
function* deleteUserRequest() {
  yield takeLatest(
    `${SagaActions.USERS_DELETE}_${SagaActionType.REQUEST}`,
    deleteUser
  );
}
function* editUserRequest() {
  yield takeLatest(
    `${SagaActions.USERS_UPDATE}_${SagaActionType.REQUEST}`,
    editUser
  );
}

function* createUserRequest() {
  yield takeLatest(
    `${SagaActions.USERS_CREATE}_${SagaActionType.REQUEST}`,
    createUserAPI
  );
}


function* userInfoRequest() {
  yield takeLatest(
    `${SagaActions.USER_INFO_FETCH}_${SagaActionType.REQUEST}`,
    userInfo
  );
}

export default function* rootUserManagement() {
  yield spawn(UserManagementRequest);
  yield spawn(deleteUserRequest);
  yield spawn(editUserRequest);
  yield spawn(createUserRequest);
  yield spawn(userInfoRequest);
}
