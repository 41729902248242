import {
  takeLatest,
  call,
  put,
  spawn,
} from "redux-saga/effects";
import {  SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { Error } from '../constant/toast/Toast';


function* fetchIgolfCountriesList({ payload }: any): Generator<any> {
  try {
      
      let response: any = yield call(gcbAPI().get,
          `/igolf_countries_list`,
          { params: payload});
      if (response?.status === 200) {
          yield put({
              type: `${SagaActions.IGOLF_COUNTRIES}_${SagaActionType.SUCCESS}`,
              payload: {
                  data: response.data.data
              },
          });
      }
  }
  catch (error:any) {
    if(error?.status === 401){
      yield call(Error, {message:`${error?.statusText}!`,description:'',duration:10})
    }else{
      yield call(Error, { description: 'Something went wrong' });
    }
  }
}

function* fetchIgolfStatesList({ payload }: any): Generator<any> {
  try {
      
      let response: any = yield call(gcbAPI().get,
          `/igolf_states_list`,
          { params: payload});
      if (response?.status === 200) {
          yield put({
              type: `${SagaActions.IGOLF_STATES}_${SagaActionType.SUCCESS}`,
              payload: {
                  data: response.data.data
              },
          });
      }
  }
  catch (error:any) {
    if(error?.status === 401){
      yield call(Error, {message:`${error?.statusText}!`,description:'',duration:10})
    }else{
      yield call(Error, { description: 'Something went wrong' });
    }
  }
}

function* IgolfCountriesRequest() {
  yield takeLatest(
      `${SagaActions.IGOLF_COUNTRIES}_${SagaActionType.REQUEST}`,
      fetchIgolfCountriesList
  );
}
function* IgolfStatesRequest() {
  yield takeLatest(
      `${SagaActions.IGOLF_STATES}_${SagaActionType.REQUEST}`,
      fetchIgolfStatesList
  );
}

export default function* rootIgolfData() {
  yield spawn(IgolfCountriesRequest);
  yield spawn(IgolfStatesRequest);
}