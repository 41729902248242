import React, { useState } from "react";
import { ReactComponent as CancelArrowIcon } from "@assets/icons/r3.svg";
import { ReactComponent as GreenArrowIcon } from "@assets/icons/r2.svg";
import { ReactComponent as GreyArrowIcon } from "@assets/icons/r4.svg";
import { ReactComponent as RoundCheckbox } from "@assets/icons/r1.svg";

export const CheckboxButton = (props: any) => {
  const [selected, setSelected] = useState<boolean | null>(props.value);
  // const selected = props.value;
  return (
    <div
      onClick={() => {
        // if (selected) {
        //   setSelected(!selected);
        // } else if (selected == null) {
        //   setSelected(true);
        // } else {
        //   setSelected(!selected);
        // }
        props.onClick();
      }}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      {selected == null && !props.disabled && <RoundCheckbox />}
      {selected && !props.disabled && <GreenArrowIcon />}
      {selected !== null && !selected && !props.disabled && <CancelArrowIcon />}
      {props.disabled && <GreyArrowIcon />}
      <label
        style={{
          marginLeft: 10,
        }}
      >
        {props.label}
      </label>
    </div>
  );
};
