import { createSlice } from "@reduxjs/toolkit";
import { SagaActionType, SagaActions } from "@sagas/index";

export interface createTournamentsInterface {
  data: {}; // tournament Data
  isLoading: boolean; // loader
}
const initialState: createTournamentsInterface = {
  data: {},
  isLoading: false,

};
export const createTournamentslice = createSlice({
  name: "createEvent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          data: action.payload.data,

        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.CREATE_TOURNAMENNT}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,

        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.TOURNAMENT_BUDGETS}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_TOURNAMENT_BY_ID}_${SagaActionType.SUCCESS}`,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          data: action.payload.data,
        };;
      }
    );
    builder.addMatcher(
      (action) =>
        action.type ===
        `${SagaActions.GET_TOURNAMENT_BY_ID}_${SagaActionType.REQUEST}`,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };;
      }
    );
  },
})

export default createTournamentslice.reducer;  