import { Card, Col, Row, Button, Popover, Tooltip, Spin, Modal, Table } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as AddNewContest } from "@assets/icons/AddNew.svg";
import { ReactComponent as SortClock } from "@assets/icons/sort-clock-ascending-outline.svg";
import { ReactComponent as SortAlphaAscending } from "@assets/icons/sort-alphabetical-ascending.svg";
import { ReactComponent as ActiveSortAlphaDescending } from "@assets/icons/ActiveZ-A.svg";
import { ReactComponent as ActiveSortAlphaAscending } from "@assets/icons/ActiveA-Z.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as MenuIcon } from "@assets/icons/charm_menu-meatball.svg";
import MainLayout from "@components/layouts/MainLayout";
import "../flightsDivisions/styles.css";
import "./styles.css"
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { SagaActions, SagaActionType } from "@sagas/index";
import { useAppSelector } from "@utils/reduxHooks";
import PageHeader from "@components/page/PageHeader";
import PayoutMatrixTable from "./PayoutMatrixTable";
import { URL_BINDER } from "@constant/url";

const AdminPrizesAwards = () => {

    const dispatch = useDispatch();
    const { PrizesAwardsData, isActiveLoading, isLoading, PrizesAwardsCardData } = useAppSelector((state: any) => state.prizeAwards);
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setIsAscending] = useState<boolean | null>(null);
    const [open, setOpen] = useState<string | null>(null);
    const navigation = useNavigate();

    const sortedPrizesAwardCards = [...PrizesAwardsData]?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (isAscending === null) {
            return 0; // No sorting when isAscending is null
        }
        return isAscending ? (nameA < nameB ? -1 : 1) : nameA > nameB ? -1 : 1;
    });

    const handleViewClick = (id: any) => {
        showModal(id);
        dispatch({
            type: `${SagaActions.PAYOUT_MATRIX_ID}_${SagaActionType.REQUEST}`,
            payload: { id }
        });
    }

    const showModal = (id: any) => {
        setOpen(id);
    };

    const hideModal = () => {
        setOpen(null);
    };

    useEffect(() => {
        if (!isActiveLoading) {
            dispatch({ type: `${SagaActions.PRIZES_AWARDS}_${SagaActionType.REQUEST}` });
        }
    }, [isActiveLoading]);

    const DeleteCard = (id: any) => {
        dispatch({
            type: `${SagaActions.DELETE_PRIZES_AWARDS}_${SagaActionType.REQUEST}`,
            payload: { id }
        });
    }
    return (
        <MainLayout>
            <>
                <Row gutter={24}>
                    <Col xl={18} sm={24}>
                        <PageHeader name='Prizes & Awards' />
                    </Col>
                    <Col xl={6} className="fd-icon-section">
                        <div className="content" style={{ width: 200 }}>
                            <div className="search" >
                                <input type="text" className="search__input" aria-label="search" placeholder="Input search text" onChange={(event) => {
                                    setSearchTerm(event.target.value);
                                }} />
                                <button className="search__submit" aria-label="submit search"><SearchIcon /></button>
                            </div>
                        </div>
                        <div className="fd-icon" onClick={() => {
                            const sortedCards = [...PrizesAwardsData];
                            if (isAscending) {
                                sortedCards.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                            } else {
                                sortedCards.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1);
                            }
                            setIsAscending(!isAscending);
                        }}>
                            {isAscending ? (
                                <Tooltip placement="bottom" title="Sorted A-Z " >
                                    <ActiveSortAlphaAscending />
                                </Tooltip>
                            ) : isAscending === false ? (
                                <Tooltip placement="bottom" title="Sorted Z-A " >
                                    <ActiveSortAlphaDescending />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="bottom" title="Sort A to Z " >
                                    <SortAlphaAscending />
                                </Tooltip>
                            )}
                        </div>
                        {/* <SortClock className="fd-icon" /> */}
                    </Col>
                </Row>
                {isLoading ? <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }} /> : <Row gutter={[30, 30]} className="flights-divisions">
                    <Col xl={6} lg={10} md={12} sm={24} xs={24} >
                        <Card className="fdcard-container">
                            <div className="fdcard-container-inside">
                                <div className="fd-add-container">
                                    <Link to="/admin/prizes-awards/create">
                                        <AddNewContest className="add-new-fd-image" />
                                    </Link>
                                    <p className="add-new-fd-text">Add Prizes & Awards Setup</p>
                                </div>
                            </div>
                        </Card>
                    </Col>


                    {sortedPrizesAwardCards
                        .filter((prizesAwardsCards) => {
                            if (searchTerm === "") {
                                return true;
                            } else if (prizesAwardsCards.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return true;
                            }
                            return false;
                        })
                        .map((prizesAwardsCard: any, id: any) => (

                            <Col xl={6} lg={10} md={12} sm={24} xs={24} key={prizesAwardsCard.id} >
                                <Card className="fdcard-container p-rel">
                                    <div className="fdcard-container-inside">
                                        <div className="flex align-bottom h-100">
                                            <Popover content={
                                                <>
                                                    <div className="FDchanges-button-container">
                                                        <Button className="changes-button pointer" onClick={() => handleViewClick(prizesAwardsCard.id)}>
                                                            View
                                                        </Button>
                                                        <Button className="changes-button pointer text-skyBlue" onClick={() => { navigation(`/admin/prizes-awards/${prizesAwardsCard?.name}${URL_BINDER}${prizesAwardsCard?.id}`) }}>
                                                            Edit
                                                        </Button>
                                                        <Button className="changes-button pointer text-red" onClick={() => { DeleteCard(prizesAwardsCard.id) }}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </>
                                            } trigger="hover" placement="bottomRight">
                                                <MenuIcon className="p-abs" style={{ top: '1.5rem', right: '1.5rem' }} />
                                            </Popover>
                                            <div>
                                                <div className="ant-card-head-title">
                                                    <p className='single-line'>{prizesAwardsCard?.name}</p>
                                                </div>
                                                <div>
                                                    <p className="card-text">Max Fields : {prizesAwardsCard?.max_fields}</p>
                                                    <p className="card-text">Distribution : {prizesAwardsCard?.distribution}</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </Card>
                                <Modal
                                    open={Boolean(open)}
                                    onOk={hideModal}
                                    onCancel={hideModal}
                                    cancelText="cancel"
                                    className="payout-matrix-modal"
                                >
                                    <div style={{ marginTop: 30 }}>
                                        <p className="modal-title">{`Viewing ${PrizesAwardsCardData?.name}`}</p>
                                        <p className="modal-subtitle">{PrizesAwardsCardData?.distribution_type}</p>
                                        <p className="modal-text">Field Size : {PrizesAwardsCardData?.max_fields}</p>
                                        <p className="modal-text">Positions : {PrizesAwardsCardData?.max_position}</p>
                                    </div>
                                    <PayoutMatrixTable />
                                    <Row>
                                        <Button className="fdCancelButton mt-8 mb-8 payout-matrix-table-close-button  " onClick={hideModal}>Close</Button>
                                    </Row>
                                </Modal>
                            </Col>
                        ))}
                </Row>}


            </>
        </MainLayout>
    )
}

export default AdminPrizesAwards