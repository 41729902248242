import { ReactComponent as SolarEyeLinear } from "@assets/icons/solar_eye-linear.svg";
import { ReactComponent as ExternalLink } from "@assets/icons/external-link.svg";
import { ReactComponent as ApproveTick } from "@assets/icons/approve_tick.svg";
import { ReactComponent as RedCrossCircle } from "@assets/icons/red-cross-circle.svg";

import { Button, Col, Dropdown, Menu, Modal, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import "./styles.css"
import { useDispatch } from 'react-redux';
import { SagaActionType, SagaActions } from '@sagas/index';
import { approveNotification } from '@auth/permission';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ConfirmationModal } from './ConfirmationModal';
import AddComments from "./AddCommentsModal";
import { useNavigate } from "react-router-dom";
import { URL_BINDER } from "@constant/url";

interface SuperAdminNotificationInterface {
  open: boolean;
  handleCancel: () => void;
}

const SuperAdminNotificationModal: React.FC<SuperAdminNotificationInterface> = ({ open = false, handleCancel }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState({ status: '', id: '' });
  const [message, setMessage] = useState<any>({ open: false, message: '' });
  const dispatch = useDispatch();
  const { notificationList, page, total, isupdating, notificationCount } = useSelector((state: any) => state.notification);
  const canEdit = approveNotification();
  const Navigate = useNavigate();
  const handleItemClick = (id: any) => {
    if (selectedItemIndex[id]) {
      delete selectedItemIndex[id];
    } else {
      selectedItemIndex[id] = true;
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedItemIndex({});
    }
  }, [open]);

  const Pagination = () => {
    if (total <= notificationList.length) return;
    dispatch({
      type: `${SagaActions.LIST_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
      payload: { page: page + 1, per_page: 10 }
    });
  }

  const onScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight < (clientHeight + scrollTop + 10)) {
      Pagination()
    }
  }

  const updateNotification = (id: any, status: any) => {
    if (isupdating) return;
    setModalType({ status: status, id: id });
    setModalOpen(true);
  }

  const updateNotificationStatus = (message?: any) => {
    if (isupdating) return;
    const payload: any = { ...modalType }
    if (message) payload.comment = message;
    dispatch({
      type: `${SagaActions.UPDATE_NOTIFICATIONS}_${SagaActionType.REQUEST}`,
      payload: payload
    });
  }

  useEffect(() => {
    if (!isupdating && modalOpen) {
      setModalOpen(false);
      setModalType({ status: '', id: '' });
    } else if (!isupdating && message.open) {
      setMessage({ open: false, message: '' });
    }
  }, [isupdating]);
  return (
    <>
      <Modal
        title={
          <div className="notificationCenterTitle">
            Notification Centre ({notificationCount}){' '}
            <Button href="/admin/notification-center" style={{ border: "none" }}>
              <ExternalLink className='notificationCenterTitleIcon' />
            </Button>
          </div>
        }
        open={open}
        onCancel={handleCancel}
        className={"NotificationBellCenterModal"}
      >
        <Col id='checkScrollForPagination' style={{ height: 280, overflow: 'auto' }} onScroll={onScroll}>
          <table className='w-100'>
            {(notificationList || []).map((notification: any, index: number) => (
              <tr key={index} className='NotificationRow' onClick={() => handleItemClick(notification?.id)}>
                <td >
                  <p className='NotificationListEventName'>{notification?.message}</p>
                  <p>
                    {moment(notification?.tournament_date).format('DD/MM/YYYY')}
                  </p>
                </td>

                <td style={{ display: "flex", maxWidth: 250, justifyContent: "space-around" }}>
                  <Button
                    className={`notificationCenterButton ${selectedItemIndex[notification?.id] ? 'selectedViewButton' : ''}`}
                    onClick={() => {
                      const data = notificationList.find((item: any) => item.id === notification?.id);
                      const URL = `/admin/notification-center/${data?.tournament_name}${URL_BINDER}${data.tournament_id}?name=${data?.message}&notification_id=${data?.id}&status=${data?.status}`;
                      Navigate(URL);
                    }}
                  >
                    <SolarEyeLinear />View
                  </Button>
                  {canEdit && notification.status === 'awaiting' &&
                    <>
                      <Button
                        className={`notificationCenterButton ${selectedItemIndex[notification?.id] ? 'selectedApproveButton' : ''}`}
                        style={{ width: 100 }}
                        onClick={() => { updateNotification(notification?.id, 'approve') }}
                      >
                        <ApproveTick />Approve
                      </Button>
                      <Button className={`notificationCenterButton ${selectedItemIndex[notification?.id] ? 'selectedCancelButton' : ''}`} style={{ width: 34 }}
                        onClick={() => { updateNotification(notification?.id, 'reject') }}
                      >
                        <RedCrossCircle />
                      </Button>
                    </>
                  }
                </td>
              </tr>
            ))}
          </table>
        </Col>
      </Modal>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        heading={modalType.status === 'approve' ? 'Approve Event?' : 'Reject Event?'}
        description={modalType.status === 'approve' ? 'Approving this event will also have it published.' : 'Rejecting this event will  prevent Chapter Owner’s to have this event published. Please do add a comment for rejection.'}
        onConfirm={() => { if (modalType.status === 'approve') updateNotificationStatus(); else { setModalOpen(false); setMessage({ open: true, message: '' }) } }}
        onCancel={() => { setModalOpen(false); }}
        isLoading={isupdating}
        isDanger={!(modalType.status === 'approve')}
        btnText={modalType.status === 'approve' ? 'Yes, Approve' : 'Continue'}
      />
      <AddComments
        modalOpen={message.open}
        setModalOpen={() => setMessage({ ...message, open: false })}
        value={message.message}
        onChange={(params: any) => { setMessage({ ...message, message: params }) }}
        onConfirm={() => { updateNotificationStatus(message.message); }}
        onCancel={() => { setMessage({ ...message, open: false }) }}
        heading="Add Comment For Rejection"
        btnText={'Add Comment & Reject'}
        isDanger={true}
        isLoading={isupdating}
      />
    </>
  );
};

export default SuperAdminNotificationModal