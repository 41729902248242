import {
  takeLatest,
  call,
  put,
  spawn,
  takeEvery,
  select,
} from "redux-saga/effects";
import { ApiEndpoints, SagaActionType, SagaActions } from ".";
import { gcbAPI } from "@utils/APIInterceptor";
import { getCompanyID, getToken } from "../auth/localData";
import { notification } from "antd";
import { Error, Success, Waraning } from "../constant/toast/Toast";
import { history } from "@config/routes";



function* createContestLibraryAPI({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    payload.company_id = getCompanyID()
    const ID = payload.id;
    delete payload.id;
    const response: any = ID ?
    yield call(gcbAPI().put,
    `${ApiEndpoints.ADMIN}/${ApiEndpoints.CONTESTS}/${ID}`,
    { ...payload })
    :
    yield call(gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CONTESTS}`,
      { ...payload });
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
    history.push('/admin/contest');
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
      payload: { data: [] }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* deleteContestLibraryAPI({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(gcbAPI().delete, `${ApiEndpoints.ADMIN}/${ApiEndpoints.CONTESTS}/${payload.id}`,
      { params: {access_token : getToken()} });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.DELETE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        payload: { id : payload.id},
      });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.DELETE_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
      payload: { id : null }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* getContestLibraryAPI({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken()
    const response: any = yield call(gcbAPI().get, `${ApiEndpoints.ADMIN}/${ApiEndpoints.CONTESTS}`,
      { params: payload });
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data, total_count: response?.data?.total_count, page: payload.page },
      });
    }
  }
  catch (error: any) {
    yield put({
      type: `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.SUCCESS}`,
      payload: { data: [] , total_count: 0, page: 1 }
    });
    yield call(Error, { description: error?.data?.message });

  }

}
function* createContestLibraryRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
    createContestLibraryAPI
  );
}
function* getContestLibraryRequest() {
  yield takeLatest(
    `${SagaActions.GET_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
    getContestLibraryAPI
  );
}
function* deleteContestLibraryRequest() {
  yield takeLatest(
    `${SagaActions.DELETE_CONTEST_LIBRARY}_${SagaActionType.REQUEST}`,
    deleteContestLibraryAPI
  );
}
export default function* rootcreateContestLibrary() {
  yield spawn(createContestLibraryRequest);
  yield spawn(getContestLibraryRequest);
  yield spawn(deleteContestLibraryRequest);

}