import DraftRte from '@components/draftrte/DraftRte';
import Htag from '@layout/H1';
import { Button, Col, Modal, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useRef } from 'react'

interface Props {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    value: string;
    onChange: (value: string) => void;
    isDanger?: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    btnText?: string;
    heading: string;
}
export default function AddComments({ heading, modalOpen, setModalOpen, value, onChange, isDanger, isLoading, onConfirm, onCancel, btnText }: Props) {
    const draftRteRef: any = useRef(null);


    const onHandleCancel = () => {
        if (draftRteRef.current) {
            draftRteRef.current.clearContent();
        }
    }
    return (
        <Modal
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            className=''
            closable={false}
            footer={null}
            width={550}
            style={{ maxWidth: '90%' }}
            centered
        >
            <Col span={24}>
                <Htag level={4}>{heading}</Htag>
                <TextArea
                    placeholder="Message"
                    className='d-border'
                    autoSize={{ minRows: 8, maxRows: 8 }}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                <Col className='flex-center mt-4' span={24}>
                    <Button
                        type="primary"
                        className='mr-2 h-50 text-bold btnSpin d-border'
                        style={isDanger ? { background: '#BF0000' } : { background: '#0ABBF2' }}
                        onClick={onConfirm}
                    >
                        {isLoading ? <Spin /> : btnText ? btnText : 'Add Comment'}
                    </Button>
                    <Button
                        size={"middle"}
                        onClick={onCancel}
                        className='ml-2 h-50'
                    >
                        Cancel
                    </Button>
                </Col>
            </Col>

        </Modal>
    )
}
