import { takeLatest, call, put, spawn } from 'redux-saga/effects';
import { ApiEndpoints, SagaActionType, SagaActions } from '.';
import { gcbAPI } from '@utils/APIInterceptor';
import { getToken } from '../auth/localData';
import { Error, Success } from '../constant/toast/Toast';



function* printCartSigns({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS    
      }/${payload.id}/print_cart_signs`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PRINT_CART_SIGN}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } 
  catch (error:any) {
   yield call(Error, { description: error?.data?.message});
  }
}

function* fetchTournamentRoundCourses({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS    
      }/${payload.id}/round_courses`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.TOURNAMENT_ROUND_COURSES}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data,
        },
      });
    }
  } 
  catch (error:any) {
   yield call(Error, { description: error?.data?.message});
  }
}

function* printScoreCards({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS    
      }/${payload.id}/${
        ApiEndpoints.PRINT_SCORECARDS}`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.PRINT_SCORECARD}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    if(error?.status === 401){
      yield call(Error, {message:`${error?.statusText}!`,description:`Please Login Again.`,duration:10})
    }else{
      yield call(Error, { description: error.data.message});
    }
  }
}

function* fetchNationalSponsors({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS    
      }/${payload.id}/national_sponsors`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.FETCH_NATIONAL_SPONSORS}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } 
  catch (error:any) {
   yield call(Error, { description: error?.data?.message});
  }
}

function* createCartSign({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CART_SIGNS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_CART_SIGN}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_CART_SIGN}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* updateCartSign({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.CART_SIGNS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }    
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'Cart Sign updated successfully.' });
      yield put({
        type: `${SagaActions.UPDATE_CART_SIGN}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      yield put({
        type: `${SagaActions.SHOW_CART_SIGN}_${SagaActionType.REQUEST}`,
        payload: { },
        id:payload.tournament_id 
      });
    }
  } catch (error) {}
}

function* showCartSign({ payload ,id}: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS
      }/${id}/show_cart_sign`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.SHOW_CART_SIGN}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* createScorecard({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    const response: any = yield call(
      gcbAPI().post,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.PRINT_SCORECARDS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    if (response?.status === 201) {
      yield call(Success, { description: response?.data?.message });
      yield put({
        type: `${SagaActions.CREATE_SCORECARD}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
    }
  } catch (error: any) {
    yield put({
      type: `${SagaActions.CREATE_SCORECARD}_${SagaActionType.SUCCESS}`,
      payload: { data: [] },
    });
    yield call(Error, { description: error?.data?.message });
  }
}

function* updateScorecard({ payload }: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().put,
      `${ApiEndpoints.ADMIN}/${ApiEndpoints.PRINT_SCORECARDS}`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }    
    );
    if (response?.status === 200) {
      yield call(Success, { description: 'Scorecard updated successfully.' });
      yield put({
        type: `${SagaActions.UPDATE_SCORECARD}_${SagaActionType.SUCCESS}`,
        payload: { data: response?.data?.data },
      });
      yield put({
        type: `${SagaActions.SHOW_SCORECARD}_${SagaActionType.REQUEST}`,
        payload: { },
        id:payload.tournament_id 
      });
    }
  } catch (error) {}
}

function* showScorecard({ payload ,id}: any): Generator<any> {
  try {
    payload.access_token = getToken();
    let response: any = yield call(
      gcbAPI().get,
      `${ApiEndpoints.ADMIN}/${
        ApiEndpoints.TOURNAMENTS
      }/${id}/show_print_scorecard`,
      { params: payload }
    );
    if (response?.status === 200) {
      yield put({
        type: `${SagaActions.SHOW_SCORECARD}_${SagaActionType.SUCCESS}`,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (error:any) {
    yield call(Error, { description: error?.data?.message });
  }
}

function* PrintCartSignsRequest() {
  yield takeLatest(
    `${SagaActions.PRINT_CART_SIGN}_${SagaActionType.REQUEST}`,
    printCartSigns
  );
}

function* tournamentRoundCoursesRequest() {
  yield takeLatest(
    `${SagaActions.TOURNAMENT_ROUND_COURSES}_${SagaActionType.REQUEST}`,
    fetchTournamentRoundCourses
  );
}

function* PrintScoreCardsRequest() {
  yield takeLatest(
    `${SagaActions.PRINT_SCORECARD}_${SagaActionType.REQUEST}`,
    printScoreCards
  );
}

function* FetchNationalSponsorsRequest() {
  yield takeLatest(
    `${SagaActions.FETCH_NATIONAL_SPONSORS}_${SagaActionType.REQUEST}`,
    fetchNationalSponsors
  );
}

function* CreateCartSignRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_CART_SIGN}_${SagaActionType.REQUEST}`,
    createCartSign
  );
}

function* UpdateCartSignRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_CART_SIGN}_${SagaActionType.REQUEST}`,
    updateCartSign
  );
}

function* ShowCartSignRequest() {
  yield takeLatest(
    `${SagaActions.SHOW_CART_SIGN}_${SagaActionType.REQUEST}`,
    showCartSign
  );
}

function* CreateScorecardRequest() {
  yield takeLatest(
    `${SagaActions.CREATE_SCORECARD}_${SagaActionType.REQUEST}`,
    createScorecard
  );
}

function* UpdateScorecardRequest() {
  yield takeLatest(
    `${SagaActions.UPDATE_SCORECARD}_${SagaActionType.REQUEST}`,
    updateScorecard
  );
}

function* ShowScorecardRequest() {
  yield takeLatest(
    `${SagaActions.SHOW_SCORECARD}_${SagaActionType.REQUEST}`,
    showScorecard
  );
}





export default function* rootPrintings() {
  yield spawn(PrintCartSignsRequest);
  yield spawn(tournamentRoundCoursesRequest);
  yield spawn(PrintScoreCardsRequest);
  yield spawn(FetchNationalSponsorsRequest);
  yield spawn(CreateCartSignRequest);
  yield spawn(UpdateCartSignRequest);
  yield spawn(ShowCartSignRequest);
  yield spawn(CreateScorecardRequest);
  yield spawn(UpdateScorecardRequest);
  yield spawn(ShowScorecardRequest);
}
